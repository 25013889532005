import initialState from 'initialState';
import { Reducer } from 'redux';

import { HIDE_AGE_RESTRICTION, SHOW_AGE_RESTRICTION, UPDATE_AGE_RESTRICTION } from './constants';

const ageRestriction: Reducer<AAgeRestriction> = (
  state: AAgeRestriction = initialState.ageRestriction,
  action: any,
) => {
  switch (action.type) {
    case UPDATE_AGE_RESTRICTION:
      return {
        ...state,
        accepted: {
          ...state.accepted,
          ...action.validAgeRestrictions,
        },
      };
    case SHOW_AGE_RESTRICTION:
      return {
        ...state,
        age: action.age,
        show: true,
      };
    case HIDE_AGE_RESTRICTION:
      const acceptedAGes = state.accepted[action.locale] || [];
      const ageRestrictionExpireDate = new Date();
      ageRestrictionExpireDate.setMonth(ageRestrictionExpireDate.getMonth() + 1);
      return {
        ...state,
        accepted: {
          ...state.accepted,
          [action.locale]:
            state.age && action.accepted
              ? [
                  ...acceptedAGes,
                  {
                    age: state.age,
                    expires: ageRestrictionExpireDate,
                  },
                ]
              : [...acceptedAGes],
        },
        age: undefined,
        show: false,
      };
    default:
      return state;
  }
};

export default ageRestriction;
