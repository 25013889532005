import { EventHandler, useEffect, useRef } from 'react';

export default function useEventListener(
  eventName: string,
  handler: EventHandler<any>,
  element: HTMLElement | Window | null = window,
): void {
  const savedHandler = useRef<any>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element?.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: any) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export function triggerEvent(eventName: string, element: HTMLElement | Window = window): void {
  const event = new CustomEvent(eventName);
  element.dispatchEvent(event);
}
