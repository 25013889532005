import Section from 'components/ProductDetail/Section';
import { SidebarContext } from 'components/Sidebar/context';
import { useContext } from 'react';

import { SIDEBAR } from '../../../helper/js/constants';
import parseHtmlElementForLinks from '../helper';
import styles from './DescriptionContent.module.scss';

function DescriptionContent() {
  const { currentPage, globalData } = useContext(SidebarContext);
  const contentList = globalData.content as Content[];

  if (!contentList || currentPage !== SIDEBAR.PAGES.DESCRIPTION) {
    return null;
  }

  return (
    <Section classes={styles.section}>
      {contentList
        .filter(({ contentIdentifier }) => contentIdentifier !== 'ARTICLE_NUMBER')
        .map(
          ({ contentIdentifier, contentType, name, text }, index) =>
            text && (
              <div
                className={contentIdentifier.toLowerCase()}
                key={`${contentIdentifier}_${contentType}_${name}`}
                dangerouslySetInnerHTML={{
                  __html: parseHtmlElementForLinks(text),
                }}
              />
            ),
        )}
    </Section>
  );
}

export default DescriptionContent;
