import Loading from 'components/Loading';
import { GlobalDataContext } from 'containers/ContentContainer/context';
import { Component, ErrorInfo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { flyerDataStartLoading } from './actions';

type ContentContainerProps = {
  children(props: any): JSX.Element;
};

type ContentContainerConnectProps = {
  loadFlyerData(): void;
  loading: boolean;
  hasError: boolean;
  uuid?: string;
  id: string;
  ar?: string;
  locale?: string;
};
type ContentContainerState = {
  hasError: boolean;
};

type ContentContainerMergedProps = ContentContainerProps & ContentContainerConnectProps;

class ContentContainer extends Component<ContentContainerMergedProps, ContentContainerState> {
  static defaultProps = {
    loading: false,
    hasError: false,
  };

  readonly state: ContentContainerState = {
    hasError: false,
  };

  mediaQuerys: any = {
    isWideScreen: undefined,
    isMdMin: undefined,
  };

  shouldComponentUpdate(prevProps: ContentContainerMergedProps) {
    return (
      prevProps.loading !== this.props.loading ||
      prevProps.hasError !== this.props.hasError ||
      prevProps.uuid !== this.props.uuid ||
      prevProps.locale !== this.props.locale
    );
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    this.props.loadFlyerData();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.hasError !== this.props.hasError) {
      this.setState({ hasError: this.props.hasError });
      return;
    }
    if (!this.props.loading && nextProps.uuid !== this.props.uuid) {
      this.props.loadFlyerData();
    }
  }

  render() {
    const { loading, children, locale, uuid, id, ar } = this.props;
    const { hasError } = this.state;

    return (
      <GlobalDataContext.Provider value={{ uuid, id, ar }}>
        {!loading || hasError || this.props.hasError ? (
          children({
            hasError: hasError || this.props.hasError,
            language: locale,
            loading,
          })
        ) : (
          <Loading />
        )}
      </GlobalDataContext.Provider>
    );
  }
}

const areStatesEqual = (nextProps: AppState, prevProps: AppState) =>
  nextProps.appData.loading === prevProps.appData.loading &&
  nextProps.appData.error === prevProps.appData.error &&
  nextProps.appData.id === prevProps.appData.id &&
  nextProps.routing.params.uuid === prevProps.routing.params.uuid &&
  nextProps.appData.locale === prevProps.appData.locale;

const mapStateToProps = (state: AppState) => ({
  loading: state.appData.loading,
  hasError: state.appData.error,
  id: state.appData.id,
  uuid: state.routing.params.uuid,
  ar: state.routing.params.ar,
  locale: state.appData.locale,
});
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    loadFlyerData: () => {
      dispatch(flyerDataStartLoading());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps, undefined, {
  areStatesEqual,
})(ContentContainer);
