import PagePreview from 'components/PagePreview';
import { VIEW } from 'helper/js/constants';
import { useFlippyNavigate } from 'helper/js/hooks/router';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

type PagePreviewContainerProps = {
  visible: boolean;
  className: string;
  isLastPage: boolean;
  recommendedFlyer: ARecommendedFlyerItem | undefined;
};

function PagePreviewContainer({
  visible,
  className = '',
  isLastPage,
}: Readonly<PagePreviewContainerProps>) {
  const { trackEventUniversalAnalytics } = useContext(TrackingContext);
  const recommendedFlyer = useSelector((state: AppState) => state.appData.recommendedFlyer[0]);
  const flippyNavigate = useFlippyNavigate();

  useEffect(() => {
    if (visible) {
      trackEventUniversalAnalytics(TRACKING_EVENTS.FLYER_RECOMMENDED_SEEN);
    }
  }, [trackEventUniversalAnalytics, visible]);

  const handleButtonClick = () => {
    trackEventUniversalAnalytics(TRACKING_EVENTS.FLYER_RECOMMENDED_BUTTON_CLICKED);
    flippyNavigate({ view: VIEW.RECOMMENDED });
  };

  const handleFlyerClick = () => {
    flippyNavigate({ uuid: recommendedFlyer.slug, page: '1', view: VIEW.FLYER });
    trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.FLYER_RECOMMENDED_LEAFLET_CLICKED,
      eventLabel: recommendedFlyer.url,
    });
  };

  return (
    <PagePreview
      onButtonClick={handleButtonClick}
      onFlyerClick={handleFlyerClick}
      className={className}
      isLastPage={isLastPage}
      visible={visible}
      recommendedFlyer={recommendedFlyer}
    />
  );
}
export default PagePreviewContainer;
