import Error from 'components/Error';
import { memo } from 'react';
import { useSelector } from 'react-redux';

function ErrorContainer() {
  const quitUrl = useSelector((state: AppState) => state.appData.urls.quitUrl);
  return <Error quitUrl={quitUrl} errorCode="errorCodeDefault" />;
}

export default memo(ErrorContainer);
