import initialState from 'initialState';
import { Reducer } from 'redux';

import { BrowserPropertiesAction } from './actions';
import { BROWSER_PROPERTY_UPDATE } from './constants';

const browser: Reducer<ABrowser, BrowserPropertiesAction> = (
  state: ABrowser = initialState.browser,
  action: BrowserPropertiesAction,
) => {
  switch (action.type) {
    case BROWSER_PROPERTY_UPDATE:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default browser;
