import initialState from 'initialState';
import { Reducer } from 'redux';

import { SheetActionTypes } from './actions';
import {
  SHEET_NAVIGATE_BEFORE_START,
  SHEET_NAVIGATE_END,
  SHEET_NAVIGATE_START,
  SHEET_PAGES_VISIBLE_UPDATED,
  SHEET_PAGES_ZOOM_UPDATE,
} from './constants';

const sheet: Reducer<ASheet, SheetActionTypes> = (
  state: ASheet = initialState.sheet,
  action: SheetActionTypes,
) => {
  switch (action.type) {
    case SHEET_PAGES_VISIBLE_UPDATED:
      return {
        ...state,
        currentVisible: action.currentVisible,
        flipPages: action.flipPages,
      };
    case SHEET_PAGES_ZOOM_UPDATE:
      return {
        ...state,
        zoom: action.zoom,
      };
    case SHEET_NAVIGATE_START:
      return {
        ...state,
        navigate: true,
        animationType: action.animationType,
      };
    case SHEET_NAVIGATE_BEFORE_START:
      return {
        ...state,
        flipToPage: action.flipToPage,
      };
    case SHEET_NAVIGATE_END:
      return {
        ...state,
        flipToPage: undefined,
        navigate: false,
        animationType: '',
      };
    default:
      return state;
  }
};
export default sheet;
