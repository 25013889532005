import './style.scss';

import classNames from 'classnames';
import NavigationButtonContainer, {
  NAVIGATION_BUTTON_TYPES,
} from 'containers/NavigationButtonContainer';
import { LanguageContext } from 'providers/Language';
import { memo, useCallback, useContext, useEffect, useState } from 'react';

type PageStepperProps = {
  currentPage: number;
  totalPages: number;
  currentVisible: number;
  onClick: () => void;
  showArrowButtons: boolean;
  isActive?: boolean;
};

const PageStepper = ({
  totalPages,
  currentPage,
  currentVisible,
  showArrowButtons,
  isActive,
  onClick,
}: PageStepperProps) => {
  const { t } = useContext(LanguageContext);
  const isFirstPage = currentPage === 1;
  const isLastPage =
    currentPage === totalPages ||
    (currentVisible === 2 && totalPages > 2 && currentPage === totalPages - 1);

  const getLabel = useCallback(() => {
    let currentPageRange;
    if (currentVisible > 1) {
      currentPageRange = `${currentPage}-${currentPage + (currentVisible - 1)}`;
    } else {
      currentPageRange = currentPage;
    }
    return `${currentPageRange} / ${totalPages}`;
  }, [totalPages, currentPage, currentVisible]);

  const [text, setText] = useState(getLabel());

  useEffect(() => {
    setText(getLabel());
  }, [getLabel]);

  const className = classNames(`stepper stepper--${import.meta.env.VITE_THEME}`, {
    'stepper--active': isActive,
  });

  return (
    <>
      {showArrowButtons && (
        <NavigationButtonContainer
          direction={NAVIGATION_BUTTON_TYPES.backward}
          icon={`arrow-left--${import.meta.env.VITE_THEME}`}
          visible={!isFirstPage}
        />
      )}
      <button type="button" className={className} title={t('overview')} onClick={onClick}>
        {text}
      </button>
      {showArrowButtons && (
        <NavigationButtonContainer
          direction={NAVIGATION_BUTTON_TYPES.forward}
          icon={`arrow-right--${import.meta.env.VITE_THEME}`}
          visible={!isLastPage}
        />
      )}
    </>
  );
};

export default memo(PageStepper);
