import './style.scss';

import { MediaGroupContext } from 'containers/MediaGroupsContainer/context';
import { LanguageContext } from 'providers/Language';
import { MouseEvent, useContext } from 'react';

type LinkInternalProps = {
  page: number;
};

const LinkInternal = ({ page }: LinkInternalProps) => {
  const { onDeepLink } = useContext(MediaGroupContext);
  const { t } = useContext(LanguageContext);

  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    const { currentTarget } = e;
    currentTarget.blur();
    page && onDeepLink(page);
  };

  return (
    <button
      type="button"
      onClick={handleOnClick}
      className="linkinternal"
      aria-label={`${t('goToPage')} ${page}`}
    />
  );
};

export default LinkInternal;
// onHandleDeepLink
