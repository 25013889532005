// https://github.com/rt2zz/redux-persist-crosstab/pull/17
import { KEY_PREFIX, REHYDRATE } from 'redux-persist/lib/constants';

const persistCrosstab = function (store: any, persistConfig: any, crosstabConfig: any) {
  const blacklist = crosstabConfig.blacklist || null;
  const whitelist = crosstabConfig.whitelist || null;
  const keyPrefix = crosstabConfig.keyPrefix || KEY_PREFIX;
  const { key } = persistConfig;
  window.addEventListener('storage', handleStorageEvent, false);

  function handleStorageEvent(e: StorageEvent) {
    if (e.key?.startsWith(keyPrefix)) {
      if (e.oldValue === e.newValue) {
        return;
      }
      const statePartial = JSON.parse(e.newValue ?? '');
      const state: Partial<AppState> = Object.keys(statePartial).reduce(
        (state: IKeyValueData, reducerKey) => {
          if (whitelist && whitelist.indexOf(reducerKey) === -1) {
            return state;
          }
          if (blacklist && blacklist.indexOf(reducerKey) !== -1) {
            return state;
          }
          state[reducerKey] = JSON.parse(statePartial[reducerKey]);
          return state;
        },
        {},
      );
      store.dispatch({
        key,
        payload: state,
        type: REHYDRATE,
      });
    }
  }
};
export default persistCrosstab;
