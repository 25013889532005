import 'components/ButtonC/style.scss';

import classNames from 'classnames';
import IconC, { ICON_SIZE, ICON_SIZE_TYPE } from 'components/IconC';
import Indicator from 'components/Indicator';
import Link, { LINK_TARGET, LinkTargetTypes } from 'components/Link';
import TooltipContainer from 'containers/TooltipContainer';
import { APPEARANCE } from 'helper/js/constants';
import { doesEventContainsElement } from 'helper/js/helper';
import { ReferenceContext } from 'providers/Reference';
import {
  CSSProperties,
  forwardRef,
  memo,
  MouseEvent,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export enum BUTTON_APPEARANCE {
  BLANK = 'blank',
  PRIMARY = 'primary',
  PRIMARY_NEGATIVE = 'primary-negative',
  SECONDARY = 'secondary',
  SECONDARY_NEGATIVE = 'secondary-negative',
  MEDIA = 'media',
  SELECT = 'select',
}

export enum BUTTON_HOVERSTYLE {
  BACKGROUND = 'background',
  ICON = 'icon',
}

export enum BUTTON_SIZE {
  S = 'sm',
  L = 'lg',
}

export enum BUTTON_ALIGN_LABEL {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export type BUTTON_APPEARANCE_TYPE =
  | BUTTON_APPEARANCE.BLANK
  | BUTTON_APPEARANCE.PRIMARY
  | BUTTON_APPEARANCE.PRIMARY_NEGATIVE
  | BUTTON_APPEARANCE.SECONDARY
  | BUTTON_APPEARANCE.SECONDARY_NEGATIVE
  | BUTTON_APPEARANCE.MEDIA
  | BUTTON_APPEARANCE.SELECT;

export type BUTTON_HOVERSTYLE_TYPE = BUTTON_HOVERSTYLE.BACKGROUND | BUTTON_HOVERSTYLE.ICON;

export type BUTTON_SIZE_TYPE = BUTTON_SIZE.S | BUTTON_SIZE.L;

export type BUTTON_ALIGN_LABEL_TYPE =
  | BUTTON_ALIGN_LABEL.LEFT
  | BUTTON_ALIGN_LABEL.RIGHT
  | BUTTON_ALIGN_LABEL.CENTER;

interface Props {
  fullwidth?: boolean;
  icon?: string;
  iconColor?: string;
  flipIcon?: string;
  disabled?: boolean;
  inColumn?: boolean;
  flipped?: boolean;
  label?: string;
  labelBold?: boolean;
  type?: BUTTON_APPEARANCE_TYPE;
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  hoverStyle?: BUTTON_HOVERSTYLE_TYPE;
  href?: string;
  target?: LinkTargetTypes;
  alignLabel?: BUTTON_ALIGN_LABEL_TYPE;
  size?: BUTTON_SIZE_TYPE;
  indicator?: number | boolean | string;
  indicatorColor?: APPEARANCE_TYPE;
  iconSize?: ICON_SIZE_TYPE;
  style?: CSSProperties;
  title?: string;
  active?: boolean;
  saveToRef?: 'sheet' | 'wishListButton';
  forceTooltipTouch?: boolean;
  showTooltipOnMount?: boolean;
  children?: ReactNode;
  ariaLabel?: string;
  noPaddingSide?: boolean;
  iconRight?: string;
  iconRightColor?: string;
  rotateIcon?: boolean;
  rotateIconRight?: boolean;
  additionalProps?: IKeyValueData;
  blurAfterClick?: boolean;
  transformLabelToUppercase?: boolean;
  isCMSBtn?: boolean;
  isCMSSecondary?: boolean;
  className?: string;
  isClickable?: boolean;
}

const getButtonClassNames = ({
  hoverStyle = BUTTON_HOVERSTYLE.BACKGROUND,
  fullwidth = false,
  type = BUTTON_APPEARANCE.PRIMARY,
  labelBold = true,
  inColumn = false,
  alignLabel = BUTTON_ALIGN_LABEL.CENTER,
  size,
  flipped = false,
  noPaddingSide = false,
  transformLabelToUppercase = true,
  isCMSBtn = false,
  className = '',
  isCMSSecondary = false,
  icon,
  iconRight,
  indicator,
  active = false,
}: Partial<Props>) =>
  classNames({
    button: true,
    [`button--${type}`]: type,
    'button--label-uppercase': transformLabelToUppercase,
    'button--no-padding-side': noPaddingSide,
    'button--with-left-right-icon': iconRight,
    [`button--${size}`]: size,
    'button--incolumn': inColumn,
    'button--bold': labelBold,
    'button--withIndicator-in-row': !inColumn && indicator,
    'button--icon': !inColumn && icon && icon !== 'no-icon',
    'button--fullwidth': fullwidth,
    [`button--${alignLabel}`]: alignLabel,
    [`button--hover-${hoverStyle}`]: type,
    [`button--hover-${hoverStyle}-active`]: active,
    'button--flipped': flipped,
    'button--cms': isCMSBtn,
    'button--cms-secondary': isCMSSecondary,
    [className]: className,
  });

const getButtonIconClassNames = ({ active = false, rotateIcon = false }: Partial<Props>) =>
  classNames({
    button__icon: true,
    'button__icon--rotate': active && rotateIcon,
  });

const getButtonIconRightClassNames = ({
  active = false,
  rotateIconRight = false,
}: Partial<Props>) =>
  classNames({
    button__icon: true,
    'button__icon--right': true,
    'button__icon--rotate': active && rotateIconRight,
  });

const checkIndicator = (indicator: Props['indicator']) =>
  (typeof indicator === 'number' && indicator > 0) ||
  (typeof indicator === 'string' && indicator !== '0') ||
  indicator === true;

const getLeftIcon = (
  { icon, iconColor, indicator, flipIcon, iconSize, indicatorColor }: Partial<Props>,
  buttonIconClasses: string,
  hasIndicator: boolean,
  iconRef: MutableRefObject<HTMLSpanElement | null>,
) =>
  (icon || flipIcon) &&
  icon !== 'no-icon' && (
    <span className={buttonIconClasses} ref={iconRef}>
      {icon && <IconC icon={icon} size={iconSize} color={iconColor} />}
      {flipIcon && <IconC icon={flipIcon || icon} size={iconSize} />}
      {hasIndicator && <Indicator content={indicator} color={indicatorColor} />}
    </span>
  );

const getRightIcon = (
  { iconRight, iconRightColor, iconSize }: Partial<Props>,
  buttonIconRightClasses: string,
) =>
  iconRight && (
    <span className={buttonIconRightClasses}>
      <IconC icon={iconRight} size={iconSize} color={iconRightColor} />
    </span>
  );

const getTooltip = (
  { title, forceTooltipTouch = false, showTooltipOnMount = false }: Partial<Props>,
  isMounted: boolean,
  buttonRef: MutableRefObject<HTMLButtonElement | null>,
) =>
  isMounted &&
  title && (
    <TooltipContainer
      text={title}
      element={buttonRef}
      forceTooltipTouch={forceTooltipTouch}
      showOnMount={showTooltipOnMount}
    />
  );

const getLabelContent = ({ label, children, additionalProps }: Partial<Props>, ref) =>
  (label || children) && (
    <span className="button__label" {...additionalProps} ref={ref}>
      {label !== undefined && label}
      {children !== undefined && children}
    </span>
  );

const ButtonC = forwardRef<HTMLSpanElement, Props>((props, ref) => {
  const {
    hoverStyle = BUTTON_HOVERSTYLE.BACKGROUND,
    fullwidth = false,
    type = BUTTON_APPEARANCE.PRIMARY,
    onClick = () => {
      return;
    },
    label,
    labelBold = true,
    disabled = false,
    icon,
    iconColor,
    rotateIcon = false,
    inColumn = false,
    alignLabel = BUTTON_ALIGN_LABEL.CENTER,
    indicator,
    indicatorColor = APPEARANCE.PRIMARY,
    size,
    href,
    target = LINK_TARGET.BLANK,
    children,
    flipped = false,
    flipIcon = '',
    iconSize = ICON_SIZE.M,
    style = {},
    title,
    active = false,
    saveToRef,
    forceTooltipTouch = false,
    showTooltipOnMount = false,
    ariaLabel,
    noPaddingSide = false,
    iconRight,
    iconRightColor,
    rotateIconRight = false,
    additionalProps,
    blurAfterClick = true,
    transformLabelToUppercase = true,
    isCMSBtn = false,
    className = '',
    isCMSSecondary = false,
    isClickable = true,
  } = props;
  const buttonRef = useRef(null);
  const iconRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  const buttonClasses = getButtonClassNames({
    hoverStyle,
    fullwidth,
    type,
    labelBold,
    inColumn,
    alignLabel,
    size,
    flipped,
    noPaddingSide,
    transformLabelToUppercase,
    isCMSBtn,
    className,
    isCMSSecondary,
    icon,
    iconRight,
    indicator,
    active,
  });
  const buttonIconClasses = getButtonIconClassNames({ active, rotateIcon });
  const buttonIconRightClasses = getButtonIconRightClassNames({ active, rotateIconRight });

  const referenceContext = useContext(ReferenceContext);

  useEffect(() => {
    if (title && !isMounted) {
      setIsMounted(true);
    }
    if (saveToRef) {
      referenceContext.saveToRef(iconRef, saveToRef);
    }
  }, [title, isMounted, saveToRef, referenceContext]);

  const handleClick = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if (document.activeElement instanceof HTMLElement && blurAfterClick) {
      document.activeElement.blur();
    }

    if (additionalProps?.contentEditable) {
      e.preventDefault();
      return;
    }

    if (doesEventContainsElement(e.nativeEvent, 'button')) {
      onClick(e);
    }
  };

  const hasIndicator = checkIndicator(indicator);

  const leftIcon = useMemo(
    () =>
      getLeftIcon(
        { icon, iconColor, flipIcon, iconSize, indicatorColor, indicator },
        buttonIconClasses,
        hasIndicator,
        iconRef,
      ),
    [
      buttonIconClasses,
      flipIcon,
      hasIndicator,
      icon,
      iconColor,
      iconSize,
      indicator,
      indicatorColor,
    ],
  );

  const rightIcon = useMemo(
    () => getRightIcon({ iconRight, iconRightColor, iconSize }, buttonIconRightClasses),
    [buttonIconRightClasses, iconRight, iconRightColor, iconSize],
  );

  const tooltip = useMemo(
    () => getTooltip({ title, forceTooltipTouch, showTooltipOnMount }, isMounted, buttonRef),
    [forceTooltipTouch, isMounted, showTooltipOnMount, title],
  );

  const labelContent = useMemo(
    () => getLabelContent({ additionalProps, children, label }, ref),
    [additionalProps, children, label, ref],
  );
  const content = useMemo(
    () => (
      <>
        {leftIcon}
        {labelContent}
        {rightIcon}
        {tooltip}
      </>
    ),
    [leftIcon, labelContent, rightIcon, tooltip],
  );

  if (href && !disabled) {
    return (
      <Link
        className={buttonClasses}
        href={href}
        target={target}
        style={style}
        ref={buttonRef}
        ariaLabel={ariaLabel ?? title ?? label}
        hasFlyxParam={import.meta.env.VITE_THEME !== 'kaufland'}
        onClick={handleClick}>
        {content}
      </Link>
    );
  }

  if (!isClickable) {
    return (
      <span className={buttonClasses} style={style} ref={buttonRef}>
        {content}
      </span>
    );
  }

  return (
    <button
      disabled={disabled}
      type="button"
      className={buttonClasses}
      onClick={handleClick}
      style={style}
      ref={buttonRef}
      aria-label={ariaLabel ?? title ?? label}>
      {content}
    </button>
  );
});

export default memo(ButtonC);
