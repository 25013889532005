import { Action, ActionCreator } from 'redux';

import { BROWSER_PROPERTY_UPDATE } from './constants';

export interface BrowserPropertiesAction extends Action {
  type: 'BROWSER_PROPERTY_UPDATE';
  data: IKeyValueData;
  reducerEvent: string;
}

export const handleBrowserProperties: ActionCreator<BrowserPropertiesAction> = (
  data: IKeyValueData,
) => ({
  type: BROWSER_PROPERTY_UPDATE,
  data,
  reducerEvent: 'BROWSER_PROPERTY_UPDATED',
});
