import ButtonC, { BUTTON_APPEARANCE } from 'components/ButtonC';
import { SidebarContext } from 'components/Sidebar/context';
import { motion } from 'framer-motion';
import { SIDEBAR, VIEW } from 'helper/js/constants';
import { useFlippyNavigate } from 'helper/js/hooks/router';
import { LanguageContext } from 'providers/Language';
import { memo, useCallback, useContext } from 'react';

type SidebarProps = {
  headline: string;
  onClose: () => void;
  history: VIEW[];
};

const variants = {
  active: {
    opacity: 1,
  },
  inactive: {
    opacity: 0,
  },
};

function SidebarHeader({ headline, onClose, history }: Readonly<SidebarProps>) {
  const { handlePageChange, currentPage } = useContext(SidebarContext);
  const { t } = useContext(LanguageContext);
  const flippyNavigate = useFlippyNavigate();
  const hasHistory = history && history.length > 1;
  const navigatedFromWishList =
    hasHistory &&
    history.slice(-1)[0] === VIEW.PRODUCTDETAILS &&
    history.slice(-2)[0] === VIEW.WISHLIST;
  const navigatedFromSubSideBarPage = currentPage > 0;
  const showBackButton = navigatedFromSubSideBarPage || navigatedFromWishList;

  const handlePageBack = useCallback(() => {
    if (navigatedFromSubSideBarPage) {
      handlePageChange(SIDEBAR.PAGES.HOME);
    } else if (navigatedFromWishList) {
      flippyNavigate({ view: VIEW.WISHLIST });
    }
  }, [flippyNavigate, handlePageChange, navigatedFromSubSideBarPage, navigatedFromWishList]);

  return (
    <header className="sidebar__header">
      {showBackButton && (
        <motion.div variants={variants} initial="inactive" animate="active">
          <ButtonC
            type={BUTTON_APPEARANCE.PRIMARY}
            icon={`arrow-left--${import.meta.env.VITE_THEME}`}
            onClick={handlePageBack}
            title={t('sidebarPageBack')}
          />
        </motion.div>
      )}

      <h2 className="sidebar__headline">{headline}</h2>
      <ButtonC
        type={BUTTON_APPEARANCE.PRIMARY}
        icon={`cross--${import.meta.env.VITE_THEME}`}
        onClick={onClose}
        title={t('sidebarClose')}
      />
    </header>
  );
}

export default memo(SidebarHeader);
