import './style.scss';

import { Children, isValidElement, PropsWithChildren, PureComponent } from 'react';

class Menu extends PureComponent<PropsWithChildren> {
  render() {
    return (
      <section className="menu">
        <ul className="menu__list">
          {Children.map(this.props.children, (child) => isValidElement(child) && child)}
        </ul>
      </section>
    );
  }
}

export default Menu;
