import initialState from 'initialState';

import { WishListActions } from './actions';
import {
  WISHLIST_ALL_PRODUCTS_DELETED,
  WISHLIST_PRODUCT_ADDED,
  WISHLIST_PRODUCT_DELETED,
} from './constants';

const wishlistProducts = (
  wishListState = initialState.wishlistProducts,
  action: WishListActions,
) => {
  switch (action.type) {
    case WISHLIST_PRODUCT_ADDED:
      const index = wishListState.findIndex((product) => product[action.product.key]);

      if (wishListState[index]) {
        let data = [...wishListState[index][action.product.key], action.product.variant];

        if (action.product.variant !== 'default') {
          data = data.filter((variant) => variant !== 'default');
        }

        const allWithOutProductToAdd = wishListState.map((item, i) =>
          index === i
            ? {
                [action.product.key]: [...data],
              }
            : item,
        );

        return [...allWithOutProductToAdd];
      }

      return [
        ...wishListState,
        {
          [action.product.key]: [action.product.variant],
        },
      ];

    case WISHLIST_PRODUCT_DELETED:
      const iProductFoundInWishlist = wishListState.findIndex(
        (product) => product[action.product.key],
      );
      const product = wishListState[iProductFoundInWishlist][action.product.key] || [];
      const filteredProduct = product.filter((erp) => erp !== action.product.variant);
      const allWithOutProductToDelete = wishListState
        .map((item, i) =>
          iProductFoundInWishlist === i
            ? {
                [action.product.key]: [...filteredProduct],
              }
            : item,
        )
        .filter((p) => Object.values(p)[0].length > 0);

      return [...allWithOutProductToDelete];

    case WISHLIST_ALL_PRODUCTS_DELETED:
      return [];

    default:
      return wishListState;
  }
};

export default wishlistProducts;
