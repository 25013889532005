import { GlobalDataContext } from 'containers/ContentContainer/context';
import { addSearchParamsToUrl } from 'helper/js/helper';
import { useContext } from 'react';

const useNormalizedUrl = (url: string, hasFlyxParam = true): string => {
  const { id } = useContext(GlobalDataContext);

  try {
    return addSearchParamsToUrl(url, {
      ...(hasFlyxParam && { flyx: id }),
    });
  } catch (error) {
    console.error(`Failed to normalize URL: ${error}`);
    return url;
  }
};

export default useNormalizedUrl;
