import ButtonC, { BUTTON_HOVERSTYLE } from 'components/ButtonC';
import Footer from 'components/Footer';
import { LINK_TARGET } from 'components/Link';
import StepperContainer from 'containers/StepperContainer';
import { VIEW } from 'helper/js/constants';
import { useFlippyNavigate, useFlippyView } from 'helper/js/hooks/router';
import { useLanguage } from 'providers/Language';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

type OwnProps = {
  hasError: boolean;
};

const FooterContainer = ({ hasError }: OwnProps) => {
  const quitUrl = useSelector((state: AppState) => state.appData.urls.quitUrl);
  const isSmMin = useSelector((state: AppState) => state.browser.isSmMin);
  const noMarginals = useSelector((state: AppState) => state.settings.noMarginals);
  const flippyView = useFlippyView();
  const recommendedProductsLength = useSelector(
    (state: AppState) => state.recommendationProducts.count,
  );
  const { t } = useLanguage();
  const flippyNavigate = useFlippyNavigate();

  const updateBrowserUrl = useCallback((view) => flippyNavigate({ view }), [flippyNavigate]);

  const updateView = useCallback(
    (view: string) => updateBrowserUrl(flippyView !== view ? view : VIEW.FLYER),
    [flippyView, updateBrowserUrl],
  );

  const handleShowRecommendedProducts = useCallback(() => {
    updateView(VIEW.RECOMMENDEDPRODUCTS);
  }, [updateView]);

  const handleToggleStepper = useCallback(() => {
    updateView(VIEW.OVERVIEW);
  }, [updateView]);

  const renderFooterLeftContent = useMemo(
    () => (
      <ButtonC
        hoverStyle={BUTTON_HOVERSTYLE.ICON}
        inColumn
        icon="close"
        ariaLabel={t('quit')}
        href={quitUrl}
        target={LINK_TARGET.PARENT}
        className={`footer__button footer__button--${import.meta.env.VITE_THEME}`}
      />
    ),
    [t, quitUrl],
  );

  const renderFooterRightContent = useMemo(
    () =>
      recommendedProductsLength > 0 && (
        <ButtonC
          inColumn
          hoverStyle={BUTTON_HOVERSTYLE.ICON}
          icon={`star--${import.meta.env.VITE_THEME}`}
          ariaLabel={t('recommendationButtonText')}
          onClick={handleShowRecommendedProducts}
          indicator={recommendedProductsLength}
          active={flippyView === VIEW.RECOMMENDEDPRODUCTS}
          className={`footer__button footer__button--${import.meta.env.VITE_THEME}`}
        />
      ),
    [recommendedProductsLength, t, handleShowRecommendedProducts, flippyView],
  );

  const renderCenterContent = useMemo(
    () => !hasError && <StepperContainer onClick={handleToggleStepper} showArrowButtons={true} />,
    [handleToggleStepper, hasError],
  );

  return (
    <Footer
      showFooter={!noMarginals && !isSmMin}
      left={renderFooterLeftContent}
      right={renderFooterRightContent}
      center={renderCenterContent}
    />
  );
};

export default memo(FooterContainer);
