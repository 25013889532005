export type IPoint = {
  x: number;
  y: number;
};

export type IRect = {
  width: number;
  height: number;
};

class Point {
  static newOriginPoint(): IPoint {
    return {
      x: 0,
      y: 0,
    };
  }

  static distance(p1: IPoint, p2: IPoint) {
    const x1 = p1.x;
    const y1 = p1.y;
    const x2 = p2.x;
    const y2 = p2.y;
    return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
  }

  static midpoint(p1: IPoint, p2: IPoint): IPoint {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  static offset(p: IPoint, origin: IPoint): IPoint {
    return {
      x: p.x - origin.x,
      y: p.y - origin.y,
    };
  }

  static map(p: IPoint, f: (arg: number) => number): IPoint {
    return {
      x: f(p.x),
      y: f(p.y),
    };
  }

  static scale(p: IPoint, s: number) {
    return this.map(p, (v: number) => v * s);
  }

  static sum(p1: IPoint, p2: IPoint): IPoint {
    return {
      x: p1.x + p2.x,
      y: p1.y + p2.y,
    };
  }

  static isEqual(p1: IPoint, p2: IPoint): boolean {
    return p1.x === p2.x && p1.y === p2.y;
  }

  static boundWithin(min: IPoint, current: IPoint, max: IPoint): IPoint {
    const numberWithin = function (lower: number, num: number, upper: number) {
      if (num > upper) {
        return upper;
      }

      if (lower > num) {
        return lower;
      }

      return num;
    };
    return {
      x: numberWithin(min.x, current.x, max.x),
      y: numberWithin(min.y, current.y, max.y),
    };
  }

  static normalizePointInRect(point: IPoint, rectOrigin: IPoint): IPoint {
    return {
      x: point.x - rectOrigin.x,
      y: point.y - rectOrigin.y,
    };
  }

  static toSize(p: IPoint): IRect {
    return {
      width: p.x,
      height: p.y,
    };
  }
}
export default Point;
