import './style.scss';

import classNames from 'classnames';

import IconC, { ICON_SIZE } from '../../IconC';

const getIconForAlert = (type: APPEARANCE_TYPE = 'primary') => {
  const ICON_MAP = {
    danger: `exclamation-triangle--${import.meta.env.VITE_THEME}`,
    success: 'hook-circle',
    primary: `information-circle--${import.meta.env.VITE_THEME}`,
  };
  return ICON_MAP[type] || ICON_MAP.primary;
};

type AlertProps = {
  //The theme of the alert.
  theme?: APPEARANCE_TYPE;
  // The headline text of the alert.	N/A	-
  headline?: string;
  // The text displayed inside the alert.	N/A	-
  content?: JSX.Element | string;
  // Specifies if the alert has the option to close.
  isClosable?: boolean;
  // Defines whether the alert has a no decorative icon.
  hasNoIcon?: boolean;
  // Defines and overrides the default icon.
  icon?: string;
  // Adds the title attribute to the close button for better accessibility. This is relevant only if the alert is closable.	N/A	-
  textCloseButtonTitle?: string;
  // callback for close action
  closeHandler?: () => void;
};

export default function Alert({
  theme = 'primary',
  headline,
  content,
  isClosable = false,
  hasNoIcon = false,
  icon = getIconForAlert(theme),
  textCloseButtonTitle = '',
  closeHandler = () => undefined,
}: Readonly<AlertProps>) {
  return (
    <div
      role={theme === 'danger' || theme === 'warning' ? 'alert' : undefined}
      className={classNames({
        'nuc-global-scope': true,
        'nuc-m-alert': true,
        [`nuc-m-alert--theme-${theme}`]: true,
      })}>
      {!hasNoIcon && icon && (
        <div className="nuc-m-alert__icon" aria-hidden={true}>
          <IconC size={ICON_SIZE.L} icon={icon} />
        </div>
      )}
      <div className="nuc-m-alert__body">
        {headline && <div className="nuc-m-alert__heading">{headline}</div>}
        {content && typeof content === 'string' && (
          <p className="nuc-m-alert__content" dangerouslySetInnerHTML={{ __html: content }} />
        )}
        {content && typeof content !== 'string' && (
          <p className="nuc-m-alert__content">{content}</p>
        )}
      </div>
      {isClosable && (
        <div className="nuc-m-alert__close-button">
          <span className="nuc-m-alert__close-icon" title={textCloseButtonTitle}>
            <IconC
              size={ICON_SIZE.S}
              icon={`cross--${import.meta.env.VITE_THEME}`}
              inButton={true}
              onClick={closeHandler}
            />
          </span>
        </div>
      )}
    </div>
  );
}
