import { Action, ActionCreator } from 'redux';

import { SETTINGS_UPDATE } from './constants';

export interface SettingsUpdateAction extends Action {
  type: 'SETTINGS_UPDATE';
  settings: ASettings;
}

export type SettingsActions = SettingsUpdateAction;

export const settingsUpdate: ActionCreator<SettingsUpdateAction> = (settings: ASettings) => ({
  type: SETTINGS_UPDATE,
  settings,
});
