import AgeRestriction from 'components/AgeRestriction/index';
import Modal from 'components/Modal';
import Overlay from 'components/Overlay';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';

import { hideAgeRestriction } from './actions';

type AgeRestrictionContainerState = {
  show: boolean;
};

type AgeRestrictionContainerProps = {
  showAgeRestriction: boolean;
  age: number;
  privacyUrl: string;
  locale: string;
  homeUrl: string;
  handleHideAgeRestriction: (locale: string, cookiesAccepted: boolean) => void;
};

class AgeRestrictionContainer extends PureComponent<
  AgeRestrictionContainerProps,
  AgeRestrictionContainerState
> {
  readonly state = {
    show: true,
  };

  handleAccepted = (cookiesAccepted = false) => {
    this.setState({
      show: false,
    });

    this.props.handleHideAgeRestriction(this.props.locale, cookiesAccepted);
  };

  render() {
    if (!this.props.age || !this.props.showAgeRestriction || !this.state.show) {
      return null;
    }

    return (
      <Modal>
        <Overlay>
          <AgeRestriction
            onAccepted={this.handleAccepted}
            age={this.props.age}
            privacyUrl={this.props.privacyUrl}
            homeUrl={this.props.homeUrl}
          />
        </Overlay>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { age, show } = state.ageRestriction;

  return {
    locale: state.appData.locale,
    age,
    showAgeRestriction: show,
    privacyUrl: state.appData.urls.privacyUrl,
    homeUrl: state.appData.urls.homeUrl,
  };
};
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    handleHideAgeRestriction: (locale: string, cookiesAccepted: boolean) => {
      dispatch(hideAgeRestriction(locale, cookiesAccepted));
    },
  };
}

export default compose<any>(connect(mapStateToProps, mapDispatchToProps))(AgeRestrictionContainer);
