import './style.scss';

import IconC, { ICON_SIZE } from 'components/IconC';
import Tile from 'components/Tile';
import { motion } from 'framer-motion';
import usePreloadImage from 'helper/js/hooks/use-preload-image';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import { memo, useCallback, useContext, useMemo, useRef } from 'react';

const variants = {
  visible: (i: number) => ({
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.35 + i * 0.1,
    },
  }),
  hidden: { opacity: 0, scale: 0 },
};

type Props = {
  recipe: Recipe | any;
  index: number;
};
const Recipe = ({ recipe, index }: Props) => {
  const recipeContainer = useRef(null);
  const image = usePreloadImage(recipe ? recipe.image : undefined, recipeContainer);
  const MAX_DIFFICULTY_LEVEL = 3;

  const { trackEventUniversalAnalytics } = useContext(TrackingContext);

  const handleClick = useCallback(() => {
    trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.RECIPE_TILE_CLICKED,
      eventLabel: recipe.name,
    });
  }, [recipe, trackEventUniversalAnalytics]);

  const content = useMemo(() => {
    if (!recipe) {
      return (
        <>
          <div className="demo" />
          <div className="demo" />
        </>
      );
    }
    const { name } = recipe;
    return <h3 className="recipe__headline">{name}</h3>;
  }, [recipe]);

  const footer = useMemo(() => {
    if (!recipe) {
      return (
        <div className="dummy">
          <div className="demo" />
          <div className="demo" />
          <div className="demo" />
          <div className="demo" />
        </div>
      );
    }
    const { time, rating, difficulty } = recipe;

    const unfilledRecipeIcons = new Array(MAX_DIFFICULTY_LEVEL - difficulty).fill(null);
    const filledRecipeIcons = new Array(MAX_DIFFICULTY_LEVEL - unfilledRecipeIcons.length).fill(
      null,
    );

    return (
      <ul className="recipe__attributes">
        <li className="recipe__attribute">
          {filledRecipeIcons.map((filledRecipe, index) => (
            <span key={index} className="recipe__attribute-icon">
              <IconC size={ICON_SIZE.M} icon="recipe-fill" />
            </span>
          ))}
          {unfilledRecipeIcons.map((unfilledRecipe, index) => (
            <span key={index} className="recipe__attribute-icon">
              <IconC size={ICON_SIZE.M} icon={`recipe--${import.meta.env.VITE_THEME}`} />
            </span>
          ))}
        </li>
        <li className="recipe__attribute">
          <span className="recipe__attribute-icon">
            <IconC size={ICON_SIZE.M} icon="clock" />
          </span>
          <span className="recipe__attribute-value">
            {time}
            min
          </span>
        </li>
        <li className="recipe__attribute">
          <span className="recipe__attribute-icon">
            <IconC size={ICON_SIZE.M} icon={`heart--${import.meta.env.VITE_THEME}`} />
          </span>
          <span className="recipe__attribute-value">({rating})</span>
        </li>
      </ul>
    );
  }, [recipe]);

  return (
    <motion.li
      custom={index}
      animate="visible"
      initial="hidden"
      variants={variants}
      className="recipe"
      ref={recipeContainer}
      onClick={handleClick}>
      <Tile
        img={image}
        imgAlt={recipe ? recipe.imageAlt : ''}
        content={content}
        footer={footer}
        link={recipe ? recipe.url : '#'}
        imageFormat="77%"
      />
    </motion.li>
  );
};

export default memo(Recipe);
