import { getSearchParamsAsObject } from 'helper/js/helper';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { VIEW } from '../../helper/js/constants';
import { updateRouterParams } from './actions';

function RouterProvider({ children }: Readonly<PropsWithChildren>) {
  const params = useParams<AParamsType>();
  const location = useLocation();
  const dispatch = useDispatch();
  const urlSearchParams = new URLSearchParams(location.search);
  useMemo(() => {
    dispatch(
      updateRouterParams({
        ...{ params },
        searchQuery: getSearchParamsAsObject(location.search),
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(updateRouterParams({ params }));
  }, [dispatch, params]);

  const getRedirectUri = () => {
    const pageNumber = urlSearchParams.get('page') ?? params.page ?? 1;
    urlSearchParams.delete('page');

    return `/${params.uuid}/view/flyer/page/${pageNumber}?${decodeURIComponent(
      urlSearchParams.toString(),
    )}`;
  };

  const updatePageNumberFromSearchParam = () => {
    dispatch(
      updateRouterParams({
        params: {
          ...params,
          page: parseInt(urlSearchParams.get('page') as string, 10),
        },
        searchQuery: getSearchParamsAsObject(location.search),
      }),
    );
  };

  let shouldRedirect = !Object.values(VIEW as unknown as string[]).includes(`${params.view}`);

  if (urlSearchParams.has('page')) {
    updatePageNumberFromSearchParam();
    shouldRedirect = true;
  }
  if (shouldRedirect) {
    return <Navigate to={getRedirectUri()} replace />;
  }

  return <>{children}</>;
}

export default RouterProvider;
