import './style.scss';

import classNames from 'classnames';
import IconC, { ICON_SIZE } from 'components/IconC';
import { sleep } from 'helper/js/helper';
import useEventListener from 'helper/js/hooks/use-event-listener';
import { ReferenceContext } from 'providers/Reference/index';
import { memo, RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';

type Props = {
  text: string;
  element: RefObject<HTMLButtonElement | HTMLAnchorElement>;
  showOnMount: boolean;
};

type Position = {
  bottom: number;
  left?: number;
  right?: number;
  top: number;
  width: number;
};

const ARROW_WIDTH_SIDE = 6;
const ARROW_OFFSET = 24;

const Tooltip = ({ element, text, showOnMount = false }: Props) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const closeTimeOut = useRef<NodeJS.Timeout | null>(null);
  const currentHistory = useRef<any>(null);
  const referenceContext = useContext(ReferenceContext);

  const [isClosedByUser, setIsClosedByUser] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [placement, setPlacement] = useState('left');
  const [position, setPosition] = useState<Position>({
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
  });

  const tooltipClasses = classNames({
    tooltip: true,
    'tooltip--visible': isVisible,
    'tooltip--closeable': !isClosedByUser && showOnMount,
    'tooltip--right': placement === 'right',
  });

  const validatePosition = useCallback(
    (currentPosition: Position, tooltipBounds: DOMRect): Position | undefined => {
      const sheetRef = referenceContext.getRef('sheet').current;
      if (!sheetRef) {
        return undefined;
      }
      if (
        currentPosition.right &&
        currentPosition.left &&
        currentPosition.left + tooltipBounds.width > sheetRef.offsetWidth
      ) {
        const { right, left, ...pos } = currentPosition;
        setPlacement('right');
        return {
          ...pos,
          right: sheetRef.offsetWidth - right + (window.innerWidth - sheetRef.offsetWidth),
        };
      }

      setPlacement('left');
      const { right, ...pos } = currentPosition;
      return pos;
    },
    [referenceContext],
  );

  const calcPosition = useCallback(() => {
    if (!tooltipRef.current || !element.current) {
      return;
    }

    const tooltipBounds = tooltipRef.current.getBoundingClientRect();
    const elementBounds = element.current.getBoundingClientRect();

    const { bottom, left, right, width, top } = elementBounds;
    const pos: Position = {
      bottom,
      left: left - ARROW_OFFSET - ARROW_WIDTH_SIDE + width / 2,
      right: right + ARROW_OFFSET + ARROW_WIDTH_SIDE - width / 2,
      top: top - tooltipBounds.height - 10,
      width,
    };
    return validatePosition(pos, tooltipBounds);
  }, [element, validatePosition]);

  const setThePosition = useCallback(() => {
    const confirmedPosition = calcPosition();
    if (confirmedPosition) {
      setPosition(confirmedPosition);
      return true;
    }
    return false;
  }, [calcPosition]);

  const handleMouseEnter = useCallback(() => {
    const blub = async () => {
      closeTimeOut.current && clearTimeout(closeTimeOut.current);
      setIsMounted(true);
      await sleep(100);
      if (!tooltipRef.current || !element.current) {
        handleMouseEnter();
        return;
      }

      if (setThePosition()) {
        setIsVisible(true);
      }
    };
    blub();
  }, [element, setThePosition]);

  const dismiss = useCallback(
    (forceDismiss = false) => {
      if (showOnMount && !isClosedByUser && !forceDismiss) {
        return;
      }

      const handleDismissAsync = async () => {
        setIsVisible(false);
        await sleep(300);
        setIsMounted(false);
        setIsClosedByUser(true);
      };

      closeTimeOut.current = setTimeout(() => {
        handleDismissAsync();
      }, 300);
    },
    [isClosedByUser, showOnMount],
  );
  const handleMouseLeave = useCallback(() => {
    dismiss();
  }, [dismiss]);

  const handleDismiss = useCallback(() => {
    dismiss(true);
  }, [dismiss]);

  useEventListener('resize', setThePosition, window);
  useEventListener('mouseenter', handleMouseEnter, element.current as HTMLElement);
  useEventListener('mouseleave', handleMouseLeave, element.current as HTMLElement);

  useEffect(() => {
    if (showOnMount && !isClosedByUser && !isVisible) {
      handleMouseEnter();
    }

    if (!isVisible && currentHistory.current) {
      currentHistory.current();
    }
  }, [showOnMount, handleMouseEnter, isVisible, isClosedByUser, calcPosition, setThePosition]);

  if (!isMounted) {
    return null;
  }
  const { top, left, right } = position;

  return (
    <div
      style={{ top, left, right }}
      ref={tooltipRef}
      className={tooltipClasses}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {showOnMount && !isClosedByUser && (
        <button className="tooltip__close" onClick={handleDismiss} aria-label="Close tooltip">
          <IconC size={ICON_SIZE.S} icon={`cross--${import.meta.env.VITE_THEME}`} />
        </button>
      )}
      <div>{text}</div>
    </div>
  );
};

export default memo(Tooltip);
