import { Action, ActionCreator } from 'redux';

import { ROUTER_NAVIGATE_URL, ROUTER_UPDATE_PARAMS } from './constants';

export interface UpdateRouterParamsAction extends Action {
  type: 'ROUTER_UPDATE_PARAMS';
  routing: ARouting;
}

export interface UpdateUrlAction extends Action {
  type: 'ROUTER_NAVIGATE_URL';
  params: Partial<AParamsType>;
  replace: boolean;
}

export type RouterActions = UpdateRouterParamsAction | UpdateUrlAction;

export const updateRouterParams: ActionCreator<UpdateRouterParamsAction> = (routing: ARouting) => ({
  type: ROUTER_UPDATE_PARAMS,
  routing,
});

export const updateUrl: ActionCreator<UpdateUrlAction> = (
  params: Partial<AParamsType>,
  replace = false,
) => ({
  type: ROUTER_NAVIGATE_URL,
  params,
  replace,
});
