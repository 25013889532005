import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import ageRestriction from './containers/AgeRestrictionContainer/reducer';
import browser from './containers/BrowserContainer/reducer';
import appData from './containers/ContentContainer/reducer';
import notifications from './containers/NotificationContainer/reducer';
import productData from './containers/ProductOverviewDataContainer/reducer';
import recommendationProducts from './containers/RecommendedProductsContainer/reducer';
import search from './containers/SearchContainer/reducer';
import sheet from './containers/SheetContainer/reducer';
import wishlistProducts from './containers/WishListContainer/reducer';
import routing from './providers/Router/reducer';
import settings from './providers/Settings/reducer';
import { ageRestrictionConfig, routingConfig } from './store';

const reducers = () =>
  combineReducers({
    browser,
    appData,
    routing: persistReducer(routingConfig, routing),
    sheet,
    wishlistProducts,
    settings,
    productData,
    search,
    ageRestriction: persistReducer(ageRestrictionConfig, ageRestriction),
    recommendationProducts,
    notifications,
  });
export default reducers;
