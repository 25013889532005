import { getSearchParamsAsObject } from 'helper/js/helper';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function KameleoonProvider() {
  const queryData = getSearchParamsAsObject();
  const locale = useSelector((state: AppState) => state.appData.locale);
  const [isScriptAdded, setIsScriptAdded] = useState(false);

  const initScript = useCallback((siteCode: string) => {
    const KameleoonScript = getScript();
    const script = document.createElement('script');
    script.setAttribute('data-cookieconsent', 'marketing');
    const scriptText = document.createTextNode(KameleoonScript);
    script.appendChild(scriptText);
    document.head.appendChild(script);

    const mainScript = document.createElement('script');
    mainScript.src = `https://${siteCode}.kameleoon.eu/kameleoon.js`;
    mainScript.async = true;
    mainScript.setAttribute('data-cookieconsent', 'marketing');
    document.head.appendChild(mainScript);

    setIsScriptAdded(true);
  }, []);

  useEffect(() => {
    let siteCode = '';

    if (queryData._ab) {
      siteCode = 'dzrmsrfsh5';
    } else if (locale === 'sv-SE') {
      siteCode = 'jfctk50rep';
    } else if (locale === 'it-IT') {
      siteCode = '30twslwa7n';
    } else if (locale === 'de-DE') {
      siteCode = 'z9q9kj37oy';
    }

    if (siteCode && !isScriptAdded) {
      initScript(siteCode);
    }
  }, [initScript, isScriptAdded, locale, queryData]);

  const getScript = (): string =>
    `/*<![CDATA[*/
            (function() {
                var a = 1000;
                window.kameleoonQueue = window.kameleoonQueue || [];
                window.addEventListener('CookiebotOnConsentReady', function() {
                    kameleoonQueue.push(function() {
                        if (Cookiebot.consent.marketing) {
                            Kameleoon.API.Core.enableLegalConsent();
                        } else {
                            Kameleoon.API.Core.disableLegalConsent();
                        }
                    });
                }, false);
                /* OptanonWrapper() is called twice - 1. On load - 2. On consent interaction - accept/reject */
                window.OptanonWrapper = function() {
                    var marketingConsent = 'C0004';
                    kameleoonQueue.push(function() {
                        if (OnetrustActiveGroups && OnetrustActiveGroups.includes(marketingConsent)) {
                            Kameleoon.API.Core.enableLegalConsent();
                        } else {
                            Kameleoon.API.Core.disableLegalConsent();
                        }
                    });
                    if (OneTrust) {
                      OneTrust.changeLanguage("${locale.toLowerCase()}");
                    }
                }
                ;
                window.kameleoonStartLoadTime = new Date().getTime();
                if (!document.getElementById("kameleoonLoadingStyleSheet") && !window.kameleoonDisplayPageTimeOut) {
                    var b = document.getElementsByTagName("script")[0];
                    var c = "* { visibility: hidden !important; background-image: none !important; }";
                    var d = document.createElement("style");
                    d.type = "text/css";
                    d.id = "kameleoonLoadingStyleSheet";
                    if (d.styleSheet) {
                        d.styleSheet.cssText = c
                    } else {
                        d.appendChild(document.createTextNode(c))
                    }
                    b.parentNode.insertBefore(d, b);
                    window.kameleoonDisplayPage = function(e) {
                        if (!e) {
                            window.kameleoonTimeout = true
                        }
                        if (d.parentNode) {
                            d.parentNode.removeChild(d)
                        }
                    }
                    ;
                    window.kameleoonDisplayPageTimeOut = window.setTimeout(window.kameleoonDisplayPage, a)
                };
                window.kameleoonIframeURL = "${window.location.hostname}/n/kameleoon-iframe";
                var f = document.createElement("a");
                window.kameleoonLightIframe = false;
                f.href = window.kameleoonIframeURL;
                window.kameleoonIframeOrigin = f.origin || (f.protocol + "//" + f.hostname);
                if (location.href.indexOf(window.kameleoonIframeOrigin) != 0) {
                    window.kameleoonLightIframe = true;
                    var g = function(event) {
                        if (window.kameleoonIframeOrigin == event.origin && event.data.slice && event.data.slice(0, 9) == "Kameleoon") {
                            window.removeEventListener("message", g);
                            window.kameleoonExternalIFrameLoaded = true;
                            if (window.Kameleoon) {
                                eval(event.data);
                                Kameleoon.Analyst.load()
                            } else {
                                window.kameleoonExternalIFrameLoadedData = event.data
                            }
                        }
                    };
                    if (window.addEventListener) {
                        window.addEventListener("message", g, false)
                    }
                    var h = document.createElement("iframe");
                    h.src = kameleoonIframeURL;
                    h.id = "kameleoonExternalIframe";
                    h.style = "float: left !important; opacity: 0.0 !important; width: 0px !important; height: 0px !important;";
                    document.head.appendChild(h)
                }
                ;
            }
            )();
            /*]]>*/`;

  return null;
}

export default KameleoonProvider;
