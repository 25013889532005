import { Action, ActionCreator } from 'redux';

import {
  SHEET_NAVIGATE,
  SHEET_NAVIGATE_BEFORE_START,
  SHEET_NAVIGATE_END,
  SHEET_NAVIGATE_START,
  SHEET_PAGES_VISIBLE_UPDATE,
  SHEET_PAGES_VISIBLE_UPDATED,
  SHEET_PAGES_ZOOM_UPDATE,
} from './constants';

export interface UpdateVisiblePagesAction extends Action {
  type: 'SHEET_PAGES_VISIBLE_UPDATE';
  page?: number;
}

export interface UpdatedVisiblePagesAction extends Action {
  type: 'SHEET_PAGES_VISIBLE_UPDATED';
  currentVisible: number;
  flipPages: number;
}

export interface UpdateZoomLevelAction extends Action {
  type: 'SHEET_PAGES_ZOOM_UPDATE';
  zoom: number;
}

export interface SheetNavigateBeforeStartAction extends Action {
  type: 'SHEET_NAVIGATE_BEFORE_START';
  flipToPage?: number;
}

export interface SheetNavigateStartAction extends Action {
  type: 'SHEET_NAVIGATE_START';
  animationType: string;
}

export interface SheetNavigateEndAction extends Action {
  type: 'SHEET_NAVIGATE_END';
}

export interface FlipToPageAction extends Action {
  type: 'SHEET_NAVIGATE';
  hasNavigated: boolean;
  currentPage?: number;
  targetPage: number;
}

export interface NavigateAbsoluteAction extends Action {
  type: 'SHEET_NAVIGATE_TO_PAGE';
  page: number;
  hasNavigated: boolean;
}

export type SheetActionTypes =
  | UpdateVisiblePagesAction
  | UpdateZoomLevelAction
  | SheetNavigateBeforeStartAction
  | SheetNavigateStartAction
  | SheetNavigateEndAction
  | FlipToPageAction
  | NavigateAbsoluteAction
  | UpdatedVisiblePagesAction;

export const updateVisiblePagesAction: ActionCreator<UpdateVisiblePagesAction> = (
  page?: number,
) => ({
  type: SHEET_PAGES_VISIBLE_UPDATE,
  page,
});

export const updateZoomLevel: ActionCreator<UpdateZoomLevelAction> = (zoom: number) => ({
  type: SHEET_PAGES_ZOOM_UPDATE,
  zoom,
});

export const sheetNavigateBeforeStart: ActionCreator<SheetNavigateBeforeStartAction> = (
  flipToPage: number,
) => ({
  type: SHEET_NAVIGATE_BEFORE_START,
  flipToPage,
});
export const sheetNavigateStart: ActionCreator<SheetNavigateStartAction> = (
  animationType: string,
) => ({
  type: SHEET_NAVIGATE_START,
  animationType,
});
export const sheetNavigateEnd: ActionCreator<SheetNavigateEndAction> = () => ({
  type: SHEET_NAVIGATE_END,
});

export const updatedVisiblePagesAction: ActionCreator<UpdatedVisiblePagesAction> = (
  currentVisible: number,
  flipPages: number,
) => ({
  type: SHEET_PAGES_VISIBLE_UPDATED,
  currentVisible,
  flipPages,
});
export const flipToPage: ActionCreator<FlipToPageAction> = (
  currentPage: number | undefined,
  targetPage: number,
  hasNavigated = false,
) => ({
  type: SHEET_NAVIGATE,
  hasNavigated,
  currentPage,
  targetPage,
});
