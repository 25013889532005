import './style.scss';

import classNames from 'classnames';
import SidebarHeader from 'components/SidebarHeader';
import SidebarPages from 'components/SidebarPages';
import { FLYER_FLIP_DURATION } from 'containers/SheetContainer/constants';
import { SidebarContenPage } from 'containers/SidebarContainer';
import { AnimatePresence, motion } from 'framer-motion';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { VIEW } from '../../helper/js/constants';
import { SidebarContext } from './context';

type SidebarProps = {
  onClose: (...args: any[]) => any;
  hasContent: boolean;
  contentClassName: string;
  pageContent: SidebarContenPage;
  isSm: boolean;
  view: string;
  history: VIEW[];
};

const variants = {
  xs: {
    exit: {
      left: '100%',
    },
    enter: {
      left: '0px',
    },
  },
  sm: {
    exit: {
      flexBasis: '0px',
    },
    enter: {
      flexBasis: '350px',
    },
  },
};
function Sidebar({
  onClose = () => {},
  hasContent = false,
  contentClassName = '',
  pageContent,
  isSm,
  view,
  history,
}: Readonly<SidebarProps>) {
  const [currentPage, setCurrentPage] = useState(0);
  const [globalData, setGlobalData] = useState({});

  const sidebarClasses = classNames({
    sidebar: true,
    'sidebar--open': hasContent,
  });

  const handlePageChange = useCallback((currentPage) => {
    setCurrentPage(currentPage);
  }, []);

  const handleGlobalData = useCallback(
    (data) => {
      setGlobalData({ ...globalData, ...data });
    },
    [globalData],
  );
  useEffect(() => {
    setCurrentPage(0);
  }, [view]);

  const sidebarContextProviderProps = useMemo(
    () => ({
      currentPage,
      handlePageChange,
      globalData,
      handleGlobalData,
    }),
    [currentPage, globalData, handleGlobalData, handlePageChange],
  );

  return (
    <AnimatePresence>
      {hasContent && (
        <motion.aside
          className={sidebarClasses}
          animate={isSm ? variants.sm.enter : variants.xs.enter}
          exit={isSm ? variants.sm.exit : variants.xs.exit}
          transition={{ duration: FLYER_FLIP_DURATION / 2000 }}>
          <div className="sidebar__wrapper">
            <SidebarContext.Provider value={sidebarContextProviderProps}>
              <SidebarHeader
                onClose={onClose}
                headline={pageContent.header[currentPage]}
                history={history}
              />
              <div className="sidebar__content">
                <SidebarPages pages={pageContent.component} contentClassName={contentClassName} />
              </div>
            </SidebarContext.Provider>
          </div>
        </motion.aside>
      )}
    </AnimatePresence>
  );
}

export default memo(Sidebar);
