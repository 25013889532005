import './style.scss';

import { Component } from 'react';

const params: IKeyValueData = {
  youtube: {
    autoplay: 1,
    hl: 'en',
    iv_load_policy: 3,
    origin: window.location.origin,
    playsinline: 0,
    rel: 0,
  },
  movingimage: {
    playerskin: 45049,
    playertype: 'html5',
  },
  bluebillywig: {
    inheritDimensions: true,
    lidlleaflet: true,
  },
};

type VideoProps = {
  embedFragment: string;
  videoProvider: string;
  inline?: boolean;
};
type VideoState = {
  error: boolean;
};

declare let bluebillywig: any;

export default class Video extends Component<VideoProps, VideoState> {
  state = {
    error: false,
  };

  url = '';

  realIdentifier = '';

  idRandomizer = 0;

  constructor(props: VideoProps) {
    super(props);
    if (props.videoProvider) {
      this.url = props.embedFragment;
      if (params[props.videoProvider]) {
        this.url += this.getParamsForVideoType(params[props.videoProvider]);
      }
    } else {
      this.state = {
        error: true,
      };
    }

    if (this.props.videoProvider === 'bluebillywig') {
      this.idRandomizer = Math.floor(Math.random() * 10000);
      if (this.props.embedFragment.includes('https')) {
        const idArray = this.props.embedFragment.split('/');
        const idString = idArray[6];
        this.realIdentifier = idString.split('.')[0];
      } else {
        this.realIdentifier = this.props.embedFragment;
      }
    }
  }

  getParamsForVideoType = (videoParams: IKeyValueData) => {
    let params = '?';
    for (const key in videoParams) {
      if (videoParams.hasOwnProperty(key)) {
        params += `${key}=${videoParams[key]}&`;
      }
    }
    return params.slice(0, -1);
  };

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    if (this.props.videoProvider === 'bluebillywig') {
      let height: any = 'auto';
      // getting the height of the inline video
      const videoDiv: HTMLElement | null = document.querySelector(
        `#video-${this.realIdentifier}-${this.idRandomizer}`,
      );
      if (videoDiv instanceof HTMLElement) {
        height = videoDiv.offsetHeight;
      }
      const script = document.createElement('script');
      script.src = 'https://lidlbe.bbvms.com/launchpad/'; // URL for the third-party library being loaded.
      document.body.appendChild(script);
      script.onload = () => {
        let myPlayer;
        // Embed the player in the element "bluebillywigPlayer". Other playout settings (like width, height, autoPlay) can also be overruled by including them in the object passed as a second parameter:
        if (this.props.inline) {
          myPlayer = new bluebillywig.Player(this.props.embedFragment, {
            target: document.getElementById(
              `bluebillywigPlayer-${this.realIdentifier}-${this.idRandomizer}`,
            ),
            autoPlay: 'false',
            width: '100%',
            height: this.props.inline ? `${height}px` : '',
          });
        } else {
          myPlayer = new bluebillywig.Player(this.props.embedFragment, {
            target: document.getElementById(
              `bluebillywigPlayer-${this.realIdentifier}-${this.idRandomizer}`,
            ),
            autoPlay: 'false',
          });
        }

        // call the play method on the newly created player:
        myPlayer.play();
      };
    }
  }

  render() {
    if (this.state.error) {
      return null;
    }

    return this.props.videoProvider === 'bluebillywig' ? (
      <div
        className="video video--bluebillywig"
        id={`video-${this.realIdentifier}-${this.idRandomizer}`}>
        <div
          className="bluebillywigPlayer"
          id={`bluebillywigPlayer-${this.realIdentifier}-${this.idRandomizer}`}
        />
      </div>
    ) : (
      <div className={`video video--${this.props.videoProvider}`}>
        <iframe title={this.url} src={this.url} allow="autoplay; encrypted-media" allowFullScreen />
      </div>
    );
  }
}
