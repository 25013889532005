import { Action, ActionCreator } from 'redux';

import {
  WISHLIST_ALL_PRODUCTS_DELETED,
  WISHLIST_CHANGED,
  WISHLIST_PRODUCT_ADDED,
  WISHLIST_PRODUCT_DELETED,
} from './constants';

export type ProductData = {
  key: number;
  variant: string;
};

export interface WishListChangedSagaAction extends Action {
  type: 'WISHLIST_CHANGED';
  product: ProductData;
}

export interface AddProductAction extends Action {
  type: 'WISHLIST_PRODUCT_ADDED';
  product: ProductData;
}

export interface DeleteProductAction extends Action {
  type: 'WISHLIST_PRODUCT_DELETED';
  product: ProductData;
}

export interface DeleteAllProductsAction extends Action {
  type: 'WISHLIST_ALL_PRODUCTS_DELETED';
}

export type WishListActions =
  | WishListChangedSagaAction
  | AddProductAction
  | DeleteProductAction
  | DeleteAllProductsAction;

export const wishListChangedSaga: ActionCreator<WishListChangedSagaAction> = (
  product: ProductData,
) => ({
  type: WISHLIST_CHANGED,
  product,
});

export const addProduct: ActionCreator<AddProductAction> = (
  product: ProductData,
  uuid: string,
) => ({
  type: WISHLIST_PRODUCT_ADDED,
  product,
  uuid,
});

export const deleteProduct: ActionCreator<DeleteProductAction> = (product: ProductData) => ({
  type: WISHLIST_PRODUCT_DELETED,
  product,
});

export const deleteAllProducts: ActionCreator<DeleteAllProductsAction> = () => ({
  type: WISHLIST_ALL_PRODUCTS_DELETED,
});
