import { Component, ReactNode } from 'react';

type SheetPagesProps = {
  currentPages: ReactNode[] | ReactNode;
  nextPages: ReactNode[] | ReactNode;
  prePages: ReactNode[] | ReactNode;
};
export default class SheetPages extends Component<SheetPagesProps, {}> {
  render() {
    const { prePages, currentPages, nextPages } = this.props;
    return (
      <>
        {prePages}
        {currentPages}
        {nextPages}
      </>
    );
  }
}
