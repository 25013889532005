import './style.scss';

import { motion } from 'framer-motion';
import { PureComponent, ReactNode } from 'react';

type HeaderProps = {
  left?: ReactNode[] | ReactNode;
  center?: ReactNode[] | ReactNode;
  right?: ReactNode[] | ReactNode;
  bottom?: ReactNode[] | ReactNode;
  hideLeft: boolean;
  hideCenter: boolean;
  hideRight: boolean;
  hasError?: boolean;
};

const variants = {
  active: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
  inactive: {
    opacity: 0,
    transition: { duration: 0.5 },
  },
};

class Header extends PureComponent<HeaderProps, {}> {
  getContent = (content: ReactNode[] | ReactNode, position: string, visibility: boolean) => (
    <motion.section
      initial={false}
      variants={variants}
      animate={visibility ? 'inactive' : 'active'}
      className={`header__item header__item--${position}`}>
      {content}
    </motion.section>
  );

  render() {
    const {
      left,
      center,
      right,
      bottom,
      hideLeft,
      hideCenter,
      hideRight,
      hasError = false,
    } = this.props;
    return (
      <header className="header">
        <div className={`header__row${hasError ? ' header__row--has-error' : ''}`}>
          {this.getContent(left, 'start', hideLeft)}
          {this.getContent(center, 'center', hideCenter)}
          {this.getContent(right, 'end', hideRight)}
        </div>
        {bottom}
      </header>
    );
  }
}

export default Header;
