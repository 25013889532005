import './style.scss';

import IconC from 'components/IconC';
import { useContext } from 'react';

import { LanguageContext } from '../../providers/Language';
import { TrackingContext } from '../../providers/Tracking';
import TRACKING_EVENTS from '../../providers/Tracking/constants';
import TRACKING_EVENT_COLLECTIONS from '../../providers/Tracking/trackingEventCollections';

type SocialItemProps = {
  service: string;
  href: string;
  icon: string;
  title?: string;
  isWishlistShare?: boolean;
};

const SocialItem = ({ href, service, icon, title, isWishlistShare = false }: SocialItemProps) => {
  const { trackEventUniversalAnalytics, trackEventDataLayerService } = useContext(TrackingContext);
  const { t } = useContext(LanguageContext);

  if (!href) {
    return null;
  }

  const handleClick = () => {
    const event = isWishlistShare
      ? TRACKING_EVENTS.SHARE_WISHLIST_BUTTON_CLICKED
      : TRACKING_EVENTS.SHARE_FLYER_BUTTON_CLICKED;
    trackEventUniversalAnalytics({
      ...event,
      eventLabel: service,
    });

    if (!isWishlistShare) {
      trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.SHARE_FLYER_BUTTON_CLICKED, {
        contentLabel: service,
      });
    }
  };

  return (
    <li
      className={`social__share__item social__share__item--${service} ${
        isWishlistShare ? 'social__share__item--wishlist' : ''
      }`}>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        aria-label={`${t('shareFlyerHeadline')} ${service}`}
        className="social__share__link">
        <div
          className={`social__share__bg social__share__bg--${service} ${
            isWishlistShare ? 'social__share__bg--wishlist' : ''
          }`}>
          <IconC icon={icon} />
        </div>
      </a>
      {isWishlistShare && <p className="social__share__title">{title}</p>}
    </li>
  );
};

export default SocialItem;
