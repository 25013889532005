import { getSearchParamsAsObject } from 'helper/js/helper';

const useRecommendedProductsOpenerVersion = () => {
  let version = 'a';

  const searchParams = getSearchParamsAsObject();
  if (searchParams && searchParams.reco === 'b') {
    version = 'b';
  }
  return version;
};

export default useRecommendedProductsOpenerVersion;
