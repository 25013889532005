import Modal from 'components/Modal';
import Overlay from 'components/Overlay';
import TextOverlay from 'components/TextOverlay';
import VideoOverlay from 'components/VideoOverlay';
import { VIEW } from 'helper/js/constants';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

type OverlayContainerState = {
  close: boolean;
};

type OverlayContainerProps = {
  close: boolean;
  video?: AVideoData;
  text?: string;
  handleClose: () => void;
  showOverlay: boolean;
  currentView: string;
  contentId: string;
};

class OverlayContainer extends PureComponent<OverlayContainerProps, OverlayContainerState> {
  constructor(props: OverlayContainerProps) {
    super(props);
    this.state = {
      close: false,
    };
  }

  handleClose = () => {
    this.props.handleClose();
  };

  getContent = () => {
    if (this.props.video) {
      const { embedFragment, videoProvider } = this.props.video;
      return <VideoOverlay videoProvider={videoProvider} embedFragment={embedFragment} />;
    }
    if (this.props.text) {
      return <TextOverlay text={this.props.text} />;
    }
    return null;
  };

  render() {
    return (
      this.props.showOverlay && (
        <Modal>
          <Overlay onClose={this.handleClose}>{this.getContent()}</Overlay>
        </Modal>
      )
    );
  }
}
const mapStateToProps = (state: AppState, props: OverlayContainerProps) => {
  const { show } = state.ageRestriction;
  const { currentView, contentId } = props;
  const { videos, texts } = state.appData;
  const video = currentView === VIEW.VIDEO && contentId && videos[contentId];
  const text = currentView === VIEW.TEXT && contentId && texts[contentId];

  return {
    showOverlay: !show && (video || text),
    video,
    text,
  };
};

export default compose<any>(connect(mapStateToProps))(OverlayContainer);
