import './style.scss';

import ButtonC, { BUTTON_ALIGN_LABEL, BUTTON_HOVERSTYLE } from 'components/ButtonC';
import { LINK_TARGET, LinkTargetTypes } from 'components/Link';
import { Component } from 'react';

type MenuItemProps = {
  text?: string;
  icon?: string;
  onClick: () => void;
  href?: string;
  target: LinkTargetTypes;
  className: string;
  badge?: string;
};

export default class MenuItem extends Component<MenuItemProps, {}> {
  static defaultProps = {
    onClick: () => {},
    className: '',
    target: LINK_TARGET.BLANK,
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { text, icon, href, onClick, target, badge } = this.props;

    return (
      <li className="menu__list-item">
        {href ? (
          <ButtonC
            hoverStyle={BUTTON_HOVERSTYLE.ICON}
            fullwidth
            icon={icon}
            href={href}
            target={target}
            onClick={onClick}
            alignLabel={BUTTON_ALIGN_LABEL.LEFT}
            labelBold={false}
            noPaddingSide
            transformLabelToUppercase={false}
            className="menu-item__button">
            {text}
            {badge && <span className="badge">{badge}</span>}
          </ButtonC>
        ) : (
          <ButtonC
            hoverStyle={BUTTON_HOVERSTYLE.ICON}
            fullwidth
            icon={icon}
            onClick={onClick}
            alignLabel={BUTTON_ALIGN_LABEL.LEFT}
            labelBold={false}
            noPaddingSide
            transformLabelToUppercase={false}
            className="menu-item__button">
            {text}
            {badge && <span className="badge">{badge}</span>}
          </ButtonC>
        )}
      </li>
    );
  }
}
