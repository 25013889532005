import { SidebarContext } from 'components/Sidebar/context';
import { useContext } from 'react';

import { SIDEBAR } from '../../../helper/js/constants';
import styles from './SubNavContent.module.scss';

function RatingContent() {
  const { globalData, currentPage } = useContext(SidebarContext);
  const rating = globalData.rating as Rating;

  if (!rating || currentPage !== SIDEBAR.PAGES.RATINGS) {
    return null;
  }

  return <iframe className={styles.iframe} title="product rating" src={rating.ratingUrl} />;
}
export default RatingContent;
