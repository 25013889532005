import Content from 'components/Content';
import RecommendedProductsOpener from 'components/RecommendedProductsOpener';
import NavigationButtonContainer, {
  NAVIGATION_BUTTON_TYPES,
} from 'containers/NavigationButtonContainer';
import SheetContainer from 'containers/SheetContainer';
import { VIEW } from 'helper/js/constants';
import { useFlippyNavigate, useFlippyPageNumber, useFlippyView } from 'helper/js/hooks/router';
import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getVisiblePagesAmount } from '../../helper/js/helper';
import { updateRecommendationProducts } from '../RecommendedProductsContainer/actions';

const FlyerContentContainer = () => {
  const isBrowserStateReady = useSelector((state: AppState) => state.browser.isReadyForRender);
  const isSmMin = useSelector((state: AppState) => state.browser.isSmMin);
  const recommendedProductsLength = useSelector(
    (state: AppState) => state.recommendationProducts.count,
  );
  const flippyView = useFlippyView();
  const flippyNavigate = useFlippyNavigate();
  const flippyPageNumber = useFlippyPageNumber();
  const hasTopics = useSelector((state: AppState) => state.settings.hasTopics);
  const showTopics = useSelector((state: AppState) => state.appData.showTopics);
  const pages = useSelector((state: AppState) => state.appData.pages);
  const isWideScreen = useSelector((state: AppState) => state.browser.isWideScreen);
  const currentVisible = getVisiblePagesAmount(flippyPageNumber, pages.length, isWideScreen);
  const flipPages = useSelector((state: AppState) => state.sheet.flipPages);
  const isNavigating = useSelector((state: AppState) => state.sheet.navigate);
  const dispatch = useDispatch();
  const isFirstPage = flippyPageNumber === 1;
  const isLastPage =
    flippyPageNumber === pages.length ||
    (flipPages === 2 && pages.length > 2 && flippyPageNumber === pages.length - 1);

  const updateView = useCallback((view: string) => flippyNavigate({ view }), [flippyNavigate]);

  const updatePageNumber = useCallback(
    (targetPage: number, replace = false) => {
      flippyNavigate({ page: `${targetPage}` }, { replace });
    },
    [flippyNavigate],
  );

  const handleShowRecommendedProducts = useCallback(() => {
    updateView(VIEW.RECOMMENDEDPRODUCTS);
  }, [updateView]);

  useEffect(() => {
    if (isSmMin && hasTopics && showTopics && flippyView === VIEW.FLYER) {
      updateView(VIEW.JUMPMARKS);
    }
  }, [hasTopics, isSmMin, flippyView, showTopics, updateView]);

  useEffect(() => {
    if (isNavigating) {
      return;
    }

    const isRequestedPageLargerThenLastPage = flippyPageNumber > pages.length;
    const isRequestedPageWithinValidPageRange =
      flippyPageNumber <= pages.length && flippyPageNumber >= 1;
    const isRequestedPageOdd = flippyPageNumber % 2 !== 0;

    if (isRequestedPageLargerThenLastPage) {
      updatePageNumber(pages.length, true);
    } else if (
      isRequestedPageWithinValidPageRange &&
      isRequestedPageOdd &&
      pages[0] &&
      currentVisible === 2 &&
      flippyPageNumber > 1
    ) {
      updatePageNumber(flippyPageNumber - 1, true);
    } else if (flippyPageNumber < 1) {
      updatePageNumber(1, true);
    }
  }, [currentVisible, flippyPageNumber, isNavigating, isWideScreen, pages, updatePageNumber]);

  useEffect(() => {
    let recommendationProducts: string[] = [];
    const pageItem = pages.find((page) => page.number === flippyPageNumber);
    const nextPageItem = pages.find(
      (page) => page.number === flippyPageNumber + 1 && currentVisible === 2,
    );
    if (pageItem?.recommendedProductIds?.length) {
      recommendationProducts = pageItem.recommendedProductIds;
    }
    if (nextPageItem?.recommendedProductIds?.length) {
      recommendationProducts = [...recommendationProducts, ...nextPageItem.recommendedProductIds];
    }
    //unique
    recommendationProducts = recommendationProducts.filter(
      (product, index, array) => array.indexOf(product) === index,
    );

    dispatch(
      updateRecommendationProducts({
        products: recommendationProducts,
        count: recommendationProducts.length,
      }),
    );
  }, [flippyPageNumber, currentVisible, dispatch, pages]);

  if (!isBrowserStateReady) {
    return null;
  }

  return (
    <Content>
      {isSmMin && !isFirstPage && (
        <div className="content_navigation content_navigation--left">
          <NavigationButtonContainer
            direction={NAVIGATION_BUTTON_TYPES.backward}
            icon={`arrow-left--${import.meta.env.VITE_THEME}`}
            className="content_navigation"
          />
        </div>
      )}
      <SheetContainer />
      {isSmMin && (
        <RecommendedProductsOpener
          onClick={handleShowRecommendedProducts}
          recommendedProductsLength={recommendedProductsLength}
        />
      )}
      {isSmMin && !isLastPage && (
        <div className="content_navigation content_navigation--right">
          <NavigationButtonContainer
            direction={NAVIGATION_BUTTON_TYPES.forward}
            icon={`arrow-right--${import.meta.env.VITE_THEME}`}
            className="content_navigation"
          />
        </div>
      )}
    </Content>
  );
};

export default memo(FlyerContentContainer);
