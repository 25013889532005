import { Action, ActionCreator } from 'redux';

import { NOTIFCATION_SEEN, NOTIFICATION_LOADED } from './constants';

export interface NotificationLoadedAction extends Action {
  type: 'NOTIFICATION_LOADED';
  data: ANotificationData[];
}

export interface NotificationSeenAction extends Action {
  type: 'NOTIFCATION_SEEN';
  seen: string[];
}

export type NotificationActions = NotificationLoadedAction | NotificationSeenAction;

export const notificationLoaded: ActionCreator<NotificationLoadedAction> = (data) => ({
  type: NOTIFICATION_LOADED,
  data,
});

export const notificationSeen: ActionCreator<NotificationSeenAction> = (seen) => ({
  type: NOTIFCATION_SEEN,
  seen,
});
