import './style.scss';

import { AnimatePresence, motion } from 'framer-motion';
import { Component, ReactNode } from 'react';

type FooterProps = {
  left?: ReactNode[] | ReactNode;
  center?: ReactNode[] | ReactNode;
  right?: ReactNode[] | ReactNode;
  showFooter: boolean;
};
class Footer extends Component<FooterProps, {}> {
  render() {
    const { left, center, right, showFooter } = this.props;
    return (
      <AnimatePresence>
        {showFooter && (
          <motion.footer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`footer footer--${import.meta.env.VITE_THEME}`}>
            <div className={`footer__row footer__row--${import.meta.env.VITE_THEME}`}>
              <section className={`footer__item footer__item--${import.meta.env.VITE_THEME}`}>
                {left}
              </section>
              <section
                className={`footer__item footer__item--center footer__item--${
                  import.meta.env.VITE_THEME
                }`}>
                {center}
              </section>
              <section
                className={`footer__item footer__item--end footer__item--${
                  import.meta.env.VITE_THEME
                }`}>
                {right}
              </section>{' '}
            </div>
          </motion.footer>
        )}
      </AnimatePresence>
    );
  }
}

export default Footer;
