import svgUrlNewsletter from 'components/PageNewsletter/newsletter.svg';
import initialState from 'initialState';

import { getProductsOverview } from '../api/products';

class FlyerValidationService {
  _state: AAppData = {
    ...initialState.appData,
  };

  _settings: ASettings = {
    ...initialState.settings,
  };

  _flyerData: any = {};

  _routerSearchQuery: ASearchQuery = initialState.routing.searchQuery;

  _notifications: ANotificationData[] = initialState.notifications.data;

  _routerParams: AParamsType = initialState.routing.params;

  _wishListProductIds: number[] = [];

  _productIdsAvailable: number[] = [];

  _hasAgeRestriction = false;

  _ageRestrictionData: AAgeRestriction = {
    show: false,
    accepted: {},
  };

  set routerSearchQuery(routerSearchQuery: ASearchQuery) {
    this._routerSearchQuery = { ...routerSearchQuery };
  }

  set routerParams(routerParams: AParamsType) {
    this._routerParams = { ...routerParams };
  }

  set notification(notifications: ANotificationData[]) {
    this._notifications = notifications;
  }

  get notification(): ANotificationData[] {
    return this._notifications;
  }

  set ageRestrictionData(ageRestrictionData: AAgeRestriction) {
    this._ageRestrictionData = { ...ageRestrictionData };
  }

  get ageRestrictionData(): AAgeRestriction {
    return this._ageRestrictionData;
  }

  set flyerData(flyerData: any) {
    this._flyerData = { ...flyerData };
  }

  get appState(): AAppData {
    return this._state;
  }

  get settings(): ASettings {
    return this._settings;
  }

  get hasAgeRestriction(): boolean {
    return this._hasAgeRestriction;
  }

  set wishlistData(wishlistData) {
    if (wishlistData) {
      this._wishListProductIds = wishlistData.map((product: any) =>
        parseInt(Object.keys(product)[0], 10),
      );
    }
  }

  get wishlistData() {
    return this._wishListProductIds;
  }

  checkForUrlParameterBeforeConfig = (
    configParam: boolean,
    urlParam?: string | boolean,
  ): string | boolean => {
    if (typeof urlParam !== 'undefined') {
      return urlParam;
    }

    return configParam;
  };

  validateFlyerData = (): boolean => {
    if (!this._flyerData.flyer) {
      throw new Error('NO FLYER DATA');
    }
    return true;
  };

  removeTopicsFromFlyer = (): void => {
    this._settings = {
      ...this._settings,
      hasTopics: false,
    };
  };

  enableNotificationCenter = (): void => {
    this._settings = {
      ...this._settings,
      showNotifications: true,
    };
  };

  enableDebug = (): void => {
    this._settings = {
      ...this._settings,
      debug: true,
    };
  };

  enableSharing = (): void => {
    this._settings = {
      ...this._settings,
      enableSharing: true,
    };
  };

  enableDownload = (): void => {
    this._settings = {
      ...this._settings,
      enableDownload: true,
    };
  };

  enableWishlist = (): void => {
    this._settings = {
      ...this._settings,
      showWishList: true,
    };
  };

  enableDebugTracking = (): void => {
    this._settings = {
      ...this._settings,
      debugTracking: true,
    };
  };

  enableMyAccount = (): void => {
    this._settings = {
      ...this._settings,
      myAccount: true,
    };
  };

  enableShoppingCart = (): void => {
    this._settings = {
      ...this._settings,
      shoppingCart: true,
    };
  };

  getOutdatedProductIds = (): number[] =>
    this._wishListProductIds.filter(
      (wishListProductId) => this._productIdsAvailable.indexOf(wishListProductId) <= -1,
    );

  changeDisplayTypeforLinks = (initialType: string, toType: ALinkDisplayTypes): APagesItem[] =>
    this._state.pages.map((page: APagesItem) => {
      const { links } = page;

      if (links) {
        const linksMod = links.map((link) => {
          if (link.displayType === initialType) {
            link = { ...link, displayType: toType };
            return link;
          }
          return link;
        });
        page = { ...page, links: [...linksMod] };
      }
      return page;
    });

  removeAllRecommendedProductsFromPage = (): void => {
    this._state = {
      ...this._state,
      pages: this._state.pages.map((page: APagesItem) => {
        page = { ...page, recommendedProductIds: [] };
        return page;
      }),
    };
  };

  transformAllProductsToDisplayNoDetailsInSidebar = (): void => {
    this._settings = {
      ...this._settings,
      showProductsInSidebar: false,
    };

    this._state = {
      ...this._state,
      pages: this.changeDisplayTypeforLinks('product', 'productnodetails'),
    };
  };

  removeAllProducts = (): void => {
    this._state = {
      ...this._state,
      pages: this.changeDisplayTypeforLinks('product', 'standard'),
      products: {},
    };
  };

  allProductsToStandardLinks = (): void => {
    this._state = {
      ...this._state,
      pages: this.changeDisplayTypeforLinks('product', 'standard'),
    };
  };

  removeLinksFromPages = (): void => {
    const pages = this._state.pages.map((page: APagesItem) => {
      page = { ...page, links: [] };
      return page;
    });
    this._state = {
      ...this._state,
      pages: [...pages],
    };
  };

  addPropertyHideQuitButton = (): void => {
    this._settings = {
      ...this._settings,
      hideQuitButton: true,
    };
  };

  addPropertyShowSearch = (): void => {
    this._settings = {
      ...this._settings,
      showSearch: true,
    };
  };

  addPropertyShowTranslationKeys = (): void => {
    this._settings = {
      ...this._settings,
      showTranslationKeys: true,
    };
  };

  addPropertyShowRecommendedProducts = (): void => {
    this._settings = {
      ...this._settings,
      showRecommendedProducts: true,
    };
  };

  addPropertyNoMarginals = (): void => {
    this._settings = {
      ...this._settings,
      noMarginals: true,
    };
  };

  disableRecommendedFlyer = (): void => {
    const pages = this._state.pages.filter((page: APagesItem) => page.type !== 'recommended');
    this._settings = {
      ...this._settings,
      hasRecommendedFlyer: false,
    };

    this._state = {
      ...this._state,
      pages: [...pages],
      recommendedFlyer: [],
    };
  };

  activateRecommendedFlyer = (): void => {
    const recommendedPage: APagesItem = {
      id: 'recommended',
      type: 'recommended',
      number: this._state.pages.length + 1,
      width: 0,
      height: 0,
      image: '',
      thumbnail: this._state.recommendedFlyer[0].thumbnailUrl,
      zoom: '',
      links: [],
      keyWords: '',
      recommendedProductIds: [],
    };
    this._state = {
      ...this._state,
      pages: [...this._state.pages, recommendedPage],
    };

    this._settings = {
      ...this._settings,
      hasRecommendedFlyer: true,
    };
  };

  disableNewsletter = (): void => {
    const pages = this._state.pages.filter((page: APagesItem) => page.type !== 'newsletter');

    this._state = {
      ...this._state,
      pages: [...pages],
    };
  };

  activateNewsletter = (): void => {
    this._state = {
      ...this._state,
      pages: [
        ...this._state.pages,
        {
          id: 'newsletter',
          type: 'newsletter',
          number: this._state.pages.length + 1,
          width: 0,
          height: 0,
          image: '',
          thumbnail: svgUrlNewsletter,
          zoom: '',
          links: [],
          keyWords: '',
          recommendedProductIds: [],
        },
      ],
    };
  };

  checkWishlistProductsOnline = async () => {
    if (this._wishListProductIds.length <= 0) {
      return;
    }

    try {
      const response = await getProductsOverview(
        this._state.apiCountryCode,
        this._wishListProductIds as unknown as string[],
        this._routerParams.ar,
      );
      if (response?.Products) {
        this._productIdsAvailable = response.Products.map(
          ({ Product }: { Product: AProductData }) => parseInt(Product.productId, 10),
        );
      }
    } catch (error) {
      return Promise.resolve();
    }
  };

  checkAgeRestrictionExpired = () => {
    const acceptedAgeRestriction = this._ageRestrictionData.accepted[this._state.locale] || [];

    if (this._flyerData.flyer.ageRestriction) {
      const validAcceptedAgeRestriction = acceptedAgeRestriction.filter((ageRestriction) => {
        const now = new Date();
        const expireDate = new Date(ageRestriction.expires);
        return expireDate.getTime() > now.getTime();
      });

      if (
        validAcceptedAgeRestriction.findIndex(
          ({ age }) => age === this._flyerData.flyer.ageRestriction,
        ) <= -1
      ) {
        this._hasAgeRestriction = true;
      }
      this._ageRestrictionData = {
        ...this._ageRestrictionData,
        accepted: {
          ...this._ageRestrictionData.accepted,
          [this._state.locale]: validAcceptedAgeRestriction,
        },
      };
    }
  };

  checkConfigOverrideByRouterparams = () => {
    if (this._routerSearchQuery.debug) {
      this.enableDebug();
    }

    if (this._routerSearchQuery.debugtracking) {
      this.enableDebugTracking();
    }

    if (!this._routerSearchQuery.nowishlist || this._routerSearchQuery.showwishlist) {
      this.enableWishlist();
    } else {
      this.removeAllProducts();
    }

    if (this._routerSearchQuery.noquit) {
      this.addPropertyHideQuitButton();
    }
    if (this._routerSearchQuery.nomarginals) {
      this.addPropertyNoMarginals();
    }
  };

  toggleFeatures = () => {
    if (
      this.checkForUrlParameterBeforeConfig(
        this._flyerData.flyer.enableSharing,
        this._routerSearchQuery.enablesharing,
      )
    ) {
      this.enableSharing();
    }

    if (
      this.checkForUrlParameterBeforeConfig(
        this._flyerData.flyer.enableDownload,
        this._routerSearchQuery.enabledownload,
      )
    ) {
      this.enableDownload();
    }

    if (
      this.checkForUrlParameterBeforeConfig(
        this._flyerData.flyer.myAccount,
        this._routerSearchQuery.myAccount,
      )
    ) {
      this.enableMyAccount();
    }

    if (
      this.checkForUrlParameterBeforeConfig(
        this._flyerData.flyer.shoppingCart,
        this._routerSearchQuery.shoppingCart,
      )
    ) {
      this.enableShoppingCart();
    }

    if (
      this.checkForUrlParameterBeforeConfig(
        this._flyerData.flyer.showSearch,
        this._routerSearchQuery.showsearch,
      )
    ) {
      this.addPropertyShowSearch();
    }

    if (
      this.checkForUrlParameterBeforeConfig(
        this._flyerData.flyer.showTranslationKeys,
        this._routerSearchQuery.showtranslationkeys,
      )
    ) {
      this.addPropertyShowTranslationKeys();
    }

    if (
      this.checkForUrlParameterBeforeConfig(
        this._flyerData.flyer.showRecommendedProducts,
        this._routerSearchQuery.showrecommendedproducts,
      )
    ) {
      this.addPropertyShowRecommendedProducts();
    } else {
      this.removeAllRecommendedProductsFromPage();
    }
  };

  transformFlyerData = () => {
    const {
      flyer: {
        apiCountryCode,
        clientLocale,
        cookieConsent = this._state.cookieConsent,
        countryCode,
        endDate,
        gtmID,
        homeUrl,
        id,
        imprintUrl,
        locale = this._state.locale,
        name,
        newsletterUrl,
        notifications = [],
        pages = this._state.pages,
        pdfUrl,
        privacyUrl,
        products = this._state.products,
        quitUrl,
        relatedFlyers = this._state.recommendedFlyer,
        regionalizationUrl,
        regions,
        startDate,
        showNotificationCenter,
        subcategory,
        title,
        texts = this._state.texts,
        topics = this._state.topics,
        videos = this._state.videos,
      },
    } = this._flyerData;
    this._state = {
      ...this._state,
      apiCountryCode,
      clientLocale,
      cookieConsent,
      countryCode,
      endDate,
      gtmId: gtmID,
      id,
      locale,
      name,
      pages,
      products,
      recommendedFlyer: relatedFlyers,
      regionCodes: regions.map((regionObject: { code: string }) => regionObject.code),
      startDate,
      subcategory,
      topics,
      texts,
      title,
      urls: {
        homeUrl,
        imprintUrl,
        newsletterUrl,
        pdfUrl,
        privacyUrl,
        quitUrl,
        regionalizationUrl,
      },
      videos,
    };

    this._notifications = notifications;

    this.checkAgeRestrictionExpired();

    if (!this._flyerData.flyer.showLinks) {
      this.removeLinksFromPages();
    }

    this.checkConfigOverrideByRouterparams();

    if (this._state.topics.length <= 0) {
      this.removeTopicsFromFlyer();
    }

    if (
      !this.checkForUrlParameterBeforeConfig(
        this._flyerData.flyer.showProductDetails,
        this._routerSearchQuery.showproductdetails,
      )
    ) {
      this.transformAllProductsToDisplayNoDetailsInSidebar();
    }

    this.toggleFeatures();

    if (
      this.checkForUrlParameterBeforeConfig(
        showNotificationCenter,
        this._routerSearchQuery.shownotifications,
      ) &&
      this._notifications.length > 0
    ) {
      this.enableNotificationCenter();
    }

    this._flyerData.flyer.showNewsletter ? this.activateNewsletter() : this.disableNewsletter();
    this._state.recommendedFlyer.length > 0 && this._flyerData.flyer.showRelatedFlyers
      ? this.activateRecommendedFlyer()
      : this.disableRecommendedFlyer();
  };

  checkProducts = async () => {
    await this.checkWishlistProductsOnline();
  };
}

const flyerValidationService = new FlyerValidationService();
export default flyerValidationService;
