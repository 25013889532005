import './style.scss';

import Video from 'components/Video';
import { Component } from 'react';

type VideoOverlayProps = {
  embedFragment: string;
  videoProvider: string;
};
export default class VideoOverlay extends Component<VideoOverlayProps, {}> {
  render() {
    const { videoProvider, embedFragment } = this.props;
    return (
      <div className={`video-overlay video-overlay--${videoProvider}`}>
        <Video videoProvider={videoProvider} embedFragment={embedFragment} />
      </div>
    );
  }
}
