import { emptyArray } from 'helper/js/helper';
import { createSelector } from 'reselect';

const flyerWishListSelector = (state: AppState) => state.wishlistProducts || emptyArray;

export const flyerWishListCount = createSelector(flyerWishListSelector, (wishlistItems) =>
  wishlistItems
    .map((product) => Object.values(product)[0].length)
    .reduce((flyerCount: number, count: number) => flyerCount + count, 0),
);
