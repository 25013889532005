import './style.scss';

import ButtonC, { BUTTON_APPEARANCE } from 'components/ButtonC';
import IconC, { ICON_SIZE } from 'components/IconC';
import Link, { LINK_TARGET } from 'components/Link';
import { Language } from 'providers/Language';
import { ChangeEvent, Component } from 'react';

type AgeRestrictionProps = {
  age: number;
  privacyUrl: string;
  homeUrl: string;
  onAccepted: (cookiesAccepted: boolean) => void;
};
type AgeRestrictionState = {
  cookiesAccepted: boolean;
};

class AgeRestriction extends Component<AgeRestrictionProps, AgeRestrictionState> {
  readonly state = {
    cookiesAccepted: false,
  };

  handleCookiesAccepted = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ cookiesAccepted: event.target.checked });
  };

  handleAccepted = () => {
    this.props.onAccepted(this.state.cookiesAccepted);
  };

  render() {
    return (
      <Language>
        {(t) => (
          <div className="agerestriction">
            <div className="agerestriction__wrapper">
              <div className="agerestriction__age">
                <IconC size={ICON_SIZE.XL} icon={this.props.age.toString()} />
              </div>
              <h2 className="agerestriction__headline">
                {t('ageRestrictionHeadline').replace('%s', this.props.age.toString())}
              </h2>
              <p className="agerestriction__content">
                {' '}
                {t('ageRestrictionContent').replace('%s', this.props.age.toString())}
              </p>
              <div className="agerestriction__cookie">
                <label className="agerestriction__cookie-label">
                  <input
                    type="checkbox"
                    checked={this.state.cookiesAccepted}
                    onChange={this.handleCookiesAccepted}
                    data-testid="checkbox"
                  />
                  {t('ageRestrictionCheckboxLabel')}
                </label>
              </div>
              <div className="agerestriction__button">
                <div className="agerestriction__button-item">
                  <ButtonC
                    type={BUTTON_APPEARANCE.SECONDARY}
                    label={t('ageRestrictionDeclined')}
                    href={this.props.homeUrl}
                    target={LINK_TARGET.SELF}
                  />
                </div>
                <div className="agerestriction__button-item">
                  <ButtonC
                    type={BUTTON_APPEARANCE.PRIMARY}
                    label={t('ageRestrictionAccepted')}
                    onClick={this.handleAccepted}
                  />
                </div>
              </div>
              <div className="agerestriction__privacy">
                <p className="agerestriction__privacy-text">
                  <span>{t('ageRestrictionPrivacyLabel')}</span>
                  <Link className="agerestriction__privacy-link" href={this.props.privacyUrl}>
                    {t('ageRestrictionPrivacyLinkLabel')}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        )}
      </Language>
    );
  }
}

export default AgeRestriction;
