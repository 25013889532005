import initialState from 'initialState';
import { Reducer } from 'redux';

import { SettingsActions } from './actions';
import { SETTINGS_UPDATE } from './constants';

const router: Reducer<ASettings, SettingsActions> = (
  state = initialState.settings,
  action: SettingsActions,
) => {
  switch (action.type) {
    case SETTINGS_UPDATE:
      return {
        ...state,
        ...action.settings,
      };
    default:
      return state;
  }
};
export default router;
