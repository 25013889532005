import { all } from 'redux-saga/effects';

import { updateBrowserConfig } from './containers/BrowserContainer/saga';
import { loadFlyer } from './containers/ContentContainer/saga';
import { updatePages } from './containers/SheetContainer/saga';
import { handleWishListChange } from './containers/WishListContainer/saga';

export default function* rootSaga() {
  yield all([loadFlyer(), updatePages(), updateBrowserConfig(), handleWishListChange()]);
}
