const quitUrl = import.meta.env.VITE_QUIT_URL || '';
const initialState: AppState = {
  browser: {
    isWideScreen: false,
    is850Max: false,
    isSmMin: false,
    isMdMin: false,
    isTouchDevice: true,
    isReadyForRender: false,
  },
  settings: {
    enableSharing: false,
    enableDownload: false,
    hideQuitButton: false,
    showWishList: false,
    noMarginals: false,
    hasTopics: true,
    hasRecommendedFlyer: false,
    showSearch: false,
    showRecommendedProducts: false,
    showProductsInSidebar: true,
    debugTracking: false,
    debug: false,
    showNotifications: false,
    showTranslationKeys: false,
    shoppingCart: false,
    myAccount: false,
  },
  notifications: {
    data: [],
    seen: [],
  },
  appData: {
    apiCountryCode: '',
    clientLocale: '',
    cookieConsent: {
      type: undefined,
      domainId: '',
      gtmAttributes: '',
    },
    countryCode: '',
    endDate: '',
    error: false,
    gtmId: '',
    id: '',
    loading: true,
    locale: '',
    name: '',
    pages: [],
    products: {},
    recommendedFlyer: [],
    regionCodes: [],
    sceenType: 'mobile',
    subcategory: '',
    startDate: '',
    texts: {},
    title: 'Leaflets',
    topics: [],
    urls: {
      homeUrl: '',
      imprintUrl: '',
      newsletterUrl: '',
      pdfUrl: '',
      privacyUrl: '',
      quitUrl,
      regionalizationUrl: '',
    },
    videos: {},
  },
  routing: {
    params: {
      page: '1',
      uuid: '',
      locale: '',
      view: 'flyer',
    },
    searchQuery: {
      enabledownload: undefined,
      enablesharing: undefined,
      shownotifications: false,
      nowishlist: false,
      showwishlist: undefined,
      showproductdetails: undefined,
      showsearch: undefined,
      noquit: false,
      nomarginals: false,
      showrecommendedproducts: undefined,
      debugtracking: false,
      debug: false,
      showtranslationkeys: false,
      shoppingCart: undefined,
      myAccount: undefined,
    },
    path: '',
  },
  sheet: {
    currentVisible: 0,
    flipPages: 0,
    zoom: 1,
    navigate: false,
    animationType: '',
    flipToPage: undefined,
  },
  wishlistProducts: [],
  search: {
    term: '',
  },
  productData: {
    expires: undefined,
  },
  ageRestriction: {
    show: false,
    age: undefined,
    accepted: {},
  },
  recommendationProducts: {
    count: 0,
    products: [],
  },
  _persist: {
    version: 0,
    rehydrated: false,
  },
};

export default initialState;
