import './style.scss';

import Link from 'components/Link';
import { memo, PropsWithChildren, ReactNode, useMemo } from 'react';

type Props = {
  content?: ReactNode;
  footer?: ReactNode;
  img: string;
  imgAlt: string;
  link?: string;
  imageFormat: string;
  onClick?: () => void;
};
const Tile = ({
  img,
  imgAlt,
  content,
  footer,
  link,
  imageFormat,
  onClick,
}: PropsWithChildren<Props>) => {
  const memorizedContent = useMemo(
    () => (
      <>
        <div
          className="tile__image"
          style={{ backgroundImage: `url(${img})`, paddingBottom: imageFormat }}
        />
        {(content || footer) && (
          <div className="tile__content">
            {content && <div className="tile__content-wrapper">{content}</div>}
            {footer && <div className="tile__footer">{footer}</div>}
          </div>
        )}
      </>
    ),
    [footer, content, img, imageFormat],
  );

  if (link) {
    return (
      <Link href={link} className="tile" ariaLabel={imgAlt}>
        {memorizedContent}
      </Link>
    );
  }
  return (
    <div
      className="recommendedproducts__item-link"
      onClick={onClick}
      role="link"
      tabIndex={0}
      onKeyDown={onClick}>
      {memorizedContent}
    </div>
  );
};

export default memo(Tile);
