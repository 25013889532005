import { injectCookieBotScript, injectOneTrustScript } from 'helper/js/cookieConsent';
import { useSelector } from 'react-redux';

export default function CookieConsentContainer() {
  const { type, domainId } = useSelector((state: AppState) => state.appData.cookieConsent) ?? {};
  const locale = useSelector((state: AppState) => state.appData.locale);

  if (type && domainId) {
    if (type === 'cookieBot') {
      injectCookieBotScript(domainId);
    } else if (type === 'oneTrust') {
      injectOneTrustScript(domainId, locale);
    }
  }
  return null;
}
