import initialState from 'initialState';
import { Reducer } from 'redux';

import { RecommendationActions } from './actions';
import { UPDATE_RECOMMENDATION_PRODUCTS } from './constants';

const recommendationProducts: Reducer<ARecommendationProducts, RecommendationActions> = (
  state: ARecommendationProducts = initialState.recommendationProducts,
  action: RecommendationActions,
) => {
  switch (action.type) {
    case UPDATE_RECOMMENDATION_PRODUCTS:
      return {
        ...state,
        count: action.count,
        products: action.products,
      };
    default:
      return state;
  }
};
export default recommendationProducts;
