import Sheet from 'components/Sheet';
import { OVERLAY_OFFSET, SIDEBAR, VIEW } from 'helper/js/constants';
import { useFlippyNavigate, useFlippyPageNumber, useFlippyView } from 'helper/js/hooks/router';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTargetPageNumber, getVisiblePagesAmount } from '../../helper/js/helper';
import useEventListener from '../../helper/js/hooks/use-event-listener';
import { TrackingContext } from '../../providers/Tracking';
import TRACKING_EVENTS from '../../providers/Tracking/constants';
import TRACKING_EVENT_COLLECTIONS from '../../providers/Tracking/trackingEventCollections';
import { updateVisiblePagesAction, updateZoomLevel } from './actions';

const getOffset = (view: VIEW, isSmMin: boolean, isMdMin: boolean, noMarginals: boolean) => {
  const SHEET_OFFSET_VERTICAL = -16;
  const SHEET_OFFSET_HORIZONTAL = -16;
  const offsetVerticalForViewPort = isMdMin ? 67 : 130;
  const offsetVerticalForBars = isMdMin && view === VIEW.JUMPMARKS ? OVERLAY_OFFSET[view] : 0;
  const viewHasOffset = ![
    VIEW.RECOMMENDEDPRODUCTS,
    VIEW.FLYER,
    VIEW.JUMPMARKS,
    VIEW.TEXT,
    VIEW.VIDEO,
  ].includes(view);
  const offsetHorizontalForBars = isMdMin && viewHasOffset ? SIDEBAR.SLIDEOUT_WIDTH : 0;

  return {
    offsetVertical:
      SHEET_OFFSET_VERTICAL -
      offsetVerticalForBars -
      (!noMarginals ? offsetVerticalForViewPort : 0),
    offsetHorizontal: SHEET_OFFSET_HORIZONTAL - offsetHorizontalForBars,
  };
};

function SheetContainer() {
  const { trackEventUniversalAnalytics, trackEventDataLayerService } = useContext(TrackingContext);
  const dispatch = useDispatch();
  const flippyNavigate = useFlippyNavigate();
  const {
    navigate,
    flipToPage: flipToPageNumber,
    animationType = '',
    flipPages = 1,
    zoom = 1,
  } = useSelector((state: AppState) => state.sheet);
  const {
    id,
    name,
    recommendedFlyer,
    urls: { newsletterUrl },
    pages = [],
    products,
  } = useSelector((state: AppState) => state.appData);
  const { noMarginals = false } = useSelector((state: AppState) => state.settings);
  const flippyView = useFlippyView();
  const flippyPageNumber = useFlippyPageNumber();
  const {
    isSmMin,
    isMdMin,
    isWideScreen = false,
    isTouchDevice,
  } = useSelector((state: AppState) => state.browser);
  const currentVisible = getVisiblePagesAmount(flippyPageNumber, pages.length, isWideScreen);
  const enableMediaLinks = !(flippyView === VIEW.OVERVIEW && !isSmMin);
  const { offsetVertical, offsetHorizontal } = getOffset(flippyView, isSmMin, isMdMin, noMarginals);
  const [previousVisible, setPreviousVisible] = useState(currentVisible);
  const isPreviousWideScreen = useRef(isWideScreen);

  useEffect(() => {
    dispatch(updateVisiblePagesAction());
  }, [dispatch]);

  useEffect(() => {
    trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.FLYER_PAGE_OPENED,
      eventLabel: name,
    });
    trackEventDataLayerService(
      TRACKING_EVENT_COLLECTIONS.FLYER_PAGE_OPENED,
      {
        contentLabel: name,
        contentID: id,
      },
      { eventInteractionType: 'open' },
      undefined,
      undefined,
      {
        pageProperties: { maxCount: pages.length, viewportPages: currentVisible },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const visibleProducts = pages
      .slice(flippyPageNumber - 1, flippyPageNumber - 1 + currentVisible)
      .map((visiblePage) => visiblePage.links)
      .flat()
      .filter((link) => link?.displayType === 'product' || link?.displayType === 'productnodetails')
      .map((visibleProductLink) => products[visibleProductLink.id]);

    if (visibleProducts.length) {
      trackEventDataLayerService(
        TRACKING_EVENT_COLLECTIONS.FLYER_PAGE_OPENED,
        {
          contentLabel: name,
          contentID: id,
        },
        { eventInteractionType: 'browse' },
        visibleProducts,
      );
    }

    if (isPreviousWideScreen.current !== isWideScreen && previousVisible !== currentVisible) {
      trackEventDataLayerService(
        TRACKING_EVENT_COLLECTIONS.FLYER_VIEWPORT_PAGE_COUNT_CHANGE,
        {
          contentLabel: name,
          contentID: id,
        },
        { eventInteractionType: 'view' },
        undefined,
        undefined,
        {
          pageProperties: { maxCount: pages.length, viewportPages: currentVisible },
        },
      );
      setPreviousVisible(currentVisible);
      isPreviousWideScreen.current = isWideScreen;
    }
  }, [
    currentVisible,
    flippyPageNumber,
    id,
    isWideScreen,
    name,
    pages,
    previousVisible,
    products,
    trackEventDataLayerService,
  ]);

  const handleSwipeGestures = (direction: string) => {
    flipSheetInDirection(direction as 'forward' | 'backward', 'swipe');
  };

  const handleKeyBoardEvents = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
      case 'Left':
        flipSheetInDirection('backward', 'keyboard');
        break;
      case 'ArrowRight':
      case 'Right':
        flipSheetInDirection('forward', 'keyboard');
        break;
    }
  };

  const handleZoomUpdate = (newZoom: number) => {
    dispatch(updateZoomLevel(newZoom));
  };

  const flipSheetInDirection = (direction: 'forward' | 'backward', type: 'swipe' | 'keyboard') => {
    const targetPage = getTargetPageNumber(
      flippyPageNumber,
      direction,
      currentVisible,
      isWideScreen,
    );

    if (flippyPageNumber === targetPage || targetPage < 1 || targetPage > pages.length) {
      return;
    }

    flippyNavigate({ view: isMdMin ? flippyView : VIEW.FLYER, page: `${targetPage}` });

    trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.NAVIGATION_BUTTON_CLICKED,
      eventAction: `${direction}_button_clicked`,
      eventLabel: type,
    });
  };

  useEventListener('keydown', handleKeyBoardEvents);

  if (currentVisible <= 0) {
    return null;
  }

  return (
    <Sheet
      pages={pages as APagesItem[]}
      navigate={navigate}
      animationType={animationType}
      currentPage={flippyPageNumber}
      flipPages={flipPages}
      currentVisible={currentVisible}
      isWideScreen={isWideScreen}
      zoom={zoom}
      newsletterUrl={newsletterUrl}
      flipToPageNumber={flipToPageNumber}
      recommendedFlyer={recommendedFlyer[0]}
      offsetHorizontal={offsetHorizontal}
      offsetVertical={offsetVertical}
      onZoomUpdate={handleZoomUpdate}
      handleSwipeGestures={handleSwipeGestures}
      enableMediaLinks={enableMediaLinks}
      isTouchDevice={isTouchDevice}
    />
  );
}

export default memo(SheetContainer);
