import './style.scss';

import classNames from 'classnames';
import IconC, { ICON_SIZE } from 'components/IconC';
import { useEffect, useState } from 'react';

interface IProps {
  content?: number | boolean | string;
  color?: APPEARANCE_TYPE;
}

function Indicator({ content = 0, color }: Readonly<IProps>) {
  const [changed, setChanged] = useState(false);
  const [indicator, setIndicator] = useState(content);
  const isIconIndicator = typeof content === 'string';

  const handleAnimationEnd = (animation: React.AnimationEvent<HTMLSpanElement>) => {
    if (animation.animationName === 'indicator__bounce' && animation.type === 'animationend') {
      setChanged(false);
    }
  };

  useEffect(() => {
    if (indicator !== content) {
      setChanged(true);
      setIndicator(content);
    }
  }, [indicator, content]);

  const indicatorClasses = classNames({
    indicator: true,
    [`indicator--${color}`]: true,
    'indicator--small': typeof content === 'boolean',
    'indicator--changed': changed,
  });

  if (!content) {
    return null;
  }

  return (
    <span onAnimationEnd={handleAnimationEnd} className={indicatorClasses}>
      {isIconIndicator ? <IconC size={ICON_SIZE.XS} icon={content} /> : content}
    </span>
  );
}

export default Indicator;
