import Progressbar from 'components/Progressbar';
import { useFlippyPageNumber } from 'helper/js/hooks/router';
import { useSelector } from 'react-redux';

import { getVisiblePagesAmount } from '../../helper/js/helper';

export default function ProgressbarContainer() {
  const flippyPageNumber = useFlippyPageNumber();
  const totalPages = useSelector((state: AppState) => state.appData.pages.length);
  const isWideScreen = useSelector((state: AppState) => state.browser.isWideScreen);
  const currentVisible = getVisiblePagesAmount(flippyPageNumber, totalPages, isWideScreen);

  return <Progressbar stepCount={totalPages} currentStep={flippyPageNumber + currentVisible - 1} />;
}
