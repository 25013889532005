import { TENANT, VIEW } from 'helper/js/constants';
import { LanguageContext } from 'providers/Language';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import { memo, useContext, useEffect } from 'react';

import { useFlippyNavigate, useFlippyView } from '../../helper/js/hooks/router';
import { TrackingContext } from '../../providers/Tracking';
import ErrorKaufland from './ErrorKaufland';
import ErrorLidl from './ErrorLidl';

export const ERROR_CODES = Object.freeze({
  DEFAULT: 'errorCodeDefault',
  PRODUCT: 'errorCodeProduct',
  IMAGE: 'errorCodeImage404',
});

type Props = {
  quitUrl?: string;
  errorCode: string;
  onlyImage?: boolean;
  isErrorInSidebar?: boolean;
};

function ErrorComponent({
  quitUrl,
  errorCode,
  onlyImage = false,
  isErrorInSidebar = false,
}: Readonly<Props>) {
  const { t } = useContext(LanguageContext);
  const { trackEventUniversalAnalytics } = useContext(TrackingContext);
  const flippyView = useFlippyView();
  const flippyNavigate = useFlippyNavigate();
  const errorMessage = t(errorCode);

  useEffect(() => {
    if (flippyView !== VIEW.FLYER && !isErrorInSidebar) {
      flippyNavigate({ view: VIEW.FLYER, contentId: '' });
    }
    trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.ERROR_PAGE_SEEN,
      eventLabel: errorMessage,
    });
  }, [trackEventUniversalAnalytics, errorMessage, flippyView, flippyNavigate, isErrorInSidebar]);

  const handleButtonClick = (): void => {
    trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.ERROR_PAGE_BUTTON_CLICKED,
      eventLabel: quitUrl,
    });
  };

  const errorPageProps = {
    title: t('error'),
    subTitle: t(errorCode),
    quitUrl: quitUrl,
    quitUrlTitle: t('quitLinkTitle'),
    handleButtonClick: handleButtonClick,
  };

  const errorLidlPageProps = {
    ...errorPageProps,
    onlyImage,
    quitUrlText: t('quitLinkText'),
  };

  return (
    <>
      {import.meta.env.VITE_THEME === TENANT.LIDL ? (
        <ErrorLidl {...errorLidlPageProps} />
      ) : (
        <ErrorKaufland {...errorPageProps} />
      )}
    </>
  );
}

export default memo(ErrorComponent);
