import { LINK_TARGET } from 'components/Link';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import { MINDSHIFT_LINKS, VIEW } from 'helper/js/constants';
import { isUserAuthenticated } from 'helper/js/helper';
import { useFlippyNavigate } from 'helper/js/hooks/router';
import { useLanguage } from 'providers/Language';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import TRACKING_EVENT_COLLECTIONS from 'providers/Tracking/trackingEventCollections';
import { useContext, useEffect } from 'react';

export default function Account() {
  const { t } = useLanguage();
  const { trackEventUniversalAnalytics, trackEventDataLayerService } = useContext(TrackingContext);
  const flippyNavigate = useFlippyNavigate();

  useEffect(() => {
    if (!isUserAuthenticated()) {
      flippyNavigate({ view: VIEW.FLYER, contentId: undefined });
    }
  }, [flippyNavigate]);

  const handleMyAccountClick = () => {
    trackEventUniversalAnalytics(TRACKING_EVENTS.ACCOUNT_BUTTON_CLICKED);
    trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.ACCOUNT_BUTTON_CLICKED, {
      contentType: 'sidebar_menu',
    });
  };

  const handleLogout = () => {
    trackEventUniversalAnalytics(TRACKING_EVENTS.LOGOUT_BUTTON_CLICKED);
  };

  return (
    <Menu>
      <MenuItem
        text={t('myAccount')}
        href={MINDSHIFT_LINKS.ACCOUNT}
        target={LINK_TARGET.ACCOUNT}
        onClick={handleMyAccountClick}
      />
      <MenuItem
        text={t('logout')}
        href={MINDSHIFT_LINKS.LOGOUT + encodeURI(window.location.href)}
        target={LINK_TARGET.SELF}
        onClick={handleLogout}
      />
    </Menu>
  );
}
