import Notification from 'components/Notification';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notificationSeen } from './actions';

const NotificationContainer = memo(() => {
  const notifications = useSelector((state: AppState) => state.notifications.data);
  const hasSeenNotifcation = useSelector(
    (state: AppState) =>
      state.notifications.data.filter(
        (notification) => state.notifications.seen.indexOf(notification.id) <= -1,
      ),
    () => true,
  );

  const isMdmin = useSelector((state: AppState) => state.browser.isMdMin);

  const dispatch = useDispatch();
  const handleSeenNotifications = useCallback(
    (ids: string[]) => {
      dispatch(notificationSeen(ids));
    },
    [dispatch],
  );

  return (
    <Notification
      notifications={notifications}
      showNotification={isMdmin && hasSeenNotifcation.length > 0}
      hasNewNotification={hasSeenNotifcation.length > 0}
      updateSeenNotifications={handleSeenNotifications}
    />
  );
});

export default NotificationContainer;
