import { createContext } from 'react';

export type SidebarContextProps = {
  globalData: any;
  currentPage: number;
  handleGlobalData: (data: any) => void;
  handlePageChange: (page: number) => void;
};

export const SidebarContext = createContext<SidebarContextProps>({
  globalData: {},
  currentPage: 1,
  handleGlobalData: () => {},
  handlePageChange: () => {},
});
