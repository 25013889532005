import './style.scss';

import { PureComponent } from 'react';

type ProgressbarProps = {
  currentStep: number;
  stepCount: number;
};
type ProgressbarState = {
  styleObj: { marginRight: string };
};
class Progressbar extends PureComponent<ProgressbarProps, ProgressbarState> {
  static defaultProps = {
    currentStep: 0,
    stepCount: 100,
  };

  state = {
    styleObj: { marginRight: '100%' },
  };

  static getDerivedStateFromProps(props: ProgressbarProps) {
    const { currentStep, stepCount } = props;
    const percentageOfOneStep = 100 / stepCount;
    let invertedProgressPercentage;
    if (currentStep <= 0) {
      invertedProgressPercentage = 100;
    } else if (currentStep >= stepCount) {
      invertedProgressPercentage = 0;
    } else {
      invertedProgressPercentage = 100 - percentageOfOneStep * currentStep;
    }

    return {
      styleObj: { marginRight: `${invertedProgressPercentage}%` },
    };
  }

  render() {
    return <div className="progressbar" style={this.state.styleObj} />;
  }
}

export default Progressbar;
