import './style.scss';

import Recipe from 'components/Recipes/Recipe';
import { memo, PropsWithChildren } from 'react';

type Props = {
  recipes?: (Recipe | any)[];
};

const Recipes = ({ recipes = new Array(10).fill(null) }: PropsWithChildren<Props>) => (
  <ul className="recipes">
    {recipes.length > 0 && recipes.map((recipe, i) => <Recipe key={i} recipe={recipe} index={i} />)}
  </ul>
);

export default memo(Recipes);
