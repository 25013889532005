/* eslint react/jsx-no-useless-fragment: "warn" */
import './style.scss';

import Link, { LINK_TARGET } from 'components/Link';
import { LanguageContext } from 'providers/Language';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import { memo, useContext } from 'react';

import brandKauflandUrl from './brand_kaufland.svg';
import brandLidlUrl from './brand_lidl.svg';

type Props = {
  href: string;
  hasError: boolean;
};

const Brand = ({ href, hasError }: Props) => {
  const brandImageUrl = import.meta.env.VITE_THEME === 'lidl' ? brandLidlUrl : brandKauflandUrl;
  const { trackEventUniversalAnalytics } = useContext(TrackingContext);
  const { t } = useContext(LanguageContext);

  const handleLogoClicked = () => {
    if (!hasError) {
      trackEventUniversalAnalytics(TRACKING_EVENTS.BRAND_BUTTON_CLICKED);
    } else {
      trackEventUniversalAnalytics({
        ...TRACKING_EVENTS.BRAND_BUTTON_CLICKED,
        eventCategory: 'flyer_error_page',
      });
    }
  };

  return (
    <Link
      onClick={handleLogoClicked}
      title={t('backToHome')}
      target={LINK_TARGET.PARENT}
      hasFlyxParam={import.meta.env.VITE_THEME !== 'kaufland'}
      href={href}>
      <div className="brand">
        <img alt={t('brandTitle')} src={brandImageUrl} />
      </div>
    </Link>
  );
};

export default memo(Brand);
