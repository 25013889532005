import initialState from 'initialState';
import { Reducer } from 'redux';

import { SearchActions } from './actions';
import { SEARCH_TERM_CHANGED } from './constants';

const search: Reducer<ASearch, SearchActions> = (
  state: ASearch = initialState.search,
  action: SearchActions,
) => {
  switch (action.type) {
    case SEARCH_TERM_CHANGED:
      return {
        ...state,
        term: action.term,
      };
    default:
      return state;
  }
};

export default search;
