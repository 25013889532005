import { TrackingContextProps, withTracking } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import { Component } from 'react';

class GlobalTrackingContainer extends Component<TrackingContextProps, {}> {
  hasOrientationChanged = false;

  orientation: string;

  constructor(props: TrackingContextProps) {
    super(props);
    this.orientation = this.getSimpleOrientationString();
  }

  shouldComponentUpdate() {
    return false;
  }

  getOrientation = () => {
    let orientation = 'unknown';
    if (window.matchMedia('(orientation: portrait)').matches) {
      orientation = 'portrait';
    } else if (window.matchMedia('(orientation: landscape)').matches) {
      orientation = 'landscape';
    }
    return orientation;
  };

  getSimpleOrientationString = (): string => {
    const orientation = window.screen?.orientation?.type || '';

    if (orientation.startsWith('landscape')) {
      return 'landscape';
    }
    if (orientation.startsWith('portrait')) {
      return 'portrait';
    }

    return this.getOrientation();
  };

  handleOrientationChange = () => {
    this.hasOrientationChanged = true;
  };

  handleResize = () => {
    if (this.hasOrientationChanged) {
      const newOrientation = this.getSimpleOrientationString();
      if (this.orientation !== newOrientation) {
        this.orientation = newOrientation;
        this.props.trackEventUniversalAnalytics({
          ...TRACKING_EVENTS.ORIENTATION_CHANGED,
          eventLabel: this.orientation,
        });
      }
      this.hasOrientationChanged = false;
    }
  };

  componentDidMount() {
    this.props.trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.ORIENTATION_INITIALE,
      eventLabel: this.orientation,
    });
    window.addEventListener('orientationchange', this.handleOrientationChange);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.handleOrientationChange);
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return null;
  }
}
export default withTracking(GlobalTrackingContainer);
