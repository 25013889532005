import ButtonC, { BUTTON_HOVERSTYLE } from 'components/ButtonC';
import { LINK_TARGET } from 'components/Link';
import { MINDSHIFT_LINKS } from 'helper/js/constants';
import { getCookie } from 'helper/js/helper';
import useEventListener from 'helper/js/hooks/use-event-listener';
import { useLanguage } from 'providers/Language';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import TRACKING_EVENT_COLLECTIONS from 'providers/Tracking/trackingEventCollections';
import { useCallback, useContext, useEffect, useState } from 'react';

export default function Cart() {
  const { t } = useLanguage();
  const { trackEventUniversalAnalytics, trackEventDataLayerService } = useContext(TrackingContext);
  const [cartCount, setCartCount] = useState(0);

  const handleClick = () => {
    trackEventUniversalAnalytics(TRACKING_EVENTS.SHOPPING_CART_BUTTON_CLICKED);
    trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.SHOPPING_CART_BUTTON_CLICKED);
  };

  const updateCartCount = useCallback(() => {
    setCartCount(parseInt(getCookie('CART_QUANTITY'), 10));
  }, [setCartCount]);

  useEffect(() => {
    updateCartCount();
  }, [updateCartCount]);

  useEventListener('UPDATE_CART_COUNT', updateCartCount);

  return (
    <ButtonC
      href={MINDSHIFT_LINKS.CART}
      target={LINK_TARGET.SHOP}
      hoverStyle={BUTTON_HOVERSTYLE.ICON}
      inColumn
      icon={`shopping-cart--${import.meta.env.VITE_THEME}`}
      label={t('shoppingCard')}
      onClick={handleClick}
      indicator={cartCount}
      transformLabelToUppercase={false}
      className="button--hide-label--sm"
    />
  );
}
