export const getEventInteractionType = (type: string, isInWishList?: boolean): string => {
  if (type !== 'product-wishlist') {
    return 'open';
  }

  return !isInWishList ? 'add' : 'remove';
};

export const getContentId = (type: string, isInWishList?: boolean): string => {
  if (type !== 'product-wishlist') {
    return 'media_link';
  }

  return !isInWishList ? 'addToWishlist' : 'removeFromWishlist';
};

export const getContentLabel = (type: string, isAnimatedGif?: boolean): string => {
  let label = type;
  switch (type) {
    case 'product-wishlist':
      label = 'product';
      break;
    case 'product-sidebar':
      label = 'product_link';
      break;
  }
  return isAnimatedGif ? `${label}_gif` : label;
};
