import Img from 'components/Img';
import { LanguageContext } from 'providers/Language';
import { SheetContext } from 'providers/Sheet';
import { memo, useContext, useEffect, useRef, useState } from 'react';

type Props = {
  image: string;
  zoom: string;
  visible?: boolean;
  number: number;
};
const PageDefaultImage = ({ image, zoom, visible = false, number }: Props) => {
  const zoomImageLoaded = useRef(false);
  const [imageState, setImageState] = useState(image);
  const { cZoom } = useContext(SheetContext);
  const { t } = useContext(LanguageContext);

  useEffect(() => {
    if (visible && cZoom > 1 && !zoomImageLoaded.current) {
      setImageState(zoom);
    }
  }, [cZoom, visible, zoom]);

  return (
    <Img
      src={imageState}
      alt={`${t('page')} ${number}`}
      showLoading={cZoom === 1}
      disableLazyLoading={true}
    />
  );
};

export default memo(PageDefaultImage);
