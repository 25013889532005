import classNames from 'classnames';
import ButtonC, { BUTTON_APPEARANCE } from 'components/ButtonC';
import { LINK_TARGET } from 'components/Link';

import errorSvgUrl from './error.svg';
import styles from './ErrorLidl.module.scss';

interface ErrorLidlProps {
  onlyImage?: boolean;
  title: string;
  subTitle: string;
  quitUrl?: string;
  quitUrlTitle: string;
  quitUrlText: string;
  handleButtonClick: () => void;
}
export default function ErrorLidl({
  onlyImage,
  title,
  subTitle,
  quitUrl,
  quitUrlTitle,
  quitUrlText,
  handleButtonClick,
}: Readonly<ErrorLidlProps>): JSX.Element {
  const errorClasses = classNames({
    [styles.error]: true,
    [styles.errorOnlyImage]: onlyImage,
  });

  const errorImage = (
    <div className={styles.image}>
      <img alt="" src={errorSvgUrl} />
    </div>
  );

  if (onlyImage) {
    return <div className={errorClasses}>{errorImage}</div>;
  }

  return (
    <div className={errorClasses}>
      <div className={styles.main}>
        <div className={styles.message}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subTitle }}></p>
        </div>
        <div className={styles.image}>
          <img alt="" src={errorSvgUrl} />
        </div>
      </div>
      {quitUrl && (
        <div className={styles.help}>
          <p className={styles.helpText}>{quitUrlText}</p>
          <div className={styles.helpLink}>
            <ButtonC
              label={quitUrlTitle}
              href={quitUrl}
              icon={`flyer--lidl`}
              type={BUTTON_APPEARANCE.PRIMARY}
              onClick={handleButtonClick}
              target={LINK_TARGET.BLANK}
            />
          </div>
        </div>
      )}
    </div>
  );
}
