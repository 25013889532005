/* eslint camelcase: "off" */
import './style.scss';

import classNames from 'classnames';
import { MouseEvent } from 'react';

import { ReactComponent as FourteenIcon } from './icons/14.svg';
import { ReactComponent as FiveteenIcon } from './icons/15.svg';
import { ReactComponent as SixteenIcon } from './icons/16.svg';
import { ReactComponent as SeventeenIcon } from './icons/17.svg';
import { ReactComponent as EightteenIcon } from './icons/18.svg';
import { ReactComponent as NineteenIcon } from './icons/19.svg';
import { ReactComponent as TwentyIcon } from './icons/20.svg';
import { ReactComponent as TwentyOneIcon } from './icons/21.svg';
import { ReactComponent as Kaufland_ArrowDownIcon } from './icons/arrow-down--kaufland.svg';
import { ReactComponent as Kaufland_ArrowLeftIcon } from './icons/arrow-left--kaufland.svg';
import { ReactComponent as ArrowLeftIcon } from './icons/arrow-left--lidl.svg';
import { ReactComponent as Kaufland_ArrowRightIcon } from './icons/arrow-right--kaufland.svg';
import { ReactComponent as ArrowRightIcon } from './icons/arrow-right--lidl.svg';
import { ReactComponent as ArrowUpIcon } from './icons/arrow-up.svg';
import { ReactComponent as Kaufland_ArrowUpIcon } from './icons/arrow-up--kaufland.svg';
import { ReactComponent as AwardIcon } from './icons/award.svg';
import { ReactComponent as BarsHorizontalIcon } from './icons/bars-horizontal.svg';
import { ReactComponent as Kaufland_BellIcon } from './icons/bell--kaufland.svg';
import { ReactComponent as BellIcon } from './icons/bell--lidl.svg';
import { ReactComponent as BookmarkIcon } from './icons/bookmark.svg';
import { ReactComponent as Kaufland_BookmarkIcon } from './icons/bookmark--kaufland.svg';
import { ReactComponent as BoxOpenIcon } from './icons/box-open.svg';
import { ReactComponent as ClockIcon } from './icons/clock.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as Kaufland_CrossIcon } from './icons/cross--kaufland.svg';
import { ReactComponent as CrossIcon } from './icons/cross--lidl.svg';
import { ReactComponent as KauflandDataProtection } from './icons/data-protection--kaufland.svg';
import { ReactComponent as DesktopIcon } from './icons/desktop.svg';
import { ReactComponent as Kaufland_DownloadIcon } from './icons/download--kaufland.svg';
import { ReactComponent as DownloadIcon } from './icons/download--lidl.svg';
import { ReactComponent as EditorTextAlignCenter } from './icons/editor-text-align-center.svg';
import { ReactComponent as EditorTextAlignLeft } from './icons/editor-text-align-left.svg';
import { ReactComponent as EditorTextAlignRight } from './icons/editor-text-align-right.svg';
import { ReactComponent as Kaufland_EnvelopeCloseIcon } from './icons/envelope-close--kaufland.svg';
import {
  ReactComponent as EnvelopeCloseIcon,
  ReactComponent as EnvelopeCloseIcon_Editor,
} from './icons/envelope-close--lidl.svg';
import { ReactComponent as EnvelopeOpenIcon } from './icons/envelope-open.svg';
import { ReactComponent as ExclamationTriangleIcon } from './icons/exclamation-triangle.svg';
import { ReactComponent as Kaufland_ExclamationTriangleIcon } from './icons/exclamation-triangle--kaufland.svg';
import { ReactComponent as Kaufland_FacebookIcon } from './icons/facebook--kaufland.svg';
import { ReactComponent as FacebookIcon } from './icons/facebook--lidl.svg';
import { ReactComponent as FlyerIcon } from './icons/flyer.svg';
import { ReactComponent as Kaufland_FlyerIcon } from './icons/flyer--kaufland.svg';
import { ReactComponent as GiftIcon } from './icons/gift.svg';
import { ReactComponent as Kaufland_HeartIcon } from './icons/heart--kaufland.svg';
import { ReactComponent as HeartIcon } from './icons/heart--lidl.svg';
import { ReactComponent as Kaufland_HeartFilledIcon } from './icons/heart-filled--kaufland.svg';
import { ReactComponent as HeartFilledIcon } from './icons/heart-filled--lidl.svg';
import { ReactComponent as HookIcon } from './icons/hook.svg';
import { ReactComponent as HookCircleIcon } from './icons/hook-circle.svg';
import { ReactComponent as KauflandImprint } from './icons/imprint--kaufland.svg';
import { ReactComponent as InformationCircleIcon } from './icons/information-circle.svg';
import { ReactComponent as Kaufland_InformationCircleIcon } from './icons/information-circle--kaufland.svg';
import { ReactComponent as LandscapeIcon } from './icons/landscape.svg';
import { ReactComponent as Kaufland_LinkIcon } from './icons/link--kaufland.svg';
import {
  ReactComponent as LinkIcon,
  ReactComponent as LinkIcon_Editor,
} from './icons/link--lidl.svg';
import { ReactComponent as LockCloseIcon } from './icons/lock-close.svg';
import { ReactComponent as Kaufland_MagnifierIcon } from './icons/magnifier--kaufland.svg';
import { ReactComponent as MagnifierIcon } from './icons/magnifier--lidl.svg';
import { ReactComponent as MessengerIcon } from './icons/messenger.svg';
import { ReactComponent as MinusIcon } from './icons/minus.svg';
import { ReactComponent as MobileIcon } from './icons/mobile.svg';
import { ReactComponent as NoIcon } from './icons/no-icon.svg';
import { ReactComponent as Kaufland_PagesIcon } from './icons/pages--kaufland.svg';
import { ReactComponent as PagesIcon } from './icons/pages--lidl.svg';
import { ReactComponent as ParagraphIcon } from './icons/paragraph-circle.svg';
import { ReactComponent as PencilIcon } from './icons/pencil.svg';
import { ReactComponent as PlayIcon, ReactComponent as PlayIcon_Editor } from './icons/play.svg';
import { ReactComponent as Kaufland_PlayIcon } from './icons/play--kaufland.svg';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as PortraitIcon } from './icons/portrait.svg';
import { ReactComponent as Kaufland_PrinterIcon } from './icons/printer--kaufland.svg';
import { ReactComponent as PrinterIcon } from './icons/printer--lidl.svg';
import { ReactComponent as QuadIcon } from './icons/quad.svg';
import {
  ReactComponent as RecipeIcon,
  ReactComponent as RecipeIcon_Editor,
} from './icons/recipe.svg';
import { ReactComponent as Kaufland_RecipeIcon } from './icons/recipe--kaufland.svg';
import { ReactComponent as RecipeFillIcon } from './icons/recipe-fill.svg';
import { ReactComponent as Kaufland_ShoppingCartIcon } from './icons/shopping-cart--kaufland.svg';
import { ReactComponent as ShoppingCartIcon } from './icons/shopping-cart--lidl.svg';
import { ReactComponent as ShoppingCartFilledIcon } from './icons/shopping-cart-filled.svg';
import { ReactComponent as Kaufland_ShoppingCartFilledIcon } from './icons/shopping-cart-filled--kaufland.svg';
import { ReactComponent as Kaufland_SocialShareIcon } from './icons/social-share--kaufland.svg';
import { ReactComponent as SocialShareIcon } from './icons/social-share--lidl.svg';
import { ReactComponent as Kaufland_StarIcon } from './icons/star--kaufland.svg';
import { ReactComponent as StarIcon } from './icons/star--lidl.svg';
import { ReactComponent as StarFilledIcon } from './icons/star-filled.svg';
import { ReactComponent as TabletIcon } from './icons/tablet.svg';
import { ReactComponent as TextIcon, ReactComponent as TextIcon_Editor } from './icons/text.svg';
import { ReactComponent as Kaufland_TextIcon } from './icons/text--kaufland.svg';
import { ReactComponent as Kaufland_TrashIcon } from './icons/trash--kaufland.svg';
import { ReactComponent as TrashIcon } from './icons/trash--lidl.svg';
import { ReactComponent as Kaufland_TwitterIcon } from './icons/twitter--kaufland.svg';
import { ReactComponent as TwitterIcon } from './icons/twitter--lidl.svg';
import { ReactComponent as UserIcon } from './icons/user.svg';
import { ReactComponent as Kaufland_WhatsAppIcon } from './icons/whatsapp--kaufland.svg';
import { ReactComponent as WhatsAppIcon } from './icons/whatsapp--lidl.svg';

export enum ICON_SIZE {
  XS = 8,
  S = 16,
  M = 24,
  L = 32,
  XL = 64,
}

export type ICON_SIZE_TYPE = ICON_SIZE.XS | ICON_SIZE.S | ICON_SIZE.M | ICON_SIZE.L | ICON_SIZE.XL;

interface IProps {
  size?: ICON_SIZE_TYPE;
  inButton?: boolean;
  alignVertical?: boolean;
  color?: string;
  icon?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

const ICON_MAP: IKeyValueData = {
  'editor-text-align-center': EditorTextAlignCenter,
  'editor-text-align-right': EditorTextAlignRight,
  'editor-text-align-left': EditorTextAlignLeft,
  'arrow-left--lidl': ArrowLeftIcon,
  'arrow-up--lidl': ArrowUpIcon,
  'arrow-up--kaufland': Kaufland_ArrowUpIcon,
  'arrow-down--kaufland': Kaufland_ArrowDownIcon,
  'arrow-right--lidl': ArrowRightIcon,
  'arrow-right--kaufland': Kaufland_ArrowRightIcon,
  'arrow-left--kaufland': Kaufland_ArrowLeftIcon,
  'bars-horizontal': BarsHorizontalIcon,
  'bookmark--lidl': BookmarkIcon,
  'bookmark--kaufland': Kaufland_BookmarkIcon,
  close: CloseIcon,
  'cross--lidl': CrossIcon,
  'cross--kaufland': Kaufland_CrossIcon,
  'download--lidl': DownloadIcon,
  'download--kaufland': Kaufland_DownloadIcon,
  'facebook--lidl': FacebookIcon,
  'facebook--kaufland': Kaufland_FacebookIcon,
  'flyer--lidl': FlyerIcon,
  'flyer--kaufland': Kaufland_FlyerIcon,
  'heart-filled--lidl': HeartFilledIcon,
  'heart-filled--kaufland': Kaufland_HeartFilledIcon,
  'heart--lidl': HeartIcon,
  'heart--kaufland': Kaufland_HeartIcon,
  'link--lidl': LinkIcon,
  link: LinkIcon_Editor,
  'link--kaufland': Kaufland_LinkIcon,
  'lock-close': LockCloseIcon,
  'magnifier--lidl': MagnifierIcon,
  'magnifier--kaufland': Kaufland_MagnifierIcon,
  'pages--lidl': PagesIcon,
  'pages--kaufland': Kaufland_PagesIcon,
  'paragraph-circle': ParagraphIcon,
  'shopping-cart-filled--lidl': ShoppingCartFilledIcon,
  'shopping-cart-filled--kaufland': Kaufland_ShoppingCartFilledIcon,
  'shopping-cart--lidl': ShoppingCartIcon,
  'shopping-cart--kaufland': Kaufland_ShoppingCartIcon,
  'star-filled': StarFilledIcon,
  'star--lidl': StarIcon,
  'star--kaufland': Kaufland_StarIcon,
  'twitter--lidl': TwitterIcon,
  'twitter--kaufland': Kaufland_TwitterIcon,
  'whatsapp--lidl': WhatsAppIcon,
  'whatsapp--kaufland': Kaufland_WhatsAppIcon,
  messenger: MessengerIcon,
  'social-share--lidl': SocialShareIcon,
  'social-share--kaufland': Kaufland_SocialShareIcon,
  'envelope-open': EnvelopeOpenIcon,
  'trash--lidl': TrashIcon,
  'trash--kaufland': Kaufland_TrashIcon,
  boxOpen: BoxOpenIcon,
  'printer--lidl': PrinterIcon,
  'printer--kaufland': Kaufland_PrinterIcon,
  plus: PlusIcon,
  minus: MinusIcon,
  14: FourteenIcon,
  15: FiveteenIcon,
  16: SixteenIcon,
  17: SeventeenIcon,
  18: EightteenIcon,
  19: NineteenIcon,
  20: TwentyIcon,
  21: TwentyOneIcon,
  'play--lidl': PlayIcon,
  play: PlayIcon_Editor,
  'play--kaufland': Kaufland_PlayIcon,
  'text--lidl': TextIcon,
  text: TextIcon_Editor,
  'text--kaufland': Kaufland_TextIcon,
  recipe: RecipeIcon_Editor,
  'recipe--lidl': RecipeIcon,
  'recipe--kaufland': Kaufland_RecipeIcon,
  'recipe-fill': RecipeFillIcon,
  'information-circle--lidl': InformationCircleIcon,
  'information-circle--kaufland': Kaufland_InformationCircleIcon,
  'exclamation-triangle--lidl': ExclamationTriangleIcon,
  'exclamation-triangle--kaufland': Kaufland_ExclamationTriangleIcon,
  hook: HookIcon,
  'hook-circle': HookCircleIcon,
  'bell--lidl': BellIcon,
  'bell--kaufland': Kaufland_BellIcon,
  clock: ClockIcon,
  pencil: PencilIcon,
  gift: GiftIcon,
  award: AwardIcon,
  'envelope-close': EnvelopeCloseIcon_Editor,
  'envelope-close--lidl': EnvelopeCloseIcon,
  'envelope-close--kaufland': Kaufland_EnvelopeCloseIcon,
  'no-icon': NoIcon,
  tablet: TabletIcon,
  mobile: MobileIcon,
  desktop: DesktopIcon,
  landscape: LandscapeIcon,
  portrait: PortraitIcon,
  quad: QuadIcon,
  user: UserIcon,
  'imprint--lidl': ParagraphIcon,
  'imprint--kaufland': KauflandImprint,
  'data-protection--lidl': LockCloseIcon,
  'data-protection--kaufland': KauflandDataProtection,
};

export default function IconC({
  size = ICON_SIZE.M,
  icon = 'placeholder',
  inButton = false,
  alignVertical = false,
  color,
  onClick,
}: IProps) {
  const iconClasses = classNames({
    icon: true,
    [`icon-${size}`]: true,
    [`icon-${icon}`]: true,
    'icon--button': inButton,
    'icon-vertical-middle': alignVertical,
  });

  const style = {
    color,
  };

  const Comp = ICON_MAP[icon];
  return Comp ? <Comp className={iconClasses} style={style} onClick={onClick} /> : null;
}
