import './style.scss';

import classNames from 'classnames';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import TRACKING_EVENT_COLLECTIONS from 'providers/Tracking/trackingEventCollections';
import { memo, ReactNode, useContext, useEffect } from 'react';

type Props = {
  className?: string;
  isLastPage?: boolean;
  isVisible?: boolean;
  type: 'newsletter' | 'preview' | 'default';
  children?: ReactNode;
};
const Page = ({ type, isVisible = false, isLastPage = false, className = '', children }: Props) => {
  const pageClasses = classNames({
    page: true,
    [className]: true,
  });

  const { trackEventUniversalAnalytics, trackEventDataLayerService } = useContext(TrackingContext);
  useEffect(() => {
    if (isVisible && isLastPage) {
      trackEventUniversalAnalytics({ ...TRACKING_EVENTS.FLYER_LASTPAGE_SEEN, eventLabel: type });
      trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.FLYER_LASTPAGE_SEEN);
    }
  }, [isVisible, isLastPage, trackEventUniversalAnalytics, type, trackEventDataLayerService]);

  return <li className={pageClasses}>{children}</li>;
};

export default memo(Page);
