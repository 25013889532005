import { createContext } from 'react';

export type GlobalDataContextProps = {
  id: string;
  uuid?: string;
  ar?: string;
};

export const GlobalDataContext = createContext<GlobalDataContextProps>({
  id: '',
  uuid: '',
});
