import {
  useFlippyContentId,
  useFlippyNavigate,
  useFlippyPageNumber,
  useFlippyView,
} from 'helper/js/hooks/router';
import { useSelector } from 'react-redux';

import { TENANT, VIEW } from '../../helper/js/constants';
import KauflandTrackingProvider from '../../providers/KauflandTracking';
import AgeRestrictionContainer from '../AgeRestrictionContainer';
import BarContainer from '../BarContainer';
import FlyerContentContainer from '../FlyerContentContainer';
import OverlayContainer from '../OverlayContainer';

export default function ViewContainer() {
  const flippyNavigate = useFlippyNavigate();
  const flippyPageNumber = useFlippyPageNumber();
  const flippyView = useFlippyView();
  const flippyContentId = useFlippyContentId();
  const ageRestriction: AAgeRestriction = useSelector((state: AppState) => state.ageRestriction);
  const isActiveView = (view: VIEW): boolean => view === flippyView;
  const isKaufland = import.meta.env.VITE_THEME === TENANT.KAUFLAND;
  const isMdMin = useSelector((state: AppState) => state.browser.isMdMin);

  const handleUpdateUrl = (view: string) => {
    flippyNavigate({ view });
  };

  const handlePageNumberChange = (targetPage: number) => {
    flippyNavigate({ view: isMdMin ? flippyView : VIEW.FLYER, page: `${targetPage}` });
  };

  const openProductDetailsInSidebar = (productId) => {
    flippyNavigate({
      view: VIEW.PRODUCTDETAILS,
      contentId: `${productId}`,
    });
  };

  const handleOverlayClose = () => {
    flippyNavigate({
      view: VIEW.FLYER,
      contentId: undefined,
    });
  };

  return (
    <>
      {isKaufland && <KauflandTrackingProvider />}
      {isActiveView(VIEW.JUMPMARKS) && (
        <BarContainer
          showView={VIEW.JUMPMARKS}
          updateUrl={handleUpdateUrl}
          currentPage={flippyPageNumber}
          currentView={flippyView}
        />
      )}
      <FlyerContentContainer />
      {isActiveView(VIEW.OVERVIEW) && (
        <BarContainer
          showView={VIEW.OVERVIEW}
          handlePageNumberChange={handlePageNumberChange}
          currentPage={flippyPageNumber}
          currentView={flippyView}
        />
      )}
      {isActiveView(VIEW.RECOMMENDEDPRODUCTS) && (
        <BarContainer
          updateUrl={handleUpdateUrl}
          showView={VIEW.RECOMMENDEDPRODUCTS}
          openProductDetailsInSidebar={openProductDetailsInSidebar}
          currentPage={flippyPageNumber}
          currentView={flippyView}
        />
      )}
      {(isActiveView(VIEW.VIDEO) || isActiveView(VIEW.TEXT)) && (
        <OverlayContainer
          handleClose={handleOverlayClose}
          currentView={flippyView}
          contentId={flippyContentId}
        />
      )}
      {ageRestriction.show && <AgeRestrictionContainer />}
    </>
  );
}
