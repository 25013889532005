import { updateVisiblePagesAction } from 'containers/SheetContainer/actions';
import { putResolve, takeEvery } from 'redux-saga/effects';

import { BROWSER_PROPERTY_UPDATE } from './constants';

export const getBrowserData = (state: AppState) => state.browser;
export const getRouterData = (state: AppState) => state.routing;
export const getWishListData = (state: AppState) => state.wishlistProducts;
export const getAgeRestrictionData = (state: AppState) => state.ageRestriction;

export function* updateBrowserConfig() {
  yield takeEvery(BROWSER_PROPERTY_UPDATE, updateBrowserConfiguration);
}

function* updateBrowserConfiguration(data: any) {
  yield putResolve({
    type: data.reducerEvent,
    data: data.data,
  });
  if (data.data.hasOwnProperty('isWideScreen')) {
    yield putResolve(updateVisiblePagesAction());
  }
}
