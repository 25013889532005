import Recipes from 'components/Recipes';
import { SidebarContext } from 'components/Sidebar/context';
import RecipeButton from 'components/SidebarRecipes/RecipeButton';
import { memo, useContext } from 'react';

import { SIDEBAR } from '../../helper/js/constants';

const SidebarRecipes = () => {
  const { currentPage, globalData } = useContext(SidebarContext);
  const data = globalData.recipeData;

  if (currentPage !== SIDEBAR.PAGES.RECIPES) {
    return null;
  }

  const hasData = data.length > 0;
  if (hasData) {
    return (
      <>
        <Recipes recipes={data} />
        <RecipeButton url={data[0].rootUrl as string} />
      </>
    );
  }
  return <Recipes />;
};

export default memo(SidebarRecipes);
