import './style.scss';

import ButtonC, { BUTTON_APPEARANCE } from 'components/ButtonC';
import { ICON_SIZE } from 'components/IconC';
import { useLanguage } from 'providers/Language';
import { memo, useCallback } from 'react';

import useRecommendedProductsOpenerVersion from './abtestingHook';

type Props = {
  onClick: () => void;
  recommendedProductsLength: number;
};

const RecommendedProductsOpener = ({ onClick, recommendedProductsLength }: Props) => {
  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  const { t } = useLanguage();
  const version = useRecommendedProductsOpenerVersion();

  if (recommendedProductsLength <= 0) {
    return null;
  }
  if (version === 'b') {
    return (
      <div className="recommended-products-opener--b">
        <ButtonC
          label={`(${recommendedProductsLength}) ${t('recommendedProductsButtonText')} `}
          type={BUTTON_APPEARANCE.PRIMARY}
          iconSize={ICON_SIZE.M}
          icon={`arrow-up--${import.meta.env.VITE_THEME}`}
          onClick={handleClick}
        />
      </div>
    );
  }

  return (
    <div className="recommended-products-opener">
      <ButtonC
        title={t('recommendedProductsButtonText')}
        showTooltipOnMount
        type={BUTTON_APPEARANCE.PRIMARY}
        iconSize={ICON_SIZE.L}
        icon={`star--${import.meta.env.VITE_THEME}`}
        onClick={handleClick}
        indicator={recommendedProductsLength}
      />
    </div>
  );
};

export default memo(RecommendedProductsOpener);
