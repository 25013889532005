import { RefObject, useEffect, useRef, useState } from 'react';

import svgUrlFallbackImg from './fallbackImg.svg';

const options = {
  root: null,
  rootMargin: '0px',
  threshold: [1, 0],
};

const usePreloadImage = (
  url: string,
  ref: RefObject<Element>,
  preloadImage: string = svgUrlFallbackImg,
) => {
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const isObserving = useRef<boolean>(false);
  const isImgLoading = useRef<boolean>(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      if (!isImgLoading.current && entries[0].isIntersecting) {
        isImgLoading.current = true;

        const img = new Image();
        img.src = url;
        img.onload = () => {
          setLoaded(true);
        };
      }
    };
    if (!isObserving.current && url && ref.current) {
      observer.current = new IntersectionObserver(handleIntersect, options);
      observer.current.observe(ref.current);
      isObserving.current = true;
    }
  }, [ref, url, loaded, isObserving]);
  return loaded ? url : preloadImage;
};

export default usePreloadImage;
