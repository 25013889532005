import { BREAKPOINTS } from 'helper/js/constants';
import { Component, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { handleBrowserProperties } from './actions';

type BrowserContainerProps = {
  handleBrowserProperties(d: any): void;
  firstPage: APagesItem;
  loading: boolean;
};

class BrowserContainer extends Component<PropsWithChildren<BrowserContainerProps>, {}> {
  mediaQuerys: any = {
    isWideScreen: undefined,
    isMdMin: undefined,
  };

  constructor(props: BrowserContainerProps) {
    super(props);
    this.checkMediaQuery('isMdMin', `(min-width: ${BREAKPOINTS.MD}px)`);
    this.checkMediaQuery('is850Max', `(max-width: ${BREAKPOINTS[850]}px)`);
    this.checkMediaQuery('isSmMin', `(min-width: ${BREAKPOINTS.SM}px)`);
    this.checkMediaQuery('isTouchDevice', '(pointer:coarse)');
  }

  updateBrowserProperty = (e: MediaQueryListEvent, browserProp: string) => {
    this.props.handleBrowserProperties({
      [browserProp]: e.matches,
    });
  };

  checkMediaQuery = (property: string, mediaQuery: string) => {
    if (this.mediaQuerys[property]) {
      this.mediaQuerys[property].removeListener(this.updateBrowserProperty);
      this.mediaQuerys = {
        ...this.mediaQuerys,
        [property]: undefined,
      };
    }
    this.mediaQuerys[property] = window.matchMedia(mediaQuery);
    this.updateBrowserProperty(this.mediaQuerys[property], property);
    this.mediaQuerys[property].addListener((e: MediaQueryListEvent) => {
      this.updateBrowserProperty(e, property);
    });
  };

  componentDidMount() {
    const page: APagesItem = this.props.firstPage;
    if (!page) {
      return;
    }
    const { width, height } = page;
    if (width && height) {
      this.checkMediaQuery('isWideScreen', `(min-aspect-ratio: ${width * 2}/${height})`);
    }
    this.props.handleBrowserProperties({
      isReadyForRender: true,
    });
  }

  render() {
    return !this.props.loading && this.props.children;
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: state.appData.loading,
  firstPage: state.appData.pages[0],
});
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    handleBrowserProperties: (browerData: any) => {
      dispatch(handleBrowserProperties(browerData));
    },
  };
}

const areStatesEqual = (nextProps: AppState, prevProps: AppState) =>
  nextProps.appData.loading === prevProps.appData.loading;

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
  areStatesEqual,
})(BrowserContainer);
