import './style.scss';

import classNames from 'classnames';
import ButtonC from 'components/ButtonC';
import { sleep } from 'helper/js/helper';
import {
  createRef,
  isValidElement,
  PropsWithChildren,
  PureComponent,
  TransitionEvent,
} from 'react';

type OverlayProps = {
  onClose?: () => void;
  className: string;
};

type OverlayState = {
  show: boolean;
};

const makeCancelable = (promise: any) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject(new Error('cancelled')) : resolve(val)),
      (error) => (hasCanceled_ ? reject(new Error('cancelled')) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export default class Overlay extends PureComponent<PropsWithChildren<OverlayProps>, OverlayState> {
  static defaultProps = {
    className: '',
  };

  state = {
    show: false,
  };

  overlayRef = createRef<HTMLDivElement>();

  timer: any = undefined;

  handleKeyPressed = (e: KeyboardEvent) => {
    if (e.keyCode === 27 || (e.type === 'click' && e.target === this.overlayRef.current)) {
      this.handleClose();
    }
  };

  async componentDidMount() {
    this.timer = makeCancelable(sleep(10));

    this.timer.promise
      .then(() => {
        this.setState({ show: true });
        document.addEventListener('keydown', this.handleKeyPressed, false);
      })
      .catch(() => {
        console.log('ERROR');
      });
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    if (
      e.target === this.overlayRef.current &&
      !e.currentTarget.classList.contains('overlay--open')
    ) {
      this.props.onClose?.();
    }
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPressed, false);
  }

  render() {
    const { children, className, onClose } = this.props;
    const overlayClasses = classNames({
      overlay: true,
      [className]: true,
      'overlay--open': isValidElement(children) && this.state.show,
    });
    return (
      <div
        className={overlayClasses}
        ref={this.overlayRef}
        onTransitionEnd={this.handleTransitionEnd}>
        <div className="overlay__button-close">
          {onClose && (
            <ButtonC
              onClick={this.handleClose}
              icon={`cross--${import.meta.env.VITE_THEME}`}
              ariaLabel="close overlay"
            />
          )}
        </div>
        {children}
      </div>
    );
  }
}
