import './style.scss';

import RecommendedItem from 'components/RecommendedItem';

interface RecommendedPreviewProps {
  thumbnailUrl: string;
  name: string;
  title: string;
  onClick: () => void;
}

const RecommendedPreview = ({
  thumbnailUrl,
  name,
  title,
  onClick = () => {},
}: RecommendedPreviewProps) => (
  <RecommendedItem
    thumbnailUrl={thumbnailUrl}
    name={name}
    title={title}
    asButton
    onClick={onClick}
    className="recommended__item--preview"
  />
);

export default RecommendedPreview;
