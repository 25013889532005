import { Action, ActionCreator } from 'redux';

import {
  FLYER_DATA_ERROR,
  FLYER_DATA_FALLBACKURLS,
  FLYER_DATA_LOADED,
  FLYER_DATA_LOADING,
  FLYER_DATA_PROGRESS,
  FLYER_DATA_SAVED,
} from './constants';

export interface FlyerDataStartLoadingAction extends Action {
  type: 'FLYER_DATA_LOADING';
}

export interface FlyerDataInProgressAction extends Action {
  type: 'FLYER_DATA_PROGRESS';
}

export interface FlyerDataLoadedAction extends Action {
  type: 'FLYER_DATA_LOADED';
  appState: Partial<AppState>;
}

export interface FlyerDataSavedAction extends Action {
  type: 'FLYER_DATA_SAVED';
}

export interface FlyerDataErrorAction extends Action {
  type: 'FLYER_DATA_ERROR';
  payload: any;
  error: boolean;
}

export interface FlyerDataFallbackAction extends Action {
  type: 'FLYER_DATA_FALLBACKURLS';
  locale: string;
  gtmId: string;
  urls: any;
}

export type FlyerDataActions =
  | FlyerDataStartLoadingAction
  | FlyerDataInProgressAction
  | FlyerDataLoadedAction
  | FlyerDataSavedAction
  | FlyerDataErrorAction
  | FlyerDataFallbackAction;

export const flyerDataStartLoading: ActionCreator<FlyerDataStartLoadingAction> = () => ({
  type: FLYER_DATA_LOADING,
});

export const flyerDataInProgress: ActionCreator<FlyerDataInProgressAction> = () => ({
  type: FLYER_DATA_PROGRESS,
});

export const flyerDataLoaded: ActionCreator<FlyerDataLoadedAction> = (appState) => ({
  type: FLYER_DATA_LOADED,
  appState,
});

export const flyerDataSaved: ActionCreator<FlyerDataSavedAction> = () => ({
  type: FLYER_DATA_SAVED,
});
export const flyerDataError: ActionCreator<FlyerDataErrorAction> = ({ payload }) => ({
  type: FLYER_DATA_ERROR,
  payload,
  error: true,
});

export const flyerDataFallback: ActionCreator<FlyerDataFallbackAction> = ({
  locale,
  gtmId,
  urls,
}) => ({
  type: FLYER_DATA_FALLBACKURLS,
  locale,
  gtmId,
  urls,
});
