import { Component, ComponentType, createContext } from 'react';

export type SheetContextProps = {
  cZoom: number;
  cSheetWidth: number;
  cSheetHeight: number;
  cPageWidth: number;
  cCurrentVisible: number;
};

export const SheetContext = createContext<SheetContextProps>({
  cZoom: 1,
  cSheetWidth: 0,
  cSheetHeight: 0,
  cPageWidth: 0,
  cCurrentVisible: 1,
});

export function withContextProps<OriginalProps extends object>(
  UnwrappedComponent: ComponentType<OriginalProps & SheetContextProps>,
) {
  type NewProps = Omit<OriginalProps, keyof SheetContextProps> & OwnProps;

  return class WithContextProps extends Component<NewProps, {}> {
    static WrappedComponent = UnwrappedComponent;

    render() {
      const Comp = UnwrappedComponent as any;

      return (
        <SheetContext.Consumer>
          {({ cZoom, cSheetHeight, cPageWidth, cCurrentVisible }) => (
            <Comp
              {...this.props}
              cZoom={cZoom}
              cSheetWidth={cZoom}
              cSheetHeight={cSheetHeight}
              cPageWidth={cPageWidth}
              cCurrentVisible={cCurrentVisible}
            />
          )}
        </SheetContext.Consumer>
      );
    }
  };
}
