const parseHtmlElementForLinks = (htmlString = '') => {
  if (typeof htmlString === 'string') {
    const htmlSpanElement = document.createElement('span');
    htmlSpanElement.innerHTML = htmlString.trim();
    const links = htmlSpanElement.querySelectorAll('a');
    if (htmlSpanElement) {
      [...links].forEach((link) => {
        link.setAttribute('target', '_blank');
      });
      return htmlSpanElement.outerHTML;
    }
  }

  return htmlString;
};

export default parseHtmlElementForLinks;
