import { SidebarContext } from 'components/Sidebar/context';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { SIDEBAR } from '../../../helper/js/constants';
import styles from './SubNavContent.module.scss';

export default function PriceDisclaimerContent() {
  const { currentPage } = useContext(SidebarContext);
  const { apiCountryCode } = useSelector((state: AppState) => state.appData);
  const priceDisclaimerUrl = `https://mobile.lidl.de/Mobile-Server/service/1337/staticPageService/${apiCountryCode}/staticPageContentByType/startext`;

  if (currentPage !== SIDEBAR.PAGES.PRICE_DISCLAIMER) {
    return null;
  }

  return (
    <iframe className={styles.iframe} title="product price disclaimer" src={priceDisclaimerUrl} />
  );
}
