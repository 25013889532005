import { Action, ActionCreator } from 'redux';

import { UPDATE_RECOMMENDATION_PRODUCTS } from './constants';

export interface UpdateRecommendationProductsAction extends Action {
  type: 'UPDATE_RECOMMENDATION_PRODUCTS';
  count: number;
  products: string[];
}

export type RecommendationActions = UpdateRecommendationProductsAction;

export const updateRecommendationProducts: ActionCreator<UpdateRecommendationProductsAction> = ({
  count = 0,
  products = [],
}) => ({
  type: UPDATE_RECOMMENDATION_PRODUCTS,
  count,
  products,
});
