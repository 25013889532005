import './style.scss';

import { useFlippyPageNumber } from 'helper/js/hooks/router';
import { useSelector } from 'react-redux';

function Subheader() {
  const isBreakpointSmMin = useSelector((state: AppState) => state.browser.isSmMin);
  const topics = useSelector((state: AppState) => state.appData.topics);
  const flippyPageNumber = useFlippyPageNumber();
  const currentPageTopic = topics.find(
    (topic) => flippyPageNumber >= topic.startPageNumber && flippyPageNumber <= topic.endPageNumber,
  );

  if (isBreakpointSmMin || topics.length === 0) {
    return null;
  }
  return (
    <div className="subheader">
      <h3
        className="subheader__content"
        style={{
          backgroundColor: currentPageTopic?.backgroundColor,
        }}>
        {currentPageTopic?.name}
      </h3>
    </div>
  );
}
export default Subheader;
