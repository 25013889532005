import PageStepper from 'components/PageStepper';
import { VIEW } from 'helper/js/constants';
import { useFlippyPageNumber, useFlippyView } from 'helper/js/hooks/router';
import { useSelector } from 'react-redux';

import { getVisiblePagesAmount } from '../../helper/js/helper';

type StepperContainerProps = {
  onClick: () => void;
  showArrowButtons?: boolean;
};

export default function StepperContainer({
  onClick,
  showArrowButtons = false,
}: Readonly<StepperContainerProps>) {
  const isWideScreen = useSelector((state: AppState) => state.browser.isWideScreen);
  const flippyView = useFlippyView();
  const flippyPageNumber = useFlippyPageNumber();
  const totalPages = useSelector((state: AppState) => state.appData.pages.length);
  const currentVisible = getVisiblePagesAmount(flippyPageNumber, totalPages, isWideScreen);

  return (
    <PageStepper
      isActive={flippyView === VIEW.OVERVIEW}
      onClick={onClick}
      currentPage={flippyPageNumber}
      totalPages={totalPages}
      currentVisible={currentVisible}
      showArrowButtons={showArrowButtons}
    />
  );
}
