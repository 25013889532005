import './style.scss';

import ButtonC, { BUTTON_APPEARANCE } from 'components/ButtonC';
import Page from 'components/Page';
import { LanguageContext } from 'providers/Language';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import { memo, useContext, useEffect } from 'react';

import { ReactComponent as NewsletterImage } from './newsletter.svg';

type PageNewsletterProps = {
  newsletterUrl?: string;
  className?: string;
  visible?: boolean;
  isLastPage?: boolean;
};

const PageNewsletter = ({
  className = '',
  newsletterUrl,
  isLastPage = false,
  visible = false,
}: PageNewsletterProps) => {
  const { trackEventUniversalAnalytics } = useContext(TrackingContext);
  const { t } = useContext(LanguageContext);

  useEffect(() => {
    if (visible) {
      trackEventUniversalAnalytics(TRACKING_EVENTS.FLYER_NEWSLETTER_SEEN);
    }
  }, [trackEventUniversalAnalytics, visible]);

  const handleButtonClick = () => {
    trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.FLYER_NEWSLETTER_BUTTON_CLICKED,
      eventLabel: newsletterUrl,
    });
  };

  return (
    <Page className={className} isLastPage={isLastPage} isVisible={visible} type="newsletter">
      <div className="newsletter">
        <NewsletterImage className="newsletter__image" />
        <span className="newsletter__text">{t('newsletterDescription')}</span>
        {newsletterUrl && (
          <ButtonC
            fullwidth
            type={BUTTON_APPEARANCE.PRIMARY}
            label={t('newsletterButton')}
            onClick={handleButtonClick}
            href={newsletterUrl}
          />
        )}
      </div>
    </Page>
  );
};

export default memo(PageNewsletter);
