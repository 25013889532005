import ButtonC, { BUTTON_APPEARANCE, BUTTON_HOVERSTYLE } from 'components/ButtonC';
import { ICON_SIZE } from 'components/IconC';
import { MediaGroupContext } from 'containers/MediaGroupsContainer/context';
import { CUSTOM_EVENTS } from 'helper/js/constants';
import { dispatchCustomEvent } from 'helper/js/helper';
import { LanguageContext } from 'providers/Language';
import { ReferenceContext } from 'providers/Reference';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import TRACKING_EVENT_COLLECTIONS from 'providers/Tracking/trackingEventCollections';
import { BaseSyntheticEvent, memo, useCallback, useContext, useMemo } from 'react';

type MediaLinkProps = {
  link?: string;
  type: string;
  id?: string;
  groupWidth: number;
  flipped?: boolean;
  isPromotion?: boolean;
  promotionId?: string;
  promotionTitle?: string;
  relation: number;
  text?: string;
  title?: string;
  icon: string;
  product?: AProductData;
  isAnimatedGif?: boolean;
};

const runwayRoot: HTMLElement | null = document.getElementById('runway-root');

const MediaLink = ({
  link = '',
  type = 'link-media',
  flipped = false,
  groupWidth = 1,
  relation,
  text = '',
  title = '',
  icon = `link--${import.meta.env.VITE_THEME}`,
  isPromotion,
  promotionId,
  promotionTitle,
  id,
  product,
  isAnimatedGif,
}: MediaLinkProps) => {
  const referenceContext = useContext(ReferenceContext);
  const { onClick } = useContext(MediaGroupContext);

  const { t } = useContext(LanguageContext);
  const { trackEventUniversalAnalytics, trackEventDataLayerService } = useContext(TrackingContext);

  const flyToWishList = useCallback(
    (currentTarget: HTMLButtonElement) => {
      const wishListButtonDomRef = referenceContext.getRef('wishListButton').current;
      if (!wishListButtonDomRef || !currentTarget || !runwayRoot) {
        return;
      }
      const clonedMediaLink: HTMLElement = currentTarget.cloneNode(true) as HTMLElement;
      if (typeof clonedMediaLink.animate === 'function') {
        const heartDomDimensions = wishListButtonDomRef.getBoundingClientRect();
        const currentTargetDimensions = currentTarget.getBoundingClientRect();
        const { top: currentTargetTop, left: currentTargetLeft } = currentTargetDimensions;
        const { top: heartTop, left: heartLeft } = heartDomDimensions;
        const fromH = currentTargetTop;
        const clonedMediaLinkStyles = `
                width: ${currentTarget.offsetWidth}px;
                height: ${currentTarget.offsetHeight}px;
                position: absolute;
                top: ${fromH}px;
                left: ${currentTargetLeft}px;
                z-index: 5000;`;

        clonedMediaLink.style.cssText = clonedMediaLinkStyles;
        clonedMediaLink.classList.add('button--flipped');
        runwayRoot.appendChild(clonedMediaLink);
        const animation = clonedMediaLink.animate(
          [
            {
              transform: 'translate(0px, 0px)',
              width: `${currentTarget.offsetWidth}px`,
              height: `${currentTarget.offsetHeight}px`,
            },
            {
              transform: `translate(${heartLeft - currentTargetLeft - 5}px, ${
                -currentTargetTop + heartTop - 5
              }px)`,
              height: '34px',
              width: '34px',
            },
          ],
          {
            duration: 1000,
            iterations: 1,
            fill: 'forwards',
            easing: 'ease-out',
          },
        );
        animation.onfinish = function () {
          runwayRoot.removeChild(clonedMediaLink);
        };
      }
    },
    [referenceContext],
  );

  const handleOnClick = useCallback(
    async (e: BaseSyntheticEvent) => {
      if (type !== 'product-shop') {
        e.preventDefault();
      }
      const { currentTarget } = e;
      id && onClick(type, id, isAnimatedGif, product, flipped);
      if (type === 'product-wishlist' && !flipped) {
        flyToWishList(currentTarget);
      }
      currentTarget.blur();
    },
    [id, onClick, type, isAnimatedGif, product, flipped, flyToWishList],
  );

  const handleOnClickOfExtLink = useCallback(() => {
    if (isPromotion) {
      trackEventUniversalAnalytics({
        ...TRACKING_EVENTS.FLYER_PROMOTION_CLICKED,
        eventAction: TRACKING_EVENTS.FLYER_PROMOTION_CLICKED.eventAction.replace(
          '%LINK_TITLE%',
          `${title}`,
        ),
        eventLabel: promotionId,
      });
      trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.FLYER_PROMOTION_CLICKED, {
        contentID: promotionId,
        contentLabel: promotionTitle,
      });
    }

    //kaufland tracking
    dispatchCustomEvent(CUSTOM_EVENTS.TRACK_EXTERNAL_LINK_CLICK, { id, link });

    trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.MEDIA_LINK_CLICKED,
      eventLabel: isAnimatedGif ? 'media-gif' : 'media',
    });

    trackEventDataLayerService(
      TRACKING_EVENT_COLLECTIONS.MEDIA_LINK_CLICKED,
      {
        contentLabel: isAnimatedGif ? 'media_gif' : 'media',
        contentID: 'media_link',
      },
      { eventInteractionType: 'open' },
      undefined,
      { linkName: title, linkType: 'external', linkURL: link },
    );
  }, [
    id,
    isAnimatedGif,
    isPromotion,
    link,
    promotionId,
    promotionTitle,
    title,
    trackEventDataLayerService,
    trackEventUniversalAnalytics,
  ]);

  const getMediaLink = useMemo(() => {
    const scale = 8.2;
    const typeLc = type.toLowerCase();
    const width = `${(100 / groupWidth) * scale}%`;
    const height = `${(100 / groupWidth) * relation * scale}%`;
    const css = { width, height };
    const modifiedType = typeLc === 'text' && text.length > 250 ? 'textlong' : typeLc;

    const getCustomizedLinkTitle = (translationKey: string): string => {
      const translationValue = t(translationKey);
      if (translationValue.includes('%s')) {
        return translationValue.replace('%s', title);
      }
      return `${title} ${translationValue}`;
    };

    let params: IKeyValueData = {
      hoverStyle: BUTTON_HOVERSTYLE.BACKGROUND,
      iconSize: ICON_SIZE.L,
      type: BUTTON_APPEARANCE.MEDIA,
      style: css,
      icon: import.meta.env.VITE_THEME === 'lidl' ? icon : `${icon}--${import.meta.env.VITE_THEME}`,
      title: '',
      href: undefined,
      onClick: undefined,
      forceTooltipTouch: false,
    };
    switch (modifiedType) {
      case 'link-media':
      case 'recipe':
        params = {
          ...params,
          title: getCustomizedLinkTitle('mediaLinksBlankTarget'),
          href: link,
          onClick: handleOnClickOfExtLink,
        };
        break;

      case 'text':
        params = {
          ...params,
          title: text,
          forceTooltipTouch: true,
        };
        break;

      case 'video':
        params = {
          ...params,
          onClick: handleOnClick,
        };
        break;

      case 'video-inline':
        params = {
          ...params,
          isClickable: false,
        };
        break;

      case 'textlong':
        params = {
          ...params,
          title: `${text.slice(0, 250)}...`,
          onClick: handleOnClick,
        };
        break;

      case 'product-wishlist':
        params = {
          ...params,
          flipped,
          icon: `heart--${import.meta.env.VITE_THEME}`,
          flipIcon: `heart-filled--${import.meta.env.VITE_THEME}`,
          onClick: handleOnClick,
          title: getCustomizedLinkTitle(
            flipped ? 'mediaLinkRemoveProductFromWishList' : 'mediaLinkAddProductToWishList',
          ),
        };
        break;

      case 'product-sidebar':
        params = {
          ...params,
          onClick: handleOnClick,
          title: getCustomizedLinkTitle('mediaLinkOpenProductInSidebar'),
        };
        break;

      case 'product-shop':
        params = {
          ...params,
          onClick: handleOnClick,
          href: link,
          title: getCustomizedLinkTitle('mediaLinkOpenProductInSidebar'),
        };
        break;

      default:
        break;
    }

    return <ButtonC {...params} />;
  }, [
    flipped,
    groupWidth,
    handleOnClick,
    handleOnClickOfExtLink,
    icon,
    link,
    relation,
    t,
    text,
    title,
    type,
  ]);
  return getMediaLink;
};

export default memo(MediaLink);
