import ButtonC from 'components/ButtonC';
import useElementInView from 'helper/js/hooks/use-element-in-view';
import { LanguageContext } from 'providers/Language';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import { memo, useCallback, useContext, useRef } from 'react';

import styles from './index.module.scss';

type Props = {
  url: string;
};
const RecipeButton = ({ url }: Props) => {
  const { t } = useContext(LanguageContext);
  const buttonContainer = useRef<HTMLDivElement>(null);
  const { trackEventUniversalAnalytics } = useContext(TrackingContext);

  const handleClick = useCallback(() => {
    trackEventUniversalAnalytics(TRACKING_EVENTS.RECIPE_WEBSITE_BUTTON_CLICKED);
  }, [trackEventUniversalAnalytics]);

  const trackRecipesViewed = useCallback(
    (isInView: boolean) => {
      if (isInView) {
        trackEventUniversalAnalytics(TRACKING_EVENTS.RECIPE_VIEWED_ALL);
      }
    },
    [trackEventUniversalAnalytics],
  );

  useElementInView(buttonContainer, trackRecipesViewed);

  return (
    <div className={styles.button} ref={buttonContainer}>
      <ButtonC fullwidth label={t('recipePage')} href={url} onClick={handleClick} />
    </div>
  );
};

export default memo(RecipeButton);
