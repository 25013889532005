import { mobileAPI } from './api';

export interface PRODUCTS_OVERVIEW_RESPONSE {
  Products: any[];
}

export interface PRODUCTS_DETAIL_RESPONSE {
  Product: any;
}

export async function getProduct(
  apiCountryCode: string,
  productId: string,
  regionCode?: string,
): Promise<PRODUCTS_DETAIL_RESPONSE> {
  const endpoint = `productService/${apiCountryCode}/product/EN/${productId}`;

  try {
    const response = await mobileAPI().get(endpoint, {
      ...(regionCode && { params: { warehouseKey: regionCode } }),
      validateStatus: (status) => status >= 200 && status < 300 && status !== 204,
    });
    return response.data;
  } catch (e) {
    throw new Error(`Product not found ${productId}`);
  }
}

export async function getProductsOverview(
  apiCountryCode: string,
  productIds: string[],
  regionCode?: string,
): Promise<PRODUCTS_OVERVIEW_RESPONSE> {
  const endpoint = `productService/${apiCountryCode}/productsForOverviewDisplay/EN/`;

  try {
    const response = await mobileAPI().post(
      endpoint,
      { ProductIds: productIds },
      {
        ...(regionCode && { params: { warehouseKey: regionCode } }),
      },
    );
    return response.data;
  } catch (e) {
    throw new Error(`Products not found ${productIds.join(',')}`);
  }
}
