import { Collections, ScenarioName } from '@mindshift/datalayer';

/**
 * structure copied from https://confluence.schwarz/display/LAENDER/Flyer+FE+tracking
 */

const TRACKING_EVENT_COLLECTIONS: { [key: string]: Collections } = {
  /**
   * GENERAL UI
   */

  FLYER_LASTPAGE_SEEN: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_View,
      eventCategory: 'view',
      eventInteractionType: 'view',
    },
    content: {
      contentID: 'last_page',
      contentType: 'flyer',
    },
  },

  /**
   * FLYER OVERVIEW
   */

  FLYER_PAGE_OPENED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_View,
      eventCategory: 'view',
      eventInteractionType: 'open|browse',
    },
    content: {
      contentID: '{leaflet_id}',
      contentLabel: '{leaflet_name}',
      contentType: 'flyer',
    },
  },
  FLYER_VIEWPORT_PAGE_COUNT_CHANGE: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_ChangeViewport,
      eventCategory: 'view',
      eventInteractionType: 'view',
    },
    content: {
      contentID: '{leaflet_id}',
      contentLabel: '{leaflet_name}',
      contentType: 'flyer',
    },
  },
  /**
   * OVERALL (Header Menu)
   */

  SEARCH_BUTTON_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'open',
    },
    content: {
      contentID: 'search',
      contentType: 'icon',
    },
  },
  WISHLIST_BUTTON_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'open',
    },
    content: {
      contentID: 'wishlist',
      contentType: 'icon',
    },
  },
  SHOPPING_CART_BUTTON_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'load',
    },
    content: {
      contentID: 'cart',
      contentType: 'icon',
    },
  },
  MENU_TOGGLE_BUTTON_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'open',
    },
    content: {
      contentID: 'menu',
      contentType: 'icon',
    },
  },
  BAR_OPENED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'open',
    },
    content: {
      contentID: 'jumpmarks',
      contentType: 'button',
    },
  },

  /**
   * LINKS
   */

  MEDIA_LINK_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: '{open|add}',
    },
    content: {
      contentID: '{media_link|addToWishlist|removeFromWishlist}',
      contentLabel: '{product_link|product|product_link_gif|product_gif|media|media_gif}',
      contentType: 'icon',
    },
  },

  /**
   * MENU
   */

  SHARE_FLYER_BUTTON_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'share',
    },
    content: {
      contentID: 'social',
      contentLabel: '{whatsapp|facebook|twitter}',
      contentType: 'icon',
    },
  },

  /**
   * SEARCH
   */

  SEARCH_FOR_TERM: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'load',
    },
    content: {
      contentID: 'search_term',
      contentLabel: '{searchterm}',
      contentType: 'button',
    },
  },
  SEARCH_NO_RESULTS_FOUND: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_View,
      eventCategory: 'enterViewport',
      eventInteractionType: 'view',
    },
    content: {
      contentID: 'search_no_results',
      contentLabel: '{searchterm}',
      contentType: 'message',
    },
  },
  SEARCH_FOR_TERM_BY_SUGGEST: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'load',
    },
    content: {
      contentID: 'search_by_suggest',
      contentLabel: '{buttonlabel}',
      contentType: 'button',
    },
  },
  SEARCH_RESULT_PAGE_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'load',
    },
    content: {
      contentID: 'search_page_click',
      contentLabel: '{searchterm}',
      contentType: 'thumbnail_flyer',
    },
  },

  /**
   * JUMPMARKS
   */

  JUMPMARK_BUTTON_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'click',
    },
    content: {
      contentID: 'jumpmark',
      contentLabel: '{jumpmark_id}',
      contentType: 'navigation',
    },
  },

  /**
   * PROMOTIONS
   */

  FLYER_PROMOTION_SEEN: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_View,
      eventCategory: 'enterViewport',
      eventInteractionType: 'view',
    },
    content: {
      contentID: '{promotion_id}',
      contentLabel: '{campaign_name}',
      contentType: 'promotion',
    },
  },
  FLYER_PROMOTION_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'load',
    },
    content: {
      contentID: '{promotion_id}',
      contentLabel: '{campaign_name}',
      contentType: 'promotion',
    },
  },

  /**
   * WISHLIST
   */

  WISHLIST_ITEM_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'load',
    },
    content: {
      contentID: 'whislist_product',
      contentLabel: '{productid}',
      contentType: 'thumbnail_product',
    },
  },

  /**
   * PRODUCT DETAIL PAGE & SHOPPING CART
   */

  SHOPPING_LINK_BUTTON_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.General_Product_Click,
      eventCategory: 'click',
      eventInteractionType: 'load',
    },
    content: {
      contentID: 'onlineshop',
      contentLabel: '{url}',
      contentType: 'sidebar_pdp',
    },
  },
  PRODUCT_DETAIL_ADD_TO_CART_BUTTON_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'add',
    },
    content: {
      contentID: 'addToCart',
      contentLabel: '{productId}',
      contentType: 'sidebar_pdp',
    },
  },
  SHOPPING_CART_HINT_LINK_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'load',
    },
    content: {
      contentID: 'view_cart',
      contentType: 'link',
    },
  },
  PRODUCT_DETAIL_WISHLIST_BUTTON_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: '{add|remove}',
    },
    content: {
      contentID: '{addToWishlist|removeFromWishlist}',
      contentLabel: 'product',
      contentType: 'sidebar_pdp',
    },
  },

  /**
   * MY ACCOUNT
   */

  ACCOUNT_BUTTON_CLICKED: {
    eventInfo: {
      eventAction: ScenarioName.Flyer_Click,
      eventCategory: 'click',
      eventInteractionType: 'load',
    },
    content: {
      contentID: 'myAccount',
      contentType: '{navigation|sidebar_menu}',
    },
  },
};

export default TRACKING_EVENT_COLLECTIONS;
