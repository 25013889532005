import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './Section.module.scss';

type Props = {
  classes?: string;
  visible?: any;
  margin?: boolean;
};

const Section = ({
  children,
  classes = '',
  visible = true,
  margin = true,
}: PropsWithChildren<Props>) => {
  if (!children || !visible) {
    return null;
  }

  const sectionClassNames = classNames({
    [`${styles.section}`]: true,
    [`${styles['section--nomargin']}`]: !margin,
    [classes]: true,
  });

  return <div className={sectionClassNames}>{children}</div>;
};

export default Section;
