import { AxiosResponse } from 'axios';

import { getBaseWithoutUuid } from '../helper/js/helper';
import { checkResponse, leafletAPI } from './api';

interface TRANSLATION_RESPONSE {
  success: boolean;
  translations: IKeyValueData;
}

// eslint-disable-next-line import/prefer-default-export
export async function getTranslations(clientLocale: string): Promise<TRANSLATION_RESPONSE> {
  const endpoint = `/translations`;

  try {
    const response = await fetchTranslations(clientLocale, endpoint);
    checkResponse(response, 'translations');
    return response.data;
  } catch (e) {
    throw new Error(`Translation not found for ${endpoint}`);
  }
}

function buildSearchParams(clientLocale: string): URLSearchParams {
  const searchParams = new URLSearchParams({
    domain_url: getBaseWithoutUuid(),
  });

  if (clientLocale !== '') {
    searchParams.set('client_locale', clientLocale);
  }
  return searchParams;
}

async function fetchTranslations(clientLocale: string, endpoint: string): Promise<AxiosResponse> {
  const endpointFallback = `/translations-fallback`;
  const requestEndpoint = clientLocale ? endpoint : endpointFallback;
  const searchParams = buildSearchParams(clientLocale);
  const response = await leafletAPI().get(requestEndpoint, { params: searchParams });
  return response;
}
