import './style.scss';

import { SidebarContext } from 'components/Sidebar/context';
import SidebarPage from 'components/SidebarPage';
import { AnimatePresence, motion } from 'framer-motion';
import { SIDEBAR } from 'helper/js/constants';
import { memo, PropsWithChildren, ReactNode, useContext } from 'react';

type Props = {
  pages: ReactNode[];
  contentClassName?: string;
};
const SidebarPages = ({ pages, contentClassName }: PropsWithChildren<Props>) => {
  const { currentPage } = useContext(SidebarContext);
  const pageWidth = 100 / pages.length;

  if (currentPage === SIDEBAR.PAGES.RATINGS) {
    contentClassName += ' sidebarpage--iframe';
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className="sidebarpages"
        style={{
          width: `${pages.length * 100}%`,
          transform: `translateX(-${currentPage * pageWidth}%)`,
        }}>
        {pages.map((page, index) => (
          <SidebarPage
            key={index}
            page={page}
            pageWidth={pageWidth}
            isActive={currentPage === index}
            contentClassName={contentClassName}
          />
        ))}
      </motion.div>
    </AnimatePresence>
  );
};

export default memo(SidebarPages);
