import { ZOOM } from 'helper/js/constants';
import { doesEventContainsElement, sleep } from 'helper/js/helper';
import {
  Component,
  createRef,
  MouseEvent as ReactMouseEvent,
  PropsWithChildren,
  TouchEvent as ReactTouchEvent,
} from 'react';

import Point, { IPoint } from './Point';
import Size from './Size';

const GUESTURE_TYPE = {
  UNSET: 'GUESTURE_TYPE_UNSET',
  PAN: 'GUESTURE_TYPE_PAN',
  CLICK: 'GUESTURE_TYPE_CLICK',
  PINCH: 'GUESTURE_TYPE_PINCH',
};

const POINTER_EVENT = {
  UNSET: 'UNSET',
  MOUSE_DOWN: 'MOUSE_DOWN',
  MOUSE_UP: 'MOUSE_UP',
};
type PinchToZoomProps = {
  isTouchDevice: boolean;
  debug?: boolean;
  onScaleUpdated: (scale: number) => void;
  className: string;
  currentPage: number;
  minZoomScale: number;
  maxZoomScale: number;
  zoom: number;
  onPanEvent: (position: string) => void;
};

type IPointerEvent = ReactTouchEvent | ReactMouseEvent;
class PinchToZoom extends Component<PropsWithChildren<PinchToZoomProps>, {}> {
  static defaultProps = {
    className: '',
    minZoomScale: ZOOM.MIN,
    maxZoomScale: ZOOM.MAX,
    zoom: ZOOM.MIN,
    onScaleUpdated: () => {},
    onPanEvent: () => {},
  };

  waitForResettingOrigin = false;

  zoomArea = createRef<HTMLDivElement>();

  zoomAreaContainer = createRef<HTMLDivElement>();

  transform = {
    zoomFactor: ZOOM.MIN,
    translate: Point.newOriginPoint(),
  };

  currentGesture = GUESTURE_TYPE.UNSET;

  lastPointerEvent = POINTER_EVENT.UNSET;

  pinchStartZoomFactor = ZOOM.MIN;

  pinchStartTouchMidpoint = Point.newOriginPoint();

  pinchStartTranslate = Point.newOriginPoint();

  pinchStartTouchPointDist = 0;

  panStartPoint = Point.newOriginPoint();

  panStartTranslate = Point.newOriginPoint();

  lastSingleTouchPoint: IPoint = Point.newOriginPoint();

  isDoubleTap = false;

  componentDidUpdate(prevProps: PinchToZoomProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.props.isTouchDevice
        ? this.zoomContentArea(this.props.minZoomScale)
        : this.zoomContentDoubleArea(this.props.minZoomScale);
    }
  }

  onPinchStart = (syntheticEvent: ReactTouchEvent) => {
    const _a = this.getTouchesCoordinate(syntheticEvent);
    const p1 = _a[0];
    const p2 = _a[1];
    this.pinchStartTouchMidpoint = Point.midpoint(p1, p2);
    this.pinchStartTouchPointDist = Point.distance(p1, p2);
    const _b = this.getTransform();
    const { currentZoomFactor } = _b;
    const { currentTranslate } = _b;
    this.pinchStartZoomFactor = currentZoomFactor;
    this.pinchStartTranslate = currentTranslate;
  };

  onPinchMove = (syntheticEvent: ReactTouchEvent) => {
    const _a = this.getTouchesCoordinate(syntheticEvent);
    const p1 = _a[0];
    const p2 = _a[1];
    const pinchCurrentTouchPointDist = Point.distance(p1, p2);
    const deltaTouchPointDist = pinchCurrentTouchPointDist - this.pinchStartTouchPointDist;
    const newZoomFactor = this.pinchStartZoomFactor + deltaTouchPointDist * 0.01;
    this.zoomContentArea(newZoomFactor);
  };

  onPinchEnd = () => {
    this.guardZoomAreaScale();
    this.guardZoomAreaTranslate();
    this.props.onScaleUpdated(this.getTransform().currentZoomFactor);
  };

  onPanStart = (syntheticEvent: IPointerEvent) => {
    const p1 = this.getTouchesCoordinate(syntheticEvent)[0];
    const { currentTranslate } = this.getTransform();
    this.panStartPoint = p1;
    this.panStartTranslate = currentTranslate;
  };

  onPanMove = (syntheticEvent: IPointerEvent) => {
    const dragPoint = this.getTouchesCoordinate(syntheticEvent)[0];
    const { currentZoomFactor } = this.getTransform();
    const origin = this.panStartPoint;
    const prevTranslate = this.panStartTranslate;
    const dragOffset = Point.offset(dragPoint, origin);
    const adjustedZoomOffset = Point.scale(dragOffset, 1 / currentZoomFactor);
    const nextTranslate = Point.sum(adjustedZoomOffset, prevTranslate);
    this.panContentArea(nextTranslate);
  };

  onPanEnd = () => {
    this.guardZoomAreaTranslate();
  };

  guardZoomAreaScale = () => {
    const { currentZoomFactor } = this.getTransform();
    if (currentZoomFactor > ZOOM.MAX) {
      this.zoomContentArea(ZOOM.MAX);
    } else if (currentZoomFactor < ZOOM.MIN) {
      this.zoomContentArea(ZOOM.MIN);
    }
  };

  truncRound = (num: number) => Math.trunc(num * 10000) / 10000;

  getTouchesCoordinate = (syntheticEvent: any) => {
    const { parentNode } = syntheticEvent.currentTarget;
    const { nativeEvent } = syntheticEvent;
    const containerRect = parentNode.getBoundingClientRect();
    const rect = {
      origin: { x: containerRect.left, y: containerRect.top },
      size: {
        width: containerRect.width,
        height: containerRect.height,
      },
    };
    if (
      !(parentNode instanceof HTMLElement) ||
      !(window.TouchEvent && nativeEvent instanceof TouchEvent)
    ) {
      const clickPoint = Point.normalizePointInRect(
        {
          x: syntheticEvent.clientX,
          y: syntheticEvent.clientY,
        },
        rect.origin,
      );
      return [clickPoint];
    }
    const touchList = nativeEvent.touches;
    let coordinates: IPoint[] = [];
    for (let i = 0; i < touchList.length; i += 1) {
      const touch = touchList.item(i);
      if (touch) {
        const touchPoint = {
          x: touch.clientX,
          y: touch.clientY,
        };
        const p = Point.normalizePointInRect(touchPoint, rect.origin);
        coordinates = [...coordinates, p];
      }
    }
    return coordinates;
  };

  guardZoomAreaTranslate = () => {
    const { validatePos } = this.guardZoomArea({});

    if (!validatePos || !this.zoomArea.current) {
      return;
    }
    this.panContentArea(validatePos);
    this.props.onScaleUpdated(this.getTransform().currentZoomFactor);
  };

  panContentArea = (pos: IPoint) => {
    this.setTransform({
      translate: pos,
    });
  };

  resetZoom = () => {
    this.setTransform({
      zoomFactor: ZOOM.MIN,
      translate: Point.newOriginPoint(),
    });
    this.props.onScaleUpdated(ZOOM.MIN);
    this.pinchStartZoomFactor = ZOOM.MIN;
    this.pinchStartTranslate = Point.newOriginPoint();
  };

  //mobile zoom logic
  zoomContentArea = (zoomFactor: number) => {
    if (!this.zoomAreaContainer.current || !this.zoomArea.current) {
      return;
    }
    if (zoomFactor === ZOOM.MIN) {
      this.resetZoom();
      return;
    }
    const prevZoomFactor = this.pinchStartZoomFactor;
    const prevTranslate = this.pinchStartTranslate;
    const boundSize = this.zoomAreaContainer.current.getBoundingClientRect();
    const prevZoomSize = Size.scale(boundSize, prevZoomFactor);
    const prevRectCenterPoint = {
      x: prevZoomSize.width / 2,
      y: prevZoomSize.height / 2,
    };

    //for pinch
    let midPoint: IPoint = this.pinchStartTouchMidpoint;
    if (this.currentGesture === GUESTURE_TYPE.CLICK) {
      //for double-tap
      midPoint = this.lastSingleTouchPoint;
    }
    const nextRectCenterPoint = {
      x: (midPoint.x - boundSize.left) * zoomFactor,
      y: (midPoint.y - 8) * zoomFactor,
    };

    const deltaTranslate = Point.scale(
      Point.offset(prevRectCenterPoint, nextRectCenterPoint),
      1 / (zoomFactor * prevZoomFactor),
    );

    const accumulateTranslate = Point.sum(deltaTranslate, prevTranslate);
    this.setTransform({
      zoomFactor: this.truncRound(zoomFactor),
      translate: accumulateTranslate,
    });
  };

  guardZoomArea = ({
    currentZoomFactor = this.getTransform().currentZoomFactor,
    currentTranslate = this.getTransform().currentTranslate,
  }: {
    currentZoomFactor?: number;
    currentTranslate?: IPoint;
  }) => {
    const zoomAreaContainer = this.zoomAreaContainer.current;
    const zoomArea = this.zoomArea.current;

    if (currentZoomFactor < ZOOM.MIN || !zoomAreaContainer || !zoomArea) {
      return {};
    }

    const containerW: number = zoomAreaContainer.clientWidth;
    const containerH: number = zoomAreaContainer.clientHeight;
    const contentW: number = zoomArea.clientWidth;
    const contentH: number = zoomArea.clientHeight;
    const spacing = 25;

    const boundSize = {
      width: containerW,
      height: containerH,
    };

    const contentSize = Size.scale(
      {
        width: contentW,
        height: contentH,
      },
      this.props.isTouchDevice ? currentZoomFactor : 2,
    );

    const diff = Size.diff(boundSize, contentSize);
    const diffInPoint = Size.toPoint(diff);
    const unitScaleLeftTopPoint = Point.scale(
      diffInPoint,
      this.props.isTouchDevice ? 1 / (2 * currentZoomFactor) : 1,
    );

    const maxLeftTopPoint = Point.boundWithin(
      { x: currentZoomFactor > 1 ? -spacing * currentZoomFactor : 0, y: 0 },
      unitScaleLeftTopPoint,
      Point.map(unitScaleLeftTopPoint, this.truncRound),
    );

    const unitScaleRightBottomPoint = Point.scale(
      diffInPoint,
      this.props.isTouchDevice ? 1 / currentZoomFactor : 1,
    );

    const maxRightBottomPoint = {
      x:
        currentZoomFactor > 1
          ? unitScaleRightBottomPoint.x + spacing * currentZoomFactor
          : Math.min(unitScaleRightBottomPoint.x, maxLeftTopPoint.x),
      y: Math.min(unitScaleRightBottomPoint.y, maxLeftTopPoint.y),
    };

    const validatePos = Point.boundWithin(maxRightBottomPoint, currentTranslate, maxLeftTopPoint);
    return {
      validatePos,
      currentTranslate,
    };
  };

  guard = async ({
    currentZoomFactor,
    currentTranslate,
  }: {
    currentZoomFactor: number;
    currentTranslate: IPoint;
  }) => {
    const { validatePos } = this.guardZoomArea({
      currentZoomFactor,
      currentTranslate,
    });
    if (currentZoomFactor > 1 && validatePos) {
      this.setTransform({
        zoomFactor: currentZoomFactor,
        translate: validatePos,
      });

      this.props.onScaleUpdated(this.getTransform().currentZoomFactor);
      return;
    }

    this.waitForResettingOrigin = true;
    this.setTransform({
      zoomFactor: currentZoomFactor,
    });

    await sleep(600);
    this.setTransform({
      translate: Point.newOriginPoint(),
    });
    this.props.onScaleUpdated(this.getTransform().currentZoomFactor);

    this.waitForResettingOrigin = false;
  };

  //desktop zoom logic
  zoomContentDoubleArea = async (zoomFactor: number) => {
    if (!this.zoomAreaContainer.current || !this.zoomArea.current || this.waitForResettingOrigin) {
      return;
    }
    const { left } = this.zoomAreaContainer.current.getBoundingClientRect();
    const nextRectCenterPoint = {
      x: (this.lastSingleTouchPoint.x - left) * zoomFactor,
      y: (this.lastSingleTouchPoint.y - 8) * zoomFactor,
    };

    const deltaTranslate = Point.scale(
      Point.offset(Point.newOriginPoint(), nextRectCenterPoint),
      1 / zoomFactor,
    );

    const currentTranslate = Point.sum(deltaTranslate, Point.newOriginPoint());

    const currentZoomFactor = this.truncRound(zoomFactor);
    if (currentZoomFactor > 1 && currentTranslate) {
      this.setTransform({
        zoomFactor: currentZoomFactor,
        translate: currentTranslate,
      });

      this.props.onScaleUpdated(this.getTransform().currentZoomFactor);
      return;
    }

    this.waitForResettingOrigin = true;
    this.setTransform({
      zoomFactor: currentZoomFactor,
    });

    await sleep(600);
    this.setTransform({
      translate: Point.newOriginPoint(),
    });
    this.props.onScaleUpdated(this.getTransform().currentZoomFactor);

    this.waitForResettingOrigin = false;
  };

  handleTouchStart = (syntheticEvent: ReactTouchEvent) => {
    if (!this.zoomAreaContainer.current || !this.zoomArea.current) {
      return;
    }
    const { nativeEvent } = syntheticEvent;
    this.lastPointerEvent = POINTER_EVENT.MOUSE_DOWN;

    switch (nativeEvent.touches?.length || 1) {
      case 2:
        this.currentGesture = GUESTURE_TYPE.PINCH;
        this.onPinchStart(syntheticEvent);
        break;
      default: {
        this.handleMouseDownOrOneTouchStart(syntheticEvent);
      }
    }
  };

  handleMouseDown = (syntheticEvent: ReactMouseEvent) => {
    if (!this.zoomAreaContainer.current || !this.zoomArea.current) {
      return;
    }

    this.lastPointerEvent = POINTER_EVENT.MOUSE_DOWN;
    this.handleMouseDownOrOneTouchStart(syntheticEvent);
  };

  handleMouseDownOrOneTouchStart = (syntheticEvent: IPointerEvent) => {
    const p1 = this.getTouchesCoordinate(syntheticEvent)[0];
    this.lastSingleTouchPoint = p1;

    if (this.getTransform().currentZoomFactor === ZOOM.MIN) {
      return;
    }
    this.onPanStart(syntheticEvent);
  };

  handleTouchMove = (syntheticEvent: ReactTouchEvent) => {
    const { nativeEvent } = syntheticEvent;
    switch (nativeEvent.touches?.length || 1) {
      case 2:
        if (this.currentGesture === GUESTURE_TYPE.PINCH) {
          this.onPinchMove(syntheticEvent);
        }
        break;
      default:
        if (
          this.lastPointerEvent === POINTER_EVENT.MOUSE_DOWN &&
          this.getTransform().currentZoomFactor > 1
        ) {
          this.onPanMove(syntheticEvent);
        }
    }
  };

  handleMouseMove = (syntheticEvent: ReactMouseEvent) => {
    this.handleMouseMoveOrOneTouchMove(syntheticEvent);
  };

  handleMouseMoveOrOneTouchMove = (syntheticEvent: IPointerEvent) => {
    if (
      this.lastPointerEvent === POINTER_EVENT.MOUSE_DOWN &&
      (this.currentGesture === GUESTURE_TYPE.CLICK ||
        this.currentGesture === GUESTURE_TYPE.PAN ||
        this.currentGesture === GUESTURE_TYPE.UNSET) &&
      this.getTransform().currentZoomFactor > 1
    ) {
      this.currentGesture = GUESTURE_TYPE.PAN;
      this.onPanMove(syntheticEvent);
      this.props.onPanEvent('start');
    }
  };

  handleTouchEnd = () => {
    if (!this.zoomAreaContainer.current || !this.zoomArea.current) {
      return;
    }

    this.lastPointerEvent = POINTER_EVENT.MOUSE_UP;

    if (this.currentGesture === GUESTURE_TYPE.PINCH) {
      this.onPinchEnd();
      return;
    }
    if (this.currentGesture === GUESTURE_TYPE.PAN) {
      this.props.onPanEvent('end');
      this.onPanEnd();
      return;
    }

    this.currentGesture = GUESTURE_TYPE.UNSET;
  };

  setTransform = (_a: any) => {
    if (!this.zoomAreaContainer.current || !this.zoomArea.current) {
      return;
    }
    /* eslint-disable no-void */
    const _b = _a === void 0 ? {} : _a;
    const _c = _b.zoomFactor;
    const zoomFactor = _c === void 0 ? this.transform.zoomFactor : _c;
    const _d = _b.translate;
    const translate =
      _d === void 0
        ? {
            x: this.transform.translate.x,
            y: this.transform.translate.y,
          }
        : _d;
    /* eslint-enable */

    const roundTransalteX = Math.round(translate.x * 1000) / 1000;
    const roundTransalteY = Math.round(translate.y * 1000) / 1000;
    if (zoomFactor < this.props.minZoomScale * 0.8) {
      return;
    }

    this.transform.zoomFactor = zoomFactor;
    this.transform.translate.x = roundTransalteX;
    this.transform.translate.y = roundTransalteY;
    let transformStyle = '';
    let originStyle = '';
    if (this.props.isTouchDevice) {
      transformStyle = `scale(${zoomFactor}) translate(${roundTransalteX}px, ${roundTransalteY}px) translateZ(0) `;
    } else {
      transformStyle = `scale(${zoomFactor})`;
      originStyle = `${roundTransalteX * -1}px ${roundTransalteY * -1}px`;
    }

    this.attachTransform(transformStyle, originStyle);
  };

  attachTransform = (transformStyle: string, originStyle: string) => {
    if (!this.zoomAreaContainer.current || !this.zoomArea.current) {
      return;
    }

    if (transformStyle) {
      this.zoomArea.current.style.transform = transformStyle;
    }
    if (originStyle) {
      this.zoomArea.current.style.transformOrigin = originStyle;
    }
  };

  getTransform = () => {
    const _a = this.transform;
    const { zoomFactor } = _a;
    const { translate } = _a;
    return {
      currentZoomFactor: zoomFactor,
      currentTranslate: {
        x: translate.x,
        y: translate.y,
      },
    };
  };

  isEventOnPage = (nativeEvent: MouseEvent) => doesEventContainsElement(nativeEvent, 'page');

  isClickOnMediaLink = (nativeEvent: MouseEvent) =>
    doesEventContainsElement(nativeEvent, ['button', 'video_inline', 'linkinternal']);

  validateClickEvent = (syntheticEvent: ReactMouseEvent<HTMLDivElement>): boolean => {
    const isValid =
      this.isClickOnMediaLink(syntheticEvent.nativeEvent) ||
      !this.isEventOnPage(syntheticEvent.nativeEvent) ||
      this.waitForResettingOrigin ||
      (this.lastPointerEvent === POINTER_EVENT.MOUSE_UP &&
        this.currentGesture === GUESTURE_TYPE.PAN);
    this.currentGesture = GUESTURE_TYPE.UNSET;
    return !isValid;
  };

  onClick = (syntheticEvent: ReactMouseEvent<HTMLDivElement>) => {
    if (!this.validateClickEvent(syntheticEvent)) {
      return;
    }
    if (this.props.isTouchDevice && !this.isDoubleTap) {
      this.isDoubleTap = true;
      setTimeout(() => {
        //timeout is required because syntheticEvent.detail === 2 doesn't work on ios
        this.isDoubleTap = false;
      }, 300);
      //no further action for single clicks -> return
      return false;
    }
    this.currentGesture = GUESTURE_TYPE.CLICK;
    const zoomFactor = this.getTransform().currentZoomFactor > ZOOM.MIN ? ZOOM.MIN : ZOOM.MAX;
    const p1 = this.getTouchesCoordinate(syntheticEvent)[0];
    if (this.zoomArea.current) {
      if (this.props.isTouchDevice) {
        this.lastSingleTouchPoint = p1;
        this.zoomContentArea(zoomFactor);
        this.props.onScaleUpdated(zoomFactor);
      } else {
        this.zoomContentDoubleArea(zoomFactor);
      }
    }

    return true;
  };

  render() {
    const { className } = this.props;
    const classNameList = ['', 'pinch-to-zoom-container'];

    const events = !this.props.isTouchDevice
      ? {
          onClick: this.onClick,
          onMouseMove: this.handleMouseMove,
          onMouseDown: this.handleMouseDown,
          onMouseUp: this.handleTouchEnd,
          onContextMenu: this.handleTouchEnd,
        }
      : {
          onTouchStart: this.handleTouchStart,
          onTouchMove: this.handleTouchMove,
          onTouchEnd: this.handleTouchEnd,
          onClick: this.onClick,
        };

    return (
      <div
        className={className.concat(classNameList.join(' '))}
        {...events}
        ref={this.zoomAreaContainer}>
        <div className="pinch-to-zoom-area" ref={this.zoomArea}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
export default PinchToZoom;
