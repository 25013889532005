import initialState from 'initialState';
import { Reducer } from 'redux';

import { NotificationActions } from './actions';
import { NOTIFCATION_SEEN, NOTIFICATION_LOADED } from './constants';

const notifications: Reducer<ANotification, NotificationActions> = (
  state = initialState.notifications,
  action: NotificationActions,
) => {
  switch (action.type) {
    case NOTIFCATION_SEEN:
      return {
        ...state,
        seen: [...new Set([...state.seen, ...action.seen])],
      };
    case NOTIFICATION_LOADED:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};
export default notifications;
