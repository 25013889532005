import './style.scss';

import { ScrollToContext } from 'providers/ScrollTo';
import {
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type Props = {
  page: ReactNode;
  pageWidth: number;
  isActive: boolean;
  contentClassName?: string;
};
const SidebarPage = ({
  page,
  pageWidth,
  isActive,
  contentClassName = '',
}: PropsWithChildren<Props>) => {
  const [isActiveState, setIsActiveState] = useState(isActive);
  const sidebar = useRef<HTMLDivElement | null>(null);

  const scrollTo = useCallback(({ offsetTop }: { offsetTop: number }) => {
    if (sidebar?.current) {
      sidebar.current.scroll({
        top: offsetTop - 25,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    if (!isActiveState && isActive) {
      setIsActiveState(true);
    }
  }, [isActive, isActiveState]);
  return (
    <ScrollToContext.Provider value={{ scrollTo }}>
      <div
        style={{ flexBasis: `${pageWidth}%` }}
        className={`sidebarpage ${contentClassName}`}
        ref={sidebar}>
        {isActiveState && page}
      </div>
    </ScrollToContext.Provider>
  );
};

export default memo(SidebarPage);
