import { memo } from 'react';
import { BrowserRouter, Route, Routes as RouterRoutes } from 'react-router-dom';

import NoMatch from './containers/NoMatchContainer';
import { getBaseAndUuidBeforePath } from './helper/js/helper';
import FlyerProjectPage from './pages/FlyerProjectPage';

export const defaultRoute = '/:uuid/view/:view/:contentId?/page/:page';
export const routes = [
  defaultRoute,
  '/:uuid/view/:view/:contentId?/page/:page/ar/:ar',
  '/:uuid/view/:view/:contentId?/ar/:ar',
  '/:uuid/ar/:ar',
  '/:uuid/ar/:ar/ar/:wtf',
  '/:uuid/view/:view/:contentId?/page/:page/store/:storeId',
  '/:uuid/view/:view/:contentId?/store/:storeId',
  '/:uuid/store/:storeId',
];

function Routes() {
  const { base } = getBaseAndUuidBeforePath();

  return (
    <BrowserRouter basename={base}>
      <RouterRoutes>
        {routes.map((route, index) => (
          <Route key={index} path={route} element={<FlyerProjectPage />} />
        ))}
        <Route path="*" element={<NoMatch />} />
      </RouterRoutes>
    </BrowserRouter>
  );
}
export default memo(Routes);
