// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Subheader from 'components/Subheader';
import BrowserContainer from 'containers/BrowserContainer';
import ContentContainer from 'containers/ContentContainer';
import CookieConsentContainer from 'containers/CookieConsentContainer';
import ErrorContainer from 'containers/ErrorContainer';
import FooterContainer from 'containers/FooterContainer';
import GlobalTrackingContainer from 'containers/GlobalTrackingContainer';
import HeaderContainer from 'containers/HeaderContainer';
import SidebarContainer from 'containers/SidebarContainer';
import ViewContainer from 'containers/ViewContainer/ViewContainer';
import ToggleBox from 'develop/ToggleBox';
import DocumentHead from 'providers/DocumentHead';
import KameleoonProvider from 'providers/Kameleoon';
import LanguageProvider from 'providers/Language';
import ReferenceProvider from 'providers/Reference';
import RouterProvider from 'providers/Router';
import GoogleTrackingProvider from 'providers/Tracking';
import { memo } from 'react';

import { useBrowserHistoryListener } from '../../helper/js/hooks/router';

function FlyerProjectPage() {
  useBrowserHistoryListener();

  return (
    <>
      <CookieConsentContainer />
      <KameleoonProvider />
      <ReferenceProvider>
        <RouterProvider>
          <GoogleTrackingProvider>
            <GlobalTrackingContainer />
            <ContentContainer>
              {({
                hasError,
                loading,
              }: {
                language: string;
                hasError: boolean;
                loading: boolean;
              }) => (
                <LanguageProvider>
                  <BrowserContainer loading={loading}>
                    <DocumentHead />
                    <main className="main" role="main">
                      <HeaderContainer hasError={hasError} />
                      <Subheader />
                      {hasError && <ErrorContainer />}
                      {!hasError && <ViewContainer />}
                      <FooterContainer hasError={hasError} />
                    </main>
                    <SidebarContainer />
                  </BrowserContainer>
                </LanguageProvider>
              )}
            </ContentContainer>
          </GoogleTrackingProvider>
        </RouterProvider>
      </ReferenceProvider>
      <ToggleBox />
    </>
  );
}

export default memo(FlyerProjectPage, () => true);
