import { ITRACKING } from '.';

const TRACKING_EVENTS: { [key: string]: ITRACKING } = {
  MENU_TOGGLE_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'menu_toggle_button_clicked',
  },
  FLYER_RECOMMENDED_SEEN: {
    eventCategory: 'flyer_recommended',
    eventAction: 'last_page_seen',
  },

  FLYER_RECOMMENDED_BUTTON_CLICKED: {
    eventCategory: 'flyer_recommended',
    eventAction: 'button_last_page_clicked',
  },
  FLYER_RECOMMENDED_PREVIEW_BUTTON_CLICKED: {
    eventCategory: 'flyer_recommended',
    eventAction: 'preview_button_clicked',
  },
  FLYER_RECOMMENDED_LEAFLET_CLICKED: {
    eventCategory: 'flyer_recommended',
    eventAction: 'leaflet_clicked',
  },
  FLYER_RECOMMENDED_OVERLAY_LEAFLET_CLICKED: {
    eventCategory: 'flyer_recommended',
    eventAction: 'leaflet_overview_clicked',
  },
  FLYER_PROMOTION_SEEN: {
    eventCategory: 'flyer_promotion',
    eventAction: 'seen',
  },
  FLYER_PROMOTION_CLICKED: {
    eventCategory: 'flyer_promotion',
    eventAction: '%LINK_TITLE%_clicked',
  },
  FLYER_LASTPAGE_SEEN: {
    eventCategory: 'flyer_webview',
    eventAction: 'last_page_seen',
  },
  FLYER_NEWSLETTER_SEEN: {
    eventCategory: 'flyer_newsletter',
    eventAction: 'seen',
  },
  FLYER_NEWSLETTER_BUTTON_CLICKED: {
    eventCategory: 'flyer_newsletter',
    eventAction: 'newsletter_button_clicked',
  },
  FLYER_ZOOMLEVEL_CHANGED: {
    eventCategory: 'flyer_webview',
    eventAction: 'zoomlevel_changed',
  },
  FLYER_ZOOMLEVEL_CHANGED_BY_CLICK: {
    eventCategory: 'flyer_webview',
    eventAction: 'zoomlevel_changed_by_click',
  },
  PRIVACY_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'privacy_button_clicked',
  },
  REGIONALIZATION_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'regionalization_button_clicked',
  },
  IMPRINT_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'imprint_button_clicked',
  },
  PDF_DOWNLOAD_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'pdf_download_clicked',
  },
  OVERVIEW_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'page_overview_button_clicked',
    eventLabel: 'menu',
  },
  NOTIFICATION_CLOSE_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'notification_close_button_clicked',
  },
  NOTIFICATION_OPEN_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'notification_open_button_clicked',
  },
  OVERVIEW_PAGE_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'overview_page_clicked',
  },
  TOPICS_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'topics_button_clicked',
  },
  SHOPPING_CART_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'shopping_cart_button_clicked',
  },
  SHOPPING_CART_HINT_LINK_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'shopping_cart_hint_link_clicked',
  },
  QUIT_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'quit_button_clicked',
  },
  EXIT_FLYER_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'exit_flyer_button_clicked',
  },
  BRAND_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'brand_button_clicked',
  },
  MEDIA_LINK_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'media_link_button_clicked',
  },
  NAVIGATION_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'placeholder',
  },
  OVERLAY_BACK_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'back_button_clicked',
  },
  ORIENTATION_INITIALE: {
    eventCategory: 'flyer_webview',
    eventAction: 'orientation_init',
  },
  ORIENTATION_CHANGED: {
    eventCategory: 'flyer_webview',
    eventAction: 'orientation_changed',
  },
  JUMPMARK_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'jumpmark_button_clicked',
  },
  WISHLIST_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'wishlist_button_clicked',
  },
  SHARE_FLYER_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'share_flyer_button_clicked',
  },
  SHARE_WISHLIST_BUTTON_CLICKED: {
    eventCategory: 'flyer_wishlist',
    eventAction: 'share_wishlist_button_clicked',
  },
  WISHLIST_ITEM_BUTTON_DELETE_CLICKED: {
    eventCategory: 'flyer_wishlist',
    eventAction: 'wishlist_item_button_delete_clicked',
  },
  WISHLIST_BUTTON_DELETE_ALL_CLICKED: {
    eventCategory: 'flyer_wishlist',
    eventAction: 'wishlist_button_delete_all_clicked',
  },
  WISHLIST_BUTTON_EMAIL_CLICKED: {
    eventCategory: 'flyer_wishlist',
    eventAction: 'wishlist_button_email_clicked',
  },
  WISHLIST_BUTTON_PDF_CLICKED: {
    eventCategory: 'flyer_wishlist',
    eventAction: 'wishlist_button_pdf_clicked',
  },
  WISHLIST_ITEM_CLICKED: {
    eventCategory: 'flyer_wishlist',
    eventAction: 'wishlist_item_clicked',
  },
  SEARCH_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'search_button_clicked',
  },
  PRODUCT_DETAIL_WISHLIST_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'product_detail_wishlist_button_clicked',
  },
  PRODUCT_DETAIL_ADD_TO_CART_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'product_detail_add_to_cart_button_clicked',
  },
  PRODUCT_DETAIL_RATINGS_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'product_detail_rating_button_clicked',
  },
  PRODUCT_DETAIL_DESCRIPTION_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'product_detail_description_button_clicked',
  },
  PRODUCT_DETAIL_PRICE_DISCLAIMER_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'product_detail_price_disclaimer_clicked',
  },
  SHOPPING_LINK_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'shopping_link_button_clicked',
  },
  SEARCH_RESULT_PAGE_CLICKED: {
    eventCategory: 'flyer_search',
    eventAction: 'search_page_clicked',
  },
  SEARCH_FOR_TERM: {
    eventCategory: 'flyer_search',
    eventAction: 'search_for_term',
  },
  SEARCH_FOR_TERM_BY_SUGGEST: {
    eventCategory: 'flyer_search',
    eventAction: 'search_for_term_by_suggest',
  },
  SEARCH_NO_RESULTS_FOUND: {
    eventCategory: 'flyer_search',
    eventAction: 'search_no_results_found',
  },
  RECOMMENDED_PRODUCTS_WISHLIST_BUTTON_CLICKED: {
    eventCategory: 'flyer_recommended_products',
    eventAction: 'wishlist_button_clicked',
  },
  RECOMMENDED_PRODUCTS_PRODUCT_CLICKED: {
    eventCategory: 'flyer_recommended_products',
    eventAction: 'wishlist_product_clicked',
  },
  BAR_SCROLLED: {
    eventCategory: 'flyer_bar',
    eventAction: 'scrolled',
  },
  BAR_CLOSED: {
    eventCategory: 'flyer_bar',
    eventAction: 'closed',
  },
  BAR_OPENED: {
    eventCategory: 'flyer_bar',
    eventAction: 'opened',
  },
  RECIPE_CTA_CLICKED: {
    eventCategory: 'flyer_recipe',
    eventAction: 'cta_button_clicked',
  },
  RECIPE_TILE_CLICKED: {
    eventCategory: 'flyer_recipe',
    eventAction: 'tile_clicked',
  },
  RECIPE_VIEWED_ALL: {
    eventCategory: 'flyer_recipe',
    eventAction: 'recipes_viewed',
  },
  RECIPE_WEBSITE_BUTTON_CLICKED: {
    eventCategory: 'flyer_recipe',
    eventAction: 'recipe_website_button_clicked',
  },
  ERROR_PAGE_SEEN: {
    eventCategory: 'flyer_error_page',
    eventAction: 'error_page_seen',
  },
  ERROR_PAGE_BUTTON_CLICKED: {
    eventCategory: 'flyer_error_page',
    eventAction: 'error_page_button_clicked',
  },
  ACCOUNT_NAVIGATION_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'account_navigation_button_clicked',
  },
  ACCOUNT_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'account_button_clicked',
  },
  LOGOUT_BUTTON_CLICKED: {
    eventCategory: 'flyer_webview',
    eventAction: 'logout_button_clicked',
  },
  FLYER_PAGE_OPENED: {
    eventCategory: 'flyer',
    eventAction: 'flyer opened',
  },
};
export default TRACKING_EVENTS;
