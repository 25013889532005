import ButtonC, { BUTTON_APPEARANCE } from 'components/ButtonC';
import { getTargetPageNumber, getVisiblePagesAmount, validateNavigation } from 'helper/js/helper';
import { useFlippyNavigate, useFlippyPageNumber } from 'helper/js/hooks/router';
import { LanguageContext } from 'providers/Language';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ICON_SIZE } from '../../components/IconC';

export const NAVIGATION_BUTTON_TYPES = {
  forward: 'forward',
  backward: 'backward',
};
type NavigationButtonContainerProps = {
  icon: string;
  direction: string;
  className?: string;
  visible?: boolean;
};

export default function NavigationButtonContainer({
  icon,
  direction,
  visible = true,
}: Readonly<NavigationButtonContainerProps>) {
  const { t } = useContext(LanguageContext);
  const { trackEventUniversalAnalytics } = useContext(TrackingContext);
  const flippyNavigate = useFlippyNavigate();
  const flippyPageNumber = useFlippyPageNumber();
  const totalPages = useSelector((state: AppState) => state.appData.pages.length);
  const isWideScreen = useSelector((state: AppState) => state.browser.isWideScreen);
  const currentVisible = getVisiblePagesAmount(flippyPageNumber, totalPages, isWideScreen);

  const handleClick = () => {
    const targetPage = getTargetPageNumber(
      flippyPageNumber,
      direction,
      currentVisible,
      isWideScreen,
    );

    if (!checkIfDisabled()) {
      flippyNavigate({ page: `${targetPage}` });
      trackEventUniversalAnalytics({
        ...TRACKING_EVENTS.NAVIGATION_BUTTON_CLICKED,
        eventAction: `${direction}_button_clicked`,
        eventLabel: 'button',
      });
    }
  };

  const checkIfDisabled = () =>
    validateNavigation(flippyPageNumber, direction, totalPages, isWideScreen);

  return (
    <ButtonC
      type={BUTTON_APPEARANCE.PRIMARY_NEGATIVE}
      icon={icon}
      iconSize={ICON_SIZE.S}
      onClick={handleClick}
      disabled={checkIfDisabled()}
      ariaLabel={t(direction)}
      className={`button--navigation button--navigation-${import.meta.env.VITE_THEME} ${
        !visible ? 'button--hidden' : ''
      }`}
    />
  );
}
