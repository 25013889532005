import initialState from 'initialState';
import { Reducer } from 'redux';

import { FlyerDataActions } from './actions';
import {
  FLYER_DATA_ERROR,
  FLYER_DATA_FALLBACKURLS,
  FLYER_DATA_LOADED,
  FLYER_DATA_LOADING,
  FLYER_DATA_SAVED,
} from './constants';

const appData: Reducer<AAppData, FlyerDataActions> = (
  state: AAppData = initialState.appData,
  action: FlyerDataActions,
) => {
  switch (action.type) {
    case FLYER_DATA_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FLYER_DATA_SAVED:
      return {
        ...state,
      };
    case FLYER_DATA_LOADED:
      const { type, ...data } = action;
      return {
        ...state,
        ...data.appState,
        loading: false,
      };
    case FLYER_DATA_FALLBACKURLS:
      return {
        ...state,
        locale: action.locale,
        gtmId: action.gtmId,
        urls: { ...action.urls },
      };
    case FLYER_DATA_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default appData;
