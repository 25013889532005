import { createContext } from 'react';

export type MediaGroupContextProps = {
  onClick: (
    type: string,
    id: string,
    isAnimatedGif: boolean | undefined,
    product: AProductData | undefined,
    isInWishList: boolean | undefined,
  ) => void;
  onDeepLink: (pageNumber: number) => void;
};

export const MediaGroupContext = createContext<MediaGroupContextProps>({
  onClick: (
    type: string,
    id: string,
    isAnimatedGif: boolean | undefined,
    product: AProductData | undefined,
    isInWishList: boolean | undefined,
  ) => {},
  onDeepLink: (pageNumber: number) => {},
});
