import { LanguageContextProps, withLanguage } from 'providers/Language';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

type TitleProviderProps = {
  title: string;
  locale: string;
  name: string;
};

class TitleProvider extends PureComponent<TitleProviderProps & LanguageContextProps, {}> {
  updateDocumentTitle() {
    const { t, title, locale, name } = this.props;

    let documentTitle = t('pageDefaultTitle');
    if (title && name) {
      documentTitle = `${name} ${title} - ${t('pageDefaultTitle')}`;
    }
    document.title = documentTitle;
    document.documentElement.lang = locale;
  }

  componentDidUpdate() {
    this.updateDocumentTitle();
  }

  componentDidMount() {
    this.updateDocumentTitle();
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: AppState) => ({
  title: state.appData.title,
  name: state.appData.name,
  locale: state.appData.locale,
});
export default withLanguage(connect(mapStateToProps, {})(TitleProvider));
