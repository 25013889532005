import './style.scss';

import { Component } from 'react';

type TextOverlayProps = {
  text: string;
};
export default class TextOverlay extends Component<TextOverlayProps, {}> {
  static defaultProps = {
    text: '',
  };

  render() {
    const { text } = this.props;
    return (
      <div className="text-overlay">
        <div className="text-overlay__content">{text}</div>
      </div>
    );
  }
}
