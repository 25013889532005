export const ZOOM = {
  MAX: 3,
  MIN: 1,
};
export enum VIEW {
  ACCOUNT = 'account',
  FLYER = 'flyer',
  MENU = 'menu',
  SEARCH = 'search',
  JUMPMARKS = 'jumpmarks',
  OVERVIEW = 'overview',
  WISHLIST = 'wishlist',
  VIDEO = 'video',
  TEXT = 'text',
  RECOMMENDED = 'recommended',
  RECOMMENDEDPRODUCTS = 'recommendedproducts',
  PRODUCTDETAILS = 'product',
}

export enum APPEARANCE {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
}

export const SIDEBAR = {
  SLIDEOUT_WIDTH: 350,
  PAGES: {
    HOME: 0,
    RECIPES: 1,
    RATINGS: 2,
    DESCRIPTION: 3,
    PRICE_DISCLAIMER: 4,
  },
};

export const BAR = {
  OVERVIEW: 120,
};

export const OVERLAY_OFFSET: IKeyValueData = {
  jumpmarks: 52,
  overview: 150,
};

export const BREAKPOINTS = {
  SM: 600,
  850: 850,
  MD: 960,
  LG: 1280,
};

export const SOCIAL_SHARE_ITEMS = {
  FACEBOOK: {
    name: 'facebook',
    url: 'https://www.facebook.com/sharer/sharer.php?u=',
    icon: `facebook--${import.meta.env.VITE_THEME}`,
    title: 'Facebook',
  },
  WHATSAPP: {
    name: 'whatsapp',
    url: 'https://api.whatsapp.com/send?text=',
    icon: `whatsapp--${import.meta.env.VITE_THEME}`,
    title: 'Whatsapp',
  },
  TWITTER: {
    name: 'twitter',
    url: 'https://twitter.com/intent/tweet?text=',
    icon: `twitter--${import.meta.env.VITE_THEME}`,
    title: 'Twitter',
  },
};

export enum MINDSHIFT_LINKS {
  CART = '/otc/cart',
  LOGIN = '/user-api/login?redirect=',
  LOGOUT = '/user-api/logout?redirect=',
  ACCOUNT = '/css/order-history/',
}

export const TENANT = {
  LIDL: 'lidl',
  KAUFLAND: 'kaufland',
};

export const COLORS = {
  BRAND: {
    LIDL: '#0050AA',
    KAUFLAND: '#FFD700',
  },
  BRANDHOVER: {
    LIDL: '#003673',
    KAUFLAND: '#FFD700',
  },
};

export enum ENVIRONMENT {
  PRODUCTION = 'production',
  TEST = 'test',
  DEVELOPMENT = 'development',
}

export enum CUSTOM_EVENTS {
  TRACK_EXTERNAL_LINK_CLICK = 'trackExternalLinkClick',
}
