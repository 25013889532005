export const SHEET_PAGES_VISIBLE_UPDATE = 'SHEET_PAGES_VISIBLE_UPDATE';
export const SHEET_PAGES_VISIBLE_UPDATED = 'SHEET_PAGES_VISIBLE_UPDATED';
export const SHEET_PAGES_ZOOM_UPDATE = 'SHEET_PAGES_ZOOM_UPDATE';
export const SHEET_NAVIGATE_TO_PAGE = 'SHEET_NAVIGATE_TO_PAGE';
export const SHEET_NAVIGATE = 'SHEET_NAVIGATE';
export const SHEET_NAVIGATE_BEFORE_START = 'SHEET_NAVIGATE_BEFORE_START';
export const SHEET_NAVIGATE_START = 'SHEET_NAVIGATE_START';
export const SHEET_NAVIGATE_END = 'SHEET_NAVIGATE_END';
export const TRANSITION_TYPES = {
  BACKWARD: 'backward',
  FORWARD: 'forward',
  FRONT_COVER_TO_BACK_COVER: 'from-front-to-back-cover',
  BACK_COVER_TO_FRONT_COVER: 'from-back-to-front-cover',
  FROM_BACK_COVER: 'from-back-cover',
  FROM_FRONT_COVER: 'from-front-cover',
  TO_FRONT_COVER: 'to-front-cover',
  TO_BACK_COVER: 'to-back-cover',
};
export const FLYER_FLIP_DURATION = 700;
