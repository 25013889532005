import { memo, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

type Props = {
  root?: string;
};

const Modal = ({ root = '#portal-root', children }: PropsWithChildren<Props>) => {
  const rootElement = document.querySelector(root);

  if (rootElement) {
    return ReactDOM.createPortal(children, rootElement);
  }
  return null;
};

export default memo(Modal);
