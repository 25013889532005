import { RefObject, useEffect, useRef } from 'react';

const options = {
  root: null,
  rootMargin: '0px',
  threshold: [1],
};

const useElementInView = (
  ref: RefObject<Element | null>,
  callBack: (isInView: boolean, fromInViewHook: boolean) => void,
) => {
  const observer = useRef<IntersectionObserver | undefined>(undefined);
  const isInview = useRef<boolean>(false);

  useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      const [{ isIntersecting }] = entries;

      if (isIntersecting && !isInview.current) {
        callBack(true, true);
      }
      if (!isIntersecting && isInview.current) {
        callBack(false, true);
      }
      isInview.current = isIntersecting;
    };
    if (ref.current && !observer.current) {
      observer.current = new IntersectionObserver(handleIntersect, options);
      observer.current.observe(ref.current);
    }
  }, [callBack, isInview, ref]);

  return null;
};

export default useElementInView;
