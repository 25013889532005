import {
  ClickCollection,
  Collections,
  ContentCollection,
  DataLayerServiceEvent,
  EventInfoCollection,
  ListProduct,
  PageCollection,
  ScenarioName,
} from '@mindshift/datalayer';
import { Component, ComponentType, createContext, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { Metric } from 'web-vitals/src/types';

import Product from '../../service/Product';
import ProductVariant from '../../service/ProductVariant';

export type ITRACKING = {
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
};

type TrackingProviderProps = {
  gtmId: string;
  debugTracking: boolean;
  cookieConsentGtmAttributes?: string;
  uuid?: string;
  id: string;
};

export type TrackingContextProps = {
  trackEventUniversalAnalytics: (data: ITRACKING) => void;
  trackEventDataLayerService: (
    eventCollections: Collections,
    content?: ContentCollection,
    eventInfo?: EventInfoCollection,
    products?: AProductData[],
    click?: ClickCollection,
    page?: PageCollection,
  ) => void;
  trackAddToCartEvent: (productVariant: Product | ProductVariant) => void;
};

export const TrackingContext = createContext<TrackingContextProps>({
  trackEventUniversalAnalytics: (data: ITRACKING) => {},
  trackEventDataLayerService: (
    eventCollections: Collections,
    content?: ContentCollection,
    eventInfo?: EventInfoCollection,
    products?: AProductData[],
    click?: ClickCollection,
    page?: PageCollection,
  ) => {},
  trackAddToCartEvent: (productVariant: Product | ProductVariant) => {},
});

const DATA_LAYER_SERVICE_MANIFEST = 'manifest.json';
class TrackingProvider extends Component<PropsWithChildren<TrackingProviderProps>, {}> {
  contextData: TrackingContextProps;

  initialized = false;
  isDataLayerServiceInitialized = false;
  isDataLayerServiceEnabled = true;

  eventQueueUniversalAnalytics: ITRACKING[] = [];
  eventQueueDataLayerService: {
    eventCollections: Collections;
    content?: ContentCollection;
    eventInfo?: EventInfoCollection;
    products?: AProductData[];
    click?: ClickCollection;
    page?: PageCollection;
  }[] = [];

  constructor(props: TrackingProviderProps) {
    super(props);
    this.contextData = {
      trackEventUniversalAnalytics: this.trackEventUniversalAnalytics,
      trackEventDataLayerService: this.trackEventDataLayerService,
      trackAddToCartEvent: this.trackAddToCartEvent,
    };
  }

  shouldComponentUpdate(prevProps: TrackingProviderProps) {
    return (
      (this.props.gtmId !== prevProps.gtmId && !this.initialized) ||
      (!this.isDataLayerServiceInitialized &&
        !window.dataLayerService?.initialized &&
        this.isDataLayerServiceEnabled)
    );
  }

  componentDidUpdate(nextProps: TrackingProviderProps) {
    if (this.props.gtmId && this.props.gtmId !== nextProps.gtmId && !this.initialized) {
      this.injectGtmScriptInHead();
    } else if (
      !this.isDataLayerServiceInitialized &&
      !window.dataLayerService?.initialized &&
      this.isDataLayerServiceEnabled
    ) {
      this.injectDataLayerServiceScript();
    }
  }

  getScriptWithInjectedGTMID = (): string | undefined => {
    if (this.props.gtmId) {
      return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${this.props.gtmId}');`;
    }
    return undefined;
  };

  injectDataLayerServiceScript = () => {
    if (!this.isDataLayerServiceEnabled) return;

    const { VITE_DATA_LAYER_SERVICE_BASE_URL } = import.meta.env;

    fetch(VITE_DATA_LAYER_SERVICE_BASE_URL + DATA_LAYER_SERVICE_MANIFEST)
      .then(async (data) => {
        if (!data.ok) {
          throw new Error('Failed to load DataLayerService manifest');
        }
        const result: { files: string; resources: string } = await data.json();
        const filename = `${result.files.split('/').pop()}`;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = VITE_DATA_LAYER_SERVICE_BASE_URL + filename;
        document.body.appendChild(script);
        setTimeout(this.trackStoredEventsUniversalAnalytics, 3000);
      })
      .catch((error) => {
        console.error(error);
        this.isDataLayerServiceEnabled = false;
      });
    this.isDataLayerServiceInitialized = true;
  };

  injectGtmScriptInHead = async () => {
    const gtmScript = this.getScriptWithInjectedGTMID();
    if (!gtmScript) {
      return;
    }
    const script = document.createElement('script');

    if (this.props.cookieConsentGtmAttributes) {
      const attributes = this.props.cookieConsentGtmAttributes.split(' ');

      attributes.forEach((attribute) => {
        const [name, value] = attribute.split('=');
        script.setAttribute(name, value.replace(/['"]+/g, ''));
      });
    }

    const scriptText = document.createTextNode(gtmScript);
    script.appendChild(scriptText);
    document.head.appendChild(script);
    this.initialized = true;
    this.trackStoredEventsUniversalAnalytics();
  };

  trackStoredEventsUniversalAnalytics = (): void => {
    const eventQueueLength = this.eventQueueUniversalAnalytics.length;
    if (eventQueueLength > 0) {
      for (let index = 0; index < eventQueueLength; index++) {
        this.trackEventUniversalAnalytics(this.eventQueueUniversalAnalytics[index]);
      }
      this.eventQueueUniversalAnalytics = [];
    }
  };

  trackStoredEventsDataLayerService = (): void => {
    const eventQueueLength = this.eventQueueDataLayerService.length;
    if (eventQueueLength > 0) {
      for (let index = 0; index < eventQueueLength; index++) {
        const { eventCollections, eventInfo, content, products, click, page } =
          this.eventQueueDataLayerService[index];
        this.trackEventDataLayerService(
          eventCollections,
          content,
          eventInfo,
          products,
          click,
          page,
        );
      }
      this.eventQueueDataLayerService = [];
    }
  };

  trackEventUniversalAnalytics = (trackingData: ITRACKING) => {
    // track custom events if GTM is loaded by us or by datalayerService
    if ((this.props.gtmId && this.initialized) || window.dataLayer) {
      const _data = {
        event: 'ga_event',
        eventCategory: trackingData.eventCategory ?? 'flyer_webview',
        eventAction: trackingData.eventAction ?? '',
        eventLabel: trackingData.eventLabel ?? '',
      };
      if (this.props.debugTracking) {
        console.log('trackEventUniversalAnalytics', _data);
      }
      window.dataLayer?.push(_data);
    } else {
      this.eventQueueUniversalAnalytics.push(trackingData);
    }
  };

  trackEventDataLayerService = (
    eventCollections: Collections,
    content?: ContentCollection,
    eventInfo?: EventInfoCollection,
    products?: AProductData[],
    click?: ClickCollection,
    page?: PageCollection,
  ) => {
    if (!this.isDataLayerServiceEnabled) return;

    const { uuid, id } = this.props;

    const dataLayerServiceEvent: DataLayerServiceEvent = {
      scenarioName: eventCollections.eventInfo?.eventAction as ScenarioName,
      collections: {
        ...eventCollections,
        eventInfo: {
          ...eventCollections.eventInfo,
          ...eventInfo,
        },
        content: {
          ...eventCollections.content,
          ...content,
        },
        ...(products?.length && {
          products: {
            list: this.mapAProductDataToListProduct(products, id, uuid),
          },
        }),
        ...(click && { click }),
        page: {
          ...page,
          pageTitle: uuid,
        },
      },
    };

    if (this.props.debugTracking) {
      console.log('trackEventDataLayerService', dataLayerServiceEvent);
      //set cookie for DataLayerService verbose-mode in mindshift-context
      //cookieName: "dataLayerService" cookieValue: "true"
    }

    window.dataLayerService = window.dataLayerService || [];
    window.dataLayerService.push(dataLayerServiceEvent);
  };

  mapAProductDataToListProduct = (
    products: AProductData[],
    leafletId: string,
    uuid?: string,
  ): ListProduct[] =>
    products.map((product) => ({
      id: product.productId,
      brand: product.brand,
      categoryPrimary: product.categoryPrimary,
      currency: product.currencyText,
      name: product.title,
      price: parseFloat(product.price),
      listId: leafletId,
      listName: uuid,
    }));

  trackAddToCartEvent = (productVariant: Product | ProductVariant) => {
    const productId = productVariant.productId.toString();
    const erpNumber = productVariant.erpNumber.toString();
    const variantId = erpNumber !== 'default' ? erpNumber : productId;

    const _data = {
      event: 'EECaddToCart',
      ecommerce: {
        currencyCode: productVariant.currencySymbol,
        add: {
          products: [
            {
              name: productVariant.title, //Product name
              id: productId, //Product ID
              price: productVariant.price, //Product price
              brand: productVariant.brandName, //Product brand
              category: '', //Product category
              variant: variantId, //variant, eg. 'red'
              quantity: 1, //quantity
              dimension1: '{}', //custom dimension
            },
          ],
        },
      },
    };

    if (this.props.debugTracking) {
      console.log('trackAddToCartEvent', _data);
    }

    window.dataLayer?.push(_data);
  };

  render() {
    return (
      <TrackingContext.Provider value={this.contextData}>
        {this.props.children}
      </TrackingContext.Provider>
    );
  }
}
const mapStateToProps = (state: AppState) => ({
  gtmId: state.appData.gtmId,
  uuid: state.routing.params.uuid,
  debugTracking: state.settings.debugTracking,
  cookieConsentGtmAttributes: state.appData.cookieConsent?.gtmAttributes,
  id: state.appData.id,
});
export default connect(mapStateToProps, {})(TrackingProvider);

export function withTracking<OriginalProps extends object>(
  UnwrappedComponent: ComponentType<OriginalProps & TrackingContextProps>,
) {
  type NewProps = Omit<OriginalProps, keyof TrackingContextProps> & OwnProps;
  return class WithTracking extends Component<NewProps, {}> {
    static WrappedComponent = UnwrappedComponent;

    render() {
      const Comp = UnwrappedComponent as any;

      return (
        <TrackingContext.Consumer>
          {({ trackEventUniversalAnalytics, trackEventDataLayerService }) => (
            <Comp
              {...this.props}
              trackEventUniversalAnalytics={trackEventUniversalAnalytics}
              trackEventDataLayerService={trackEventDataLayerService}
            />
          )}
        </TrackingContext.Consumer>
      );
    }
  };
}

export function sendWebVitalsToAnalytics({ id, name, value }: Metric) {
  if (typeof window.ga === 'function') {
    ga('send', 'event', {
      eventCategory: 'Web Vitals',
      eventAction: name,
      eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      eventLabel: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate
    });
  }
}
