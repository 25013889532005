import { all, put, select, takeEvery } from 'redux-saga/effects';

import { addProduct, deleteProduct, WishListChangedSagaAction } from './actions';
import { WISHLIST_CHANGED } from './constants';

const getCurrentWishList = (state) => state.wishlistProducts;

export function* handleWishListChange() {
  yield takeEvery(WISHLIST_CHANGED, handleWishListProducts);
}

export function* handleWishListProducts({ product }: WishListChangedSagaAction) {
  const currentWishList = yield select(getCurrentWishList);
  const index = currentWishList.findIndex((wishlistProduct) => wishlistProduct[product.key]);
  const mainProduct = currentWishList[index] || {};
  const mainProductsVariants = mainProduct[product.key] || [];

  if (mainProductsVariants.length > 0 && product.variant === 'default') {
    yield all(
      mainProductsVariants.map((item) => put(deleteProduct({ ...product, variant: item }))),
    );
    return;
  }

  if (mainProductsVariants.indexOf(product.variant) > -1) {
    yield put(deleteProduct(product));
    return;
  }

  yield put(addProduct(product));
}
