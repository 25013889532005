import ButtonC, { BUTTON_HOVERSTYLE } from 'components/ButtonC';
import { APPEARANCE, MINDSHIFT_LINKS, VIEW } from 'helper/js/constants';
import { isUserAuthenticated } from 'helper/js/helper';
import { useFlippyNavigate, useFlippyView } from 'helper/js/hooks/router';
import { useLanguage } from 'providers/Language';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import TRACKING_EVENT_COLLECTIONS from 'providers/Tracking/trackingEventCollections';
import { useCallback, useContext, useEffect, useState } from 'react';

export default function Account() {
  const flippyNavigate = useFlippyNavigate();
  const { t } = useLanguage();
  const { trackEventUniversalAnalytics, trackEventDataLayerService } = useContext(TrackingContext);
  const flippyView = useFlippyView();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isMenuOpen = flippyView === VIEW.ACCOUNT;

  useEffect(() => {
    if (isUserAuthenticated()) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleClick = useCallback(() => {
    trackEventUniversalAnalytics(TRACKING_EVENTS.ACCOUNT_NAVIGATION_BUTTON_CLICKED);
    trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.ACCOUNT_BUTTON_CLICKED, {
      contentType: 'navigation',
    });
    if (!isAuthenticated) {
      window.location.href = MINDSHIFT_LINKS.LOGIN + encodeURI(window.location.href);
    } else {
      flippyNavigate({
        view: !isMenuOpen ? VIEW.ACCOUNT : VIEW.FLYER,
        contentId: undefined,
      });
    }
  }, [
    flippyNavigate,
    isAuthenticated,
    isMenuOpen,
    trackEventDataLayerService,
    trackEventUniversalAnalytics,
  ]);

  return (
    <ButtonC
      hoverStyle={BUTTON_HOVERSTYLE.ICON}
      inColumn
      icon={'user'}
      label={t('myAccount')}
      onClick={handleClick}
      indicator={isAuthenticated ? 'hook' : `cross--${import.meta.env.VITE_THEME}`}
      indicatorColor={isAuthenticated ? APPEARANCE.SUCCESS : APPEARANCE.PRIMARY}
      transformLabelToUppercase={false}
      className="button--hide-label--sm"
    />
  );
}
