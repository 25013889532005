import 'intersection-observer';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { ENVIRONMENT } from './helper/js/constants';
import { sendWebVitalsToAnalytics } from './providers/Tracking';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store';

const target = document.querySelector('#root')!;
const canHover = !matchMedia('(pointer:coarse)').matches;
if (canHover) {
  document.body.classList.add('canHover');
}
document.body.classList.add(`theme--${import.meta.env.VITE_THEME}`);

ReactDOM.createRoot(target as HTMLElement).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);

if (import.meta.env.NODE_ENV === ENVIRONMENT.PRODUCTION) {
  reportWebVitals(sendWebVitalsToAnalytics);
}
