import './style.scss';

import classNames from 'classnames';
import MediaLink from 'components/MediaLink';
import Video from 'components/Video';
import { memo, useCallback, useState } from 'react';

type VideoInlineProps = {
  video: AInlineVideoData;
  width: number;
  relation: number;
};

const VideoInline = ({ video, width, relation }: VideoInlineProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const handleLoadIframe = useCallback(() => {
    setIsLoaded(true);
  }, []);

  if (!video) {
    return null;
  }

  const { videoProvider, embedFragment, poster } = video;
  const videoInlinePreviewClasses = classNames({
    video_inline__preview: true,
    [`video_inline__preview--${videoProvider}`]: true,
  });

  return (
    <div className="video_inline">
      {isLoaded ? (
        <Video videoProvider={videoProvider} embedFragment={embedFragment} inline />
      ) : (
        <button
          className={videoInlinePreviewClasses}
          onClick={handleLoadIframe}
          aria-label={'video'}>
          <img src={poster} alt="" />
          <MediaLink relation={relation} type="video" groupWidth={width} icon="play" />
        </button>
      )}
    </div>
  );
};
export default memo(VideoInline);
