import initialState from 'initialState';
import { Reducer } from 'redux';

import { VIEW } from '../../helper/js/constants';
import { RouterActions } from './actions';
import { ROUTER_UPDATE_PARAMS } from './constants';

const router: Reducer<ARouting, RouterActions> = (
  state = initialState.routing,
  action: RouterActions,
) => {
  switch (action.type) {
    case ROUTER_UPDATE_PARAMS:
      const isViewWithContentId = [VIEW.PRODUCTDETAILS, VIEW.VIDEO, VIEW.TEXT].includes(
        action.routing.params.view as VIEW,
      );
      return {
        ...state,
        params: {
          ...state.params,
          ...(action.routing.params || {}),
          contentId: isViewWithContentId ? action.routing.params.contentId : undefined,
          page: '',
        },
        path: action.routing.path || state.path,
        searchQuery: {
          ...state.searchQuery,
          ...(action.routing.searchQuery || {}),
        },
      };
    default:
      return state;
  }
};
export default router;
