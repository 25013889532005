import { createContext } from 'react';

export type ScrollToContextProps = {
  scrollTo: (
    { offsetLeft, offsetTop }: { offsetLeft: number; offsetTop: number },
    delta?: boolean,
  ) => Promise<void> | void;
};

export const ScrollToContext = createContext<ScrollToContextProps>({
  scrollTo: (
    { offsetLeft, offsetTop }: { offsetLeft: number; offsetTop: number },
    delta = false,
  ) => {
    console.warn(offsetLeft, offsetTop, delta);
  },
});
