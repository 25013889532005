import { Component, createContext, PropsWithChildren, RefObject } from 'react';

type RefType = 'sheet' | 'wishListButton';
export const ReferenceContext = createContext<ReferenceProviderState>({
  saveToRef: (ref: RefObject<any>, key: RefType) => {},
  getRef: (key: RefType) => ({
    current: null,
  }),
});
type ReferenceProviderState = {
  saveToRef: (ref: RefObject<any>, key: RefType) => void;
  getRef: (key: RefType) => RefObject<any>;
};

export default class ReferenceProvider extends Component<
  PropsWithChildren<{}>,
  ReferenceProviderState
> {
  domRefs: any = {
    sheet: {
      current: null,
    },
    wishListButton: {
      current: null,
    },
  };

  saveToRef = (ref: RefObject<any>, key: RefType) => {
    this.domRefs = { ...this.domRefs, [key]: ref };
  };

  getRef = (key: RefType) => this.domRefs[key] || { current: null };

  state = {
    // eslint-disable-next-line react/no-unused-state
    saveToRef: this.saveToRef,
    // eslint-disable-next-line react/no-unused-state
    getRef: this.getRef,
  };

  render() {
    return (
      <ReferenceContext.Provider value={this.state}>
        {this.props.children}
      </ReferenceContext.Provider>
    );
  }
}
