import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip';
import { memo, RefObject } from 'react';
import { useSelector } from 'react-redux';

type OwnProps = {
  forceTooltipTouch: boolean;
  text: string;
  element: RefObject<HTMLButtonElement | HTMLAnchorElement>;
  showOnMount: boolean;
};

const TooltipContainer = ({ forceTooltipTouch, text, element, showOnMount }: OwnProps) => {
  const showTooltip = useSelector(
    (state: AppState) => forceTooltipTouch || !state.browser.isTouchDevice,
  );
  const navigate = useSelector((state: AppState) => state.sheet.navigate);

  if (!showTooltip || navigate) {
    return null;
  }
  return (
    <Modal>
      <Tooltip text={text} element={element} showOnMount={showOnMount} />
    </Modal>
  );
};

export default memo(TooltipContainer);
