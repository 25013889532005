import SheetPages from 'components/SheetPages';
import { compareReactNodes } from 'helper/js/helper';
import { Component, ReactNode } from 'react';

type SheetListProps = {
  currentPages: ReactNode[] | ReactNode;
  nextPages: ReactNode[] | ReactNode;
  prePages: ReactNode[] | ReactNode;
  classNames: string;
};
export default class SheetList extends Component<SheetListProps, {}> {
  static defaultProps = {
    classNames: '',
  };

  shouldComponentUpdate(nextProps: SheetListProps) {
    return (
      nextProps.classNames !== this.props.classNames ||
      compareReactNodes(nextProps.currentPages, this.props.currentPages) ||
      compareReactNodes(nextProps.prePages, this.props.prePages) ||
      compareReactNodes(nextProps.nextPages, this.props.nextPages)
    );
  }

  render() {
    const { prePages, currentPages, nextPages } = this.props;
    return (
      <ul className={this.props.classNames}>
        <SheetPages prePages={prePages} currentPages={currentPages} nextPages={nextPages} />
      </ul>
    );
  }
}
