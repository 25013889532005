import { IPoint, IRect } from './Point';

class Size {
  static map(k: IRect, f: (arg: number) => number) {
    return {
      width: f(k.width),
      height: f(k.height),
    };
  }

  static scale(k: IRect, s: number) {
    return this.map(k, (v: number) => v * s);
  }

  static diff(m: IRect, n: IRect): IRect {
    return {
      width: m.width - n.width,
      height: m.height - n.height,
    };
  }

  static isEqual(m: IRect, n: IRect): boolean {
    return m.width === n.width && m.height === n.height;
  }

  static toPoint(s: IRect): IPoint {
    return {
      x: s.width,
      y: s.height,
    };
  }
}
export default Size;
