import './style.scss';

import classNames from 'classnames';
import IconC from 'components/IconC';
import Img from 'components/Img';
import { Language } from 'providers/Language';
import { Component } from 'react';

interface RecommendedItemProps {
  hidden: boolean;
  className: string;
  asButton: boolean;
  onClick: (uuid?: string, url?: string) => void;
  thumbnailUrl: string;
  url?: string;
  name: string;
  title: string;
  slug?: string;
}

class RecommendedItem extends Component<RecommendedItemProps, {}> {
  static defaultProps = {
    hidden: false,
    title: '',
    className: '',
    asButton: false,
  };

  handleClick = () => {
    if (this.props.slug || this.props.url) {
      this.props.onClick(this.props.slug, this.props.url);
      return;
    }
    this.props.onClick();
  };

  getRecommendedItemButton = (classes = '') => {
    const { thumbnailUrl, name, title } = this.props;
    const buttonClasses = classNames({
      [classes]: true,
      'recommended__item-button': true,
    });
    return (
      <Language>
        {(t) => (
          <button type="button" className={buttonClasses} onClick={this.handleClick}>
            <h5 className="recommended__item-headline">{name}</h5>
            <p className="recommended__item-subheadline">{title}</p>
            <div className="recommended__item-image">
              <Img src={thumbnailUrl} minimalError />
            </div>
            <div className="recommended__item-detail">
              <IconC icon={`flyer--${import.meta.env.VITE_THEME}`} />
              <p className="recommended__item-detail-text">{t('openFlyer')}</p>
            </div>
          </button>
        )}
      </Language>
    );
  };

  render() {
    const { hidden, className, asButton } = this.props;
    const recommendedClasses = classNames({
      recommended__item: true,
      [className]: className,
    });
    if (asButton) {
      return this.getRecommendedItemButton(recommendedClasses);
    }
    return hidden ? (
      <li className="recommended__item recommended__item--hidden" />
    ) : (
      <li className={recommendedClasses}>{this.getRecommendedItemButton()}</li>
    );
  }
}
export default RecommendedItem;
