import './NotificationItem.scss';

import classNames from 'classnames';
import Alert from 'components/nucleus/Alert';
import { memo } from 'react';

type Props = { title: string; message: string; type?: APPEARANCE_TYPE };

const getTypeForAlert = (type: APPEARANCE_TYPE = 'primary'): APPEARANCE_TYPE => {
  const ALERT_MAP = {
    alert: 'danger',
    hint: 'primary',
  };
  return ALERT_MAP[type] || ALERT_MAP.hint;
};

const NotificationItem = ({ title, message, type }: Props) => {
  const notificationItemClasses = classNames({
    notification__item: true,
  });

  const mappedType = getTypeForAlert(type);
  return (
    <li className={notificationItemClasses} data-testid="notification__item">
      <Alert theme={mappedType} headline={title} content={message} isClosable={false} />
    </li>
  );
};

export default memo(NotificationItem);
