import {
  FlipToPageAction,
  NavigateAbsoluteAction,
  sheetNavigateBeforeStart,
  sheetNavigateEnd,
  sheetNavigateStart,
  updatedVisiblePagesAction,
  UpdateVisiblePagesAction,
  updateVisiblePagesAction,
  updateZoomLevel,
} from 'containers/SheetContainer/actions';
import { ZOOM } from 'helper/js/constants';
import { getVisiblePagesAmount, validateNavigation } from 'helper/js/helper';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';

import {
  FLYER_FLIP_DURATION,
  SHEET_NAVIGATE,
  SHEET_NAVIGATE_TO_PAGE,
  SHEET_PAGES_VISIBLE_UPDATE,
  TRANSITION_TYPES,
} from './constants';

export const thestate = (state: AppState) => state;
export const getPrevVisiblePages = (state: AppState) => state.sheet.currentVisible;
export const getRouterParams = (state: AppState) => state.routing.params;
export const getPagesLength = (state: AppState) => state.appData.pages.length;
export const isWideScreenState = (state: AppState) => state.browser.isWideScreen;
export const isMdState = (state: AppState) => state.browser.isMdMin;
export const getCurrentZoom = (state: AppState) => state.sheet.zoom;
export const isNavigating = (state: AppState) => state.sheet.navigate;
export const getCurrentVisiblePages = (state: AppState) => state.sheet.flipPages;
export function* updatePages() {
  yield takeEvery(SHEET_PAGES_VISIBLE_UPDATE, updateVisiblePages);
  yield takeEvery(SHEET_NAVIGATE, flipSheet);
  yield takeEvery(SHEET_NAVIGATE_TO_PAGE, flipSheet);
}
export function getAvailableFlipPages(
  currentPagePlusVisiblePages: number,
  pagesLength: number,
  newVisiblePages: number,
): number {
  return currentPagePlusVisiblePages >= pagesLength ? 2 : newVisiblePages;
}
const validateNewPage = (page: number, pagesLength: number): number => {
  if (page < 1) {
    return 1;
  }
  if (page > pagesLength) {
    return validateNewPage(page - 1, pagesLength);
  }

  return page;
};

export function getAnimationType(
  oldPage: number,
  newPage: number,
  direction: string,
  pagesLength: number,
) {
  if (oldPage === pagesLength && newPage === 1) {
    return TRANSITION_TYPES.BACK_COVER_TO_FRONT_COVER;
  }
  if (oldPage === 1 && newPage === pagesLength) {
    return TRANSITION_TYPES.FRONT_COVER_TO_BACK_COVER;
  }
  if (direction === TRANSITION_TYPES.FORWARD) {
    if (oldPage === 1) {
      return TRANSITION_TYPES.FROM_FRONT_COVER;
    }
    if (newPage === pagesLength) {
      return TRANSITION_TYPES.TO_BACK_COVER;
    }
  } else {
    if (newPage === 1) {
      return TRANSITION_TYPES.TO_FRONT_COVER;
    }
    if (oldPage === pagesLength) {
      return TRANSITION_TYPES.FROM_BACK_COVER;
    }
  }
  return direction;
}
export function* updateVisiblePages(data: UpdateVisiblePagesAction) {
  const routerParams = yield select(getRouterParams);
  const pagesLength = yield select(getPagesLength);
  const prevVisiblePages = yield select(getPrevVisiblePages);
  const isWideScreen = yield select(isWideScreenState);
  const currentPage = parseInt(data.page ?? routerParams.page, 10);
  const nextVisiblePages = getVisiblePagesAmount(
    data.page ?? currentPage,
    pagesLength,
    isWideScreen,
  );
  if (!isWideScreen) {
    yield put(updatedVisiblePagesAction(1, 1));
  } else {
    yield put(
      updatedVisiblePagesAction(
        nextVisiblePages,
        getAvailableFlipPages(
          data.page ?? currentPage + prevVisiblePages,
          pagesLength,
          nextVisiblePages,
        ),
      ),
    );
  }
}

const isSheetNavigateAbsoluteAction = (
  action: FlipToPageAction | NavigateAbsoluteAction,
): action is NavigateAbsoluteAction => action.type === SHEET_NAVIGATE_TO_PAGE;

export function* flipSheet(data: FlipToPageAction) {
  const isSheetNavigating: boolean = yield select(isNavigating);

  if (isSheetNavigating) {
    return;
  }

  const { currentPage, targetPage } = data;
  const pagesLength: number = yield select(getPagesLength);
  const isWideScreen: boolean = yield select(isWideScreenState);
  const currentZoom: number = yield select(getCurrentZoom);

  const direction = getTransitionDirection(currentPage, targetPage);
  const validatedTargetPage = validateNewPage(targetPage, pagesLength);

  if (
    targetPage === currentPage ||
    (currentPage && validateNavigation(currentPage, direction, pagesLength, isWideScreen))
  ) {
    return;
  }

  const delayTime = isWideScreen ? FLYER_FLIP_DURATION : 0;
  const animationType =
    isWideScreen && currentPage
      ? getAnimationType(currentPage, validatedTargetPage, direction, pagesLength)
      : '';

  yield put(updateZoomLevel(ZOOM.MIN));
  if (currentZoom > ZOOM.MIN) {
    yield delay(150 * currentZoom);
  }

  if (isSheetNavigateAbsoluteAction(data)) {
    yield put(sheetNavigateBeforeStart(validatedTargetPage));
  }

  if (isWideScreen) {
    yield delay(0);
  }

  yield put(sheetNavigateStart(animationType));
  yield call(updateVisiblePages, updateVisiblePagesAction(validatedTargetPage));
  yield delay(30);

  if (isWideScreen && currentPage) {
    // see also: usePageFlipAnimation
    yield delay(delayTime);
  }

  yield put(sheetNavigateEnd());
}

function getTransitionDirection(currentPage: number | undefined, targetPage: number): string {
  return !currentPage || currentPage < targetPage
    ? TRANSITION_TYPES.FORWARD
    : TRANSITION_TYPES.BACKWARD;
}
