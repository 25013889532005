import Bar from 'components/Bar';
import ButtonC, { BUTTON_HOVERSTYLE, BUTTON_SIZE } from 'components/ButtonC';
import Loading from 'components/Loading';
import { VIEW } from 'helper/js/constants';
import { LanguageContextProps, withLanguage } from 'providers/Language';
import { TrackingContextProps, withTracking } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import TRACKING_EVENT_COLLECTIONS from 'providers/Tracking/trackingEventCollections';
import { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

const RecommendedProductsContainer = lazy(() => import('containers/RecommendedProductsContainer'));
const Topics = lazy(() => import('components/Topics'));
const OverviewContainer = lazy(() => import('containers/OverviewContainer'));

type BarContainerState = {
  close: boolean;
};

type BarContainerProps = {
  showOverviewContent: boolean;
  showJumpmarkContent: boolean;
  showRecommendedProducts: boolean;
  showView: string;
  updateUrl: (view: string) => void;
  openProductDetailsInSidebar: (roductId: number) => void;
  handlePageNumberChange: (pageNumber: number) => void;
  currentView: string;
  currentPage: number;
  scrollOffset: number;
};

type BarContainerMergedProps = BarContainerProps & LanguageContextProps & TrackingContextProps;

class BarContainer extends Component<BarContainerMergedProps, BarContainerState> {
  constructor(props: BarContainerMergedProps) {
    super(props);
    this.state = {
      close: false,
    };
  }

  shouldComponentUpdate(nextProps: BarContainerProps) {
    return (
      nextProps.currentView !== this.props.currentView ||
      nextProps.currentPage !== this.props.currentPage ||
      nextProps.showOverviewContent !== this.props.showOverviewContent ||
      nextProps.showJumpmarkContent !== this.props.showJumpmarkContent ||
      nextProps.showView !== this.props.showView ||
      nextProps.showRecommendedProducts !== this.props.showRecommendedProducts ||
      nextProps.scrollOffset !== this.props.scrollOffset
    );
  }

  componentDidMount() {
    if (this.props.showView === this.props.currentView) {
      this.trackToggleBar(true);
    }
  }

  componentDidUpdate(prevProps: BarContainerProps) {
    if (this.props.showView === prevProps.currentView) {
      this.trackToggleBar(false);
    }

    if (this.props.showView === this.props.currentView) {
      this.trackToggleBar(true);
    }
  }

  trackToggleBar = (open: boolean) => {
    if (!open) {
      this.props.trackEventUniversalAnalytics({
        ...TRACKING_EVENTS.BAR_CLOSED,
        eventLabel: this.props.showView,
      });
    }
    if (open) {
      this.props.trackEventUniversalAnalytics({
        ...TRACKING_EVENTS.BAR_OPENED,
        eventLabel: this.props.showView,
      });
      this.props.trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.BAR_OPENED);
    }
  };

  handleBarClose = () => {
    this.props.updateUrl('flyer');
  };

  openProductDetailsInSidebar = (productId: number) => {
    this.props.openProductDetailsInSidebar(productId);
  };

  getHeader = () => {
    if (this.props.showRecommendedProducts) {
      const headline = this.props.t('recommendedProductsHeadline');
      return (
        <>
          {headline && <h3>{headline}</h3>}
          <ButtonC
            hoverStyle={BUTTON_HOVERSTYLE.ICON}
            size={BUTTON_SIZE.S}
            icon={`cross--${import.meta.env.VITE_THEME}`}
            onClick={this.handleBarClose}
          />
        </>
      );
    }
    return null;
  };

  getContent = () => {
    if (this.props.showJumpmarkContent) {
      return (
        <Suspense fallback={<Loading />}>
          <Topics />
        </Suspense>
      );
    }
    if (this.props.showOverviewContent) {
      return (
        <Suspense fallback={<Loading />}>
          <OverviewContainer
            handlePageNumberChange={this.props.handlePageNumberChange}
            currentPage={this.props.currentPage}
          />
        </Suspense>
      );
    }
    if (this.props.showRecommendedProducts) {
      return (
        <Suspense fallback={<Loading />}>
          <RecommendedProductsContainer
            openProductDetailsInSidebar={this.openProductDetailsInSidebar}
          />
        </Suspense>
      );
    }
    return null;
  };

  getTransition = () => {
    if (this.props.showView === VIEW.OVERVIEW) {
      return {
        initial: { height: 0, originX: 1, originY: 1 },
        animate: { height: 158, originX: 1, originY: 1 },
        exit: { height: 0, originX: 1, originY: 1 },
        transition: 0.35,
      };
    }
    if (this.props.showView === VIEW.RECOMMENDEDPRODUCTS) {
      return {
        initial: { scaleY: 0, originY: 1 },
        animate: { scaleY: 1, originY: 1 },
        exit: { scaleY: 0, originY: 1 },
        transition: 0.5,
      };
    }
    return {};
  };

  handleScroll = (direction: 'left' | 'right') => {
    this.props.trackEventUniversalAnalytics({
      ...TRACKING_EVENTS.BAR_SCROLLED,
      eventLabel: direction,
    });
  };

  render() {
    return (
      <Bar
        header={this.getHeader()}
        className={`bar--${this.props.showView}`}
        scrollOffset={this.props.scrollOffset}
        currentPage={this.props.currentPage}
        onScroll={this.handleScroll}
        showProgressBar={this.props.showRecommendedProducts}
        transition={this.getTransition()}>
        {this.getContent()}
      </Bar>
    );
  }
}

const mapStateToProps = (state: AppState, props: BarContainerProps) => {
  const isCurrentView = props.showView === props.currentView;
  const showRecommendedProducts =
    isCurrentView &&
    props.showView === VIEW.RECOMMENDEDPRODUCTS &&
    state.recommendationProducts.count;
  const showOverviewContent =
    isCurrentView && !state.browser.isSmMin && props.showView === VIEW.OVERVIEW;

  let offset = 0;

  if (showRecommendedProducts) {
    offset = state.recommendationProducts.count;
  } else if (showOverviewContent) {
    offset = state.appData.pages.length;
  }

  return {
    showOverviewContent,
    showJumpmarkContent:
      isCurrentView && props.showView === VIEW.JUMPMARKS && state.browser.isMdMin,
    showRecommendedProducts,
    scrollOffset: offset,
  };
};

export default compose<any>(connect(mapStateToProps), withTracking, withLanguage)(BarContainer);
