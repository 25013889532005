export const fallBackUrls = {
  lidl: {
    imprintUrl: 'https://info.lidl/en/imprint',
    privacyUrl: 'https://info.lidl/en/privacy-protection',
    quitUrl: 'https://info.lidl/en',
  },
  kaufland: {
    imprintUrl: 'https://www.kaufland.com/legal-notice.html',
    privacyUrl: 'https://www.kaufland.com/privacy-policy.html',
    quitUrl: 'https://www.kaufland.com',
  },
};
