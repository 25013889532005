import './style.scss';

import classNames from 'classnames';
import ButtonC, { BUTTON_APPEARANCE } from 'components/ButtonC';
import Page from 'components/Page';
import RecommendedPreview from 'components/RecommendedPreview';
import { LanguageContext } from 'providers/Language';
import { memo, useContext } from 'react';

type PagePreviewProps = {
  className?: string;
  recommendedFlyer: ARecommendedFlyerItem;
  onFlyerClick?: () => void;
  onButtonClick?: () => void;
  isLastPage?: boolean;
  visible?: boolean;
};
const PagePreview = ({
  recommendedFlyer,
  onFlyerClick = () => {},
  onButtonClick = () => {},
  className = '',
  isLastPage = false,
  visible = false,
}: PagePreviewProps) => {
  const { t } = useContext(LanguageContext);

  const pageClasses = classNames({
    [className]: true,
    'page--preview': true,
  });

  return (
    <Page className={pageClasses} isVisible={visible} isLastPage={isLastPage} type="preview">
      <div className="preview">
        <h2 className="preview_headline">{t('moreFlyersHeading')}</h2>
        <div className="preview__content">
          <RecommendedPreview {...recommendedFlyer} onClick={onFlyerClick} />
          <ButtonC
            fullwidth
            type={BUTTON_APPEARANCE.PRIMARY}
            label={t('moreFlyersTitleButton')}
            onClick={onButtonClick}
          />
        </div>
      </div>
    </Page>
  );
};

export default memo(PagePreview);
