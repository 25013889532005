import localForage from 'localforage';
import { AnyAction, applyMiddleware, compose, createStore, Middleware } from 'redux';
import {
  createMigrate,
  createTransform,
  MigrationManifest,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';

import initialState from './initialState';
import rootReducer from './reducers';
import rootSaga from './sagas';
import persistCrosstab from './service/reduxPersistCrosstabV5';

const localForageStore = localForage.createInstance({
  name: 'store',
});

export const productDataForage = localForage.createInstance({
  name: 'productData',
});

const sagaMiddleware = createSagaMiddleware();

const crashReporterMiddleWare: Middleware = () => (next) => (action: AnyAction) => {
  if (action.type === 'FLYER_DATA_ERROR') {
    const { browser, routing } = store.getState();
    const error: any = new Error(action.payload);
    error.state = JSON.stringify({
      browser,
      routing,
    });
    console.warn(error);
  }
  return next(action);
};

const enhancers = [];
const middleware: Middleware[] = [crashReporterMiddleWare, sagaMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composedEnhancers = composeEnhancers(applyMiddleware(...middleware), ...enhancers);
const migrations: MigrationManifest = {
  3: (state: any) => {
    const newState = { ...state };
    if (state.hasOwnProperty('productData')) {
      return {
        ...newState,
        productData: {
          ids: [],
        },
      };
    }
    return newState;
  },
};

const SetTransform = createTransform(
  (inboundState: any) => {
    if (!inboundState.expires) {
      const newDate = new Date();
      newDate.setMinutes(newDate.getMinutes() + 30);

      return {
        expires: newDate.getTime(),
      };
    }
    return inboundState;
  },
  (outboundState: any) => {
    const expireDate = new Date();

    if (outboundState.expires < expireDate.getTime()) {
      productDataForage.clear();
      return {};
    }
    return outboundState;
  },
  { whitelist: ['productData'] },
);

const AddArTransform = createTransform(
  (inboundState: any = initialState.routing) => {
    if (inboundState.params.ar || inboundState.params.storeId) {
      return {
        ...initialState.routing,
        params: {
          ...initialState.routing.params,
          ar: inboundState.params.ar,
          storeId: inboundState.params.storeId,
        },
      };
    }
    return initialState.routing;
  },
  (outboundState: any) => outboundState,
  { whitelist: ['routing'] },
);

const persistConfig = {
  key: 'root',
  debug: true,
  storage: localForageStore,
  whitelist: ['wishlistProducts', 'productData', 'notifications'],
  blacklist: ['ageRestriction', 'routing'],
  version: 3,
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [SetTransform],
};

export const ageRestrictionConfig = {
  key: 'ageRestriction',
  blacklist: ['age', 'show'],
  storage: localForageStore,
};

export const routingConfig = {
  key: 'routing',
  storage: storageSession,
  debug: true,
  blacklist: ['path', 'searchQuery'],

  transforms: [AddArTransform],
};

export const pReducer = persistReducer(persistConfig, rootReducer());
export const store = createStore(pReducer, composedEnhancers);
export const persistor = persistStore(store, {});
persistCrosstab(store, persistConfig, {
  whitelist: ['wishlistProducts'],
  ageRestriction: ['ageRestriction'],
});

sagaMiddleware.run(rootSaga);
