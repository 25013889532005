import Page from 'components/Page';
import PageDefaultImage from 'components/PageDefaultImage';
import MediaGroupsContainer from 'containers/MediaGroupsContainer';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import { TrackingContext } from 'providers/Tracking/index';
import TRACKING_EVENT_COLLECTIONS from 'providers/Tracking/trackingEventCollections';
import { memo, useContext, useEffect } from 'react';

type PageDefaultProps = {
  image: string;
  zoom: string;
  links?: ALink[];
  className?: string;
  visible?: boolean;
  pageId: string;
  type?: string;
  isLastPage?: boolean;
  number: number;
  promotionId?: string;
  promotionTitle?: string;
};
const PageDefault = ({
  pageId,
  image,
  zoom,
  links = [],
  className = '',
  visible = false,
  type = 'STANDARD',
  isLastPage = false,
  number,
  promotionId,
  promotionTitle,
}: PageDefaultProps) => {
  const isPromotion = visible && type === 'PROMOTION';

  const { trackEventUniversalAnalytics, trackEventDataLayerService } = useContext(TrackingContext);

  useEffect(() => {
    if (isPromotion) {
      trackEventUniversalAnalytics({
        ...TRACKING_EVENTS.FLYER_PROMOTION_SEEN,
        eventLabel: promotionId,
      });
      trackEventDataLayerService(TRACKING_EVENT_COLLECTIONS.FLYER_PROMOTION_SEEN, {
        contentID: promotionId,
        contentLabel: promotionTitle,
      });
    }
  }, [
    isPromotion,
    promotionId,
    promotionTitle,
    trackEventDataLayerService,
    trackEventUniversalAnalytics,
  ]);

  if (!visible && links) {
    //preload animated gifs
    links.forEach((linkItem) => {
      if (linkItem.displayType === 'animated_gif') {
        fetch(linkItem.url, { mode: 'no-cors' });
      }
    });
  }

  return (
    <Page className={className} isLastPage={isLastPage} isVisible={visible} type="default">
      <div className="page__wrapper">
        <PageDefaultImage image={image} zoom={zoom} number={number} visible={visible} />
      </div>
      {visible && links && <MediaGroupsContainer pageId={pageId} />}
    </Page>
  );
};

export default memo(PageDefault);
