export function injectOneTrustScript(domainId: string, locale: string): void {
  const existingScript = document.getElementById('OneTrust');

  if (existingScript) {
    return;
  }

  const consentModeScript = document.createElement('script');
  consentModeScript.id = 'ConsentMode';
  consentModeScript.text = `
  // Define dataLayer and the gtag function. 
  window.dataLayer = window.dataLayer || [];
  window.dataLayerNext = window.dataLayerNext || []; 
  function gtag() {
    dataLayer.push(arguments); 
    dataLayerNext.push(arguments);
  } 
 
  // Default ad_storage to 'denied'. 
  gtag('consent', 'default', { 
    ad_storage: "denied", 
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied", 
    functionality_storage: "denied", 
    personalization_storage: "denied", 
    security_storage: "denied",
    wait_for_update: 500 
  }); 
  `;

  const cookieLawScript = document.createElement('script');
  cookieLawScript.id = 'OneTrust';
  cookieLawScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  cookieLawScript.dataset.documentLanguage = 'true';
  cookieLawScript.dataset.domainScript = domainId;
  cookieLawScript.setAttribute('type', 'text/javascript');
  cookieLawScript.setAttribute('charset', 'UTF-8');

  const optanonWrapperScript = document.createElement('script');
  optanonWrapperScript.id = 'OneTrustOptanonWrapper';
  optanonWrapperScript.setAttribute('type', 'text/javascript');
  optanonWrapperScript.text = `if(OneTrust && typeof OptanonWrapper !== 'function'){ window.OptanonWrapper = function() { OneTrust.changeLanguage("${locale.toLowerCase()}"); }}`;

  if (locale === 'es-ES') {
    cookieLawScript.setAttribute('data-dLayer-name', 'dataLayerNext');
    document.head.appendChild(consentModeScript);
  }

  document.head.appendChild(cookieLawScript);
  document.head.appendChild(optanonWrapperScript);
}

export function injectCookieBotScript(domainId: string): void {
  const existingScript = document.getElementById('Cookiebot');

  if (existingScript) {
    return;
  }
  const script = document.createElement('script');
  script.id = 'Cookiebot';
  script.src = 'https://consent.cookiebot.com/uc.js';
  script.async = true;
  script.dataset.cbid = domainId;
  document.head.appendChild(script);
}
