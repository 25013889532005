import './style.scss';

import Error, { ERROR_CODES } from 'components/Error';
import Loading from 'components/Loading';
import usePreloadImage from 'helper/js/hooks/use-preload-image';
import { createRef, useCallback, useState } from 'react';

type Props = {
  src: string;
  className?: string;
  alt?: string;
  draggable?: boolean;
  minimalError?: boolean;
  onLoad?: () => void;
  onError?: (src: string) => void;
  preloadImage?: boolean;
  preloadImageSrc?: string;
  disableLazyLoading?: boolean;
  showLoading?: boolean;
};

const imageFallback =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
const Img = ({
  preloadImage,
  preloadImageSrc,
  className = 'img',
  src,
  alt = '',
  draggable = false,
  minimalError = false,
  onLoad = () => {},
  onError = () => {},
  disableLazyLoading = false,
  showLoading = true,
}: Props) => {
  const imgRef = createRef<HTMLImageElement>();
  const image = usePreloadImage(src, imgRef, preloadImageSrc);
  const [hasError, setHasError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const handleError = () => {
    if (image === src) {
      onError(src);
      setHasError(true);
    }
  };

  const handleLoad = useCallback(() => {
    if (image === src) {
      setLoaded(true);
    }
  }, [image, src]);

  if (hasError) {
    return <Error errorCode={ERROR_CODES.IMAGE} onlyImage={minimalError} />;
  }

  const getImgSrc = () => {
    if (disableLazyLoading) {
      return src;
    }

    if (preloadImage) {
      return image;
    }

    if (image === src) {
      return src;
    }

    return imageFallback;
  };

  return (
    <>
      {!preloadImage && !loaded && showLoading && <Loading />}
      <img
        ref={imgRef}
        className={className}
        onError={handleError}
        onLoad={handleLoad}
        src={getImgSrc()}
        alt={alt}
        draggable={draggable}
      />
    </>
  );
};

export default Img;
