import 'components/Link/style.scss';

import useNormalizedUrl from 'components/Link/normalizedUrlHook';
import { CSSProperties, forwardRef, memo, MouseEvent, ReactNode, Ref } from 'react';

export enum LINK_TARGET {
  BLANK = '_blank',
  SELF = '_self',
  PARENT = '_parent',
  SHOP = 'shop',
  ACCOUNT = 'account',
}

export type LinkTargetTypes = LINK_TARGET;

type Props = {
  href: string;
  style?: CSSProperties;
  children: ReactNode;
  target?: LinkTargetTypes;
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  title?: string;
  ref?: Ref<HTMLAnchorElement>;
  ariaLabel?: string;
  hasFlyxParam?: boolean;
};

const Link = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      href = '',
      ariaLabel,
      children,
      target = LINK_TARGET.BLANK,
      className,
      onClick,
      title,
      style,
      hasFlyxParam = true,
    }: Props,
    ref,
  ) => {
    const isPdf = href && href.indexOf('.pdf') > -1;
    const normalizedHref = useNormalizedUrl(href, hasFlyxParam);
    const rel = target === LINK_TARGET.BLANK ? 'noopener' : undefined;

    return (
      <a
        aria-label={ariaLabel ?? title}
        title={title}
        onClick={onClick}
        href={isPdf ? href : normalizedHref}
        target={target}
        className={className}
        style={style}
        rel={rel}
        ref={ref}>
        {children}
      </a>
    );
  },
);

export default memo<Props>(Link);
