import { flipToPage, sheetNavigateBeforeStart } from 'containers/SheetContainer/actions';
import { FLYER_FLIP_DURATION } from 'containers/SheetContainer/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSearchParams,
  generatePath,
  To,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { defaultRoute } from 'routes';

import { VIEW } from '../constants';
import { sleep } from '../helper';
import useEventListener from './use-event-listener';

const generateRouteParams = (params: {
  view?: string | null;
  contentId?: string | null;
  uuid?: string | null;
  page?: string | null;
}) => {
  return {
    view: params.view ?? null,
    contentId: params.contentId ?? null,
    uuid: params.uuid ?? null,
    page: params.page ?? null,
  };
};

const generateFlippyUrl = (routeParams: AParamsType, searchParams?: URLSearchParams): To => {
  return {
    pathname: generatePath(defaultRoute, generateRouteParams(routeParams)),
    search: createSearchParams(searchParams).toString(),
  };
};

export function useFlippyPageNumber(): number {
  const { page } = useParams<AParamsType>();
  const pageNumber = parseInt(page ?? '', 10);
  return isNaN(pageNumber) ? 1 : pageNumber;
}

export function useFlippyView(): VIEW {
  const { view } = useParams<AParamsType>();
  return view as VIEW;
}

export function useFlippyContentId(): string {
  const { contentId } = useParams<AParamsType>();
  return contentId ?? '';
}

type FlippyNavigateFunction = (
  routeParams: AParamsType,
  options?: { replace?: boolean },
) => Promise<void>;

export function useFlippyNavigate(): FlippyNavigateFunction {
  const pageFlipAnimation = usePageFlipAnimation();
  const navigate = useNavigate();
  const currentParams = useParams<AParamsType>();
  const [searchParams] = useSearchParams();
  const isNavigating = useSelector((state: AppState) => state.sheet.navigate);

  return async (targetParams, options): Promise<void> => {
    if (isNavigating && !options?.replace) {
      return;
    }
    if (options?.replace) {
      await pageFlipAnimation(undefined, targetParams.page);
    } else {
      await pageFlipAnimation(currentParams.page, targetParams.page);
    }
    navigate(
      generateFlippyUrl(
        {
          ...currentParams,
          ...targetParams,
        },
        searchParams,
      ),
      options,
    );
  };
}

export function usePageFlipAnimation(): (
  currentPage: string | undefined,
  targetPage: string | undefined,
) => Promise<void> {
  const isWideScreen = useSelector((state: AppState) => state.browser.isWideScreen);
  const dispatch = useDispatch();

  return async (currentPage, targetPage) => {
    if (targetPage && currentPage !== targetPage) {
      dispatch(sheetNavigateBeforeStart(targetPage));
      dispatch(
        flipToPage(currentPage ? parseInt(currentPage, 10) : undefined, parseInt(targetPage, 10)),
      );
      if (isWideScreen) {
        await sleep(FLYER_FLIP_DURATION);
      }
    }
  };
}

export const useBrowserHistoryListener = () => {
  const dispatch = useDispatch();
  const flippyPageNumber = useFlippyPageNumber();

  const handlePopState = () => {
    if (flippyPageNumber) {
      dispatch(flipToPage(undefined, flippyPageNumber));
    }
  };

  useEventListener('popstate', handlePopState);
};
