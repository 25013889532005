import { Action, ActionCreator } from 'redux';

import { HIDE_AGE_RESTRICTION, SHOW_AGE_RESTRICTION, UPDATE_AGE_RESTRICTION } from './constants';

export interface UpdateAgeRestrictionAction extends Action {
  type: 'UPDATE_AGE_RESTRICTION';
  validAgeRestrictions: AAgeRestriction;
}

export interface HideAgeRestrictionAction extends Action {
  type: 'HIDE_AGE_RESTRICTION';
  accepted: boolean;
  locale: string;
}

export interface ShowAgeRestrictionAction extends Action {
  type: 'SHOW_AGE_RESTRICTION';
  age: number;
}

export const updateAgeRestriction: ActionCreator<UpdateAgeRestrictionAction> = (
  validAgeRestrictions,
) => ({
  type: UPDATE_AGE_RESTRICTION,
  validAgeRestrictions,
});

export const hideAgeRestriction: ActionCreator<HideAgeRestrictionAction> = (
  locale: string,
  accepted: boolean,
) => ({
  type: HIDE_AGE_RESTRICTION,
  accepted,
  locale,
});

export const showAgeRestriction: ActionCreator<ShowAgeRestrictionAction> = (age: number) => ({
  type: SHOW_AGE_RESTRICTION,
  age,
});
