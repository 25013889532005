import './style.scss';

import classNames from 'classnames';
import IconC from 'components/IconC';
import Progressbar from 'components/Progressbar';
import { AnimatePresence, motion } from 'framer-motion';
import { sleep } from 'helper/js/helper';
import { ReferenceContext } from 'providers/Reference/index';
import { ScrollToContext } from 'providers/ScrollTo';
import {
  createRef,
  isValidElement,
  PropsWithChildren,
  PureComponent,
  ReactNode,
  WheelEvent,
} from 'react';

import { LanguageContextProps, withLanguage } from '../../providers/Language';

type BarProps = {
  className: string;
  header: ReactNode;
  scrollOffset: number;
  onScroll: (direction: 'left' | 'right') => void;
  showProgressBar: boolean;
  transition: any;
  currentPage: number;
};

type BarState = {
  className: string;
  header: ReactNode;
  children: ReactNode;
  showRightButton: boolean;
  showLeftButton: boolean;
  progressBarCurrentStep: number;
  progressBarStepCount: number;
};

class Bar extends PureComponent<PropsWithChildren<BarProps> & LanguageContextProps, BarState> {
  static contextType = ReferenceContext;

  isScrollingTimer: NodeJS.Timeout | undefined;

  isScrollingTimer2: NodeJS.Timeout | undefined;

  resizeTimer?: NodeJS.Timeout;

  childWidth: number[] = [];

  static defaultProps = {
    className: '',
    header: null,
    onScroll: () => {},
    showProgressBar: false,
  };

  constructor(props: PropsWithChildren<BarProps> & LanguageContextProps) {
    super(props);

    this.state = {
      showLeftButton: false,
      header: props.header,
      className: props.className,
      children: props.children,
      showRightButton: true,
      progressBarCurrentStep: 0,
      progressBarStepCount: 0,
    };
  }

  private bar = createRef<HTMLDivElement>();

  private content = createRef<HTMLDivElement>();

  scrollToNearestElement = async (next: boolean) => {
    if (this.bar.current) {
      try {
        await this.updateChildWidth();
        const nearestValue = this.getNearestValueFromOrderedArray(
          this.childWidth,
          this.bar.current.scrollLeft,
          next,
        );
        this.scrollTo({ offsetLeft: nearestValue }, false);
      } catch (error) {}
    }
  };

  handleResize = () => {
    this.resizeTimer && clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.scrollToNearestElement(true);
      this.updateButtonStatus();
    }, 200);
  };

  initData = async () => {
    try {
      if (!this.props.children) {
        return;
      }
      await sleep(700);
      await this.updateChildWidth();
      this.handleChangesOnBar();

      window.addEventListener('resize', this.handleResize);
      window.addEventListener('orientationchange', this.handleResize);
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    this.initData();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleResize);
  }

  async componentDidUpdate(prevProps: PropsWithChildren<BarProps>) {
    if (!isValidElement(prevProps.children) && isValidElement(this.props.children)) {
      this.setState({
        header: this.props.header,
        className: this.props.className,
        children: this.props.children,
      });
      this.initData();
    }

    if (!isValidElement(this.props.children) && isValidElement(prevProps.children)) {
      this.componentWillUnmount();
    }
  }

  scrollTo = async ({ offsetLeft }: { offsetLeft: number }, delta = false) => {
    if (!this.bar.current) {
      await sleep(300);
      await this.scrollTo({ offsetLeft }, delta);
    }

    this.handleScrollEnd(offsetLeft);

    if (this.bar.current) {
      const left = delta ? this.bar.current.scrollLeft + offsetLeft : offsetLeft;
      this.bar.current.scroll({ top: 0, left, behavior: 'smooth' });
    }
  };

  handleScrollEnd = (offsetLeft: number) => {
    this.props.onScroll(offsetLeft < 0 ? 'left' : 'right');
  };

  onScroll = (e: WheelEvent) => {
    const { deltaX } = e;
    const { deltaY } = e;
    const y = Math.abs(deltaY);
    const x = Math.abs(deltaX);

    this.isScrollingTimer && clearTimeout(this.isScrollingTimer);
    this.isScrollingTimer = setTimeout(() => this.handleScrollEnd(deltaX), 500);

    if (y > x && y > 3) {
      this.scrollTo({ offsetLeft: e.deltaY }, false);
    }
  };

  gotoPrevSlide = () => {
    this.scrollToNearestElement(true);
  };

  gotoNextSlide = () => {
    this.scrollToNearestElement(false);
  };

  getNearestValueFromOrderedArray = (
    orderedArray: number[],
    pivot: number,
    next: boolean,
  ): number => {
    for (let i = 0; i < orderedArray.length; i++) {
      if (next === true) {
        if (orderedArray[i] >= pivot) {
          return orderedArray[i - 1] || 0;
        }
      } else if (orderedArray[i] > pivot) {
        return orderedArray[i];
      }
    }
    return 0;
  };

  updateChildWidth = () => {
    return new Promise<void>((resolve, reject) => {
      if (!this.content.current || !this.bar.current) {
        reject(new Error(`${this.props.className} NO BAR`));
        return;
      }

      const children: HTMLElement[] = [].slice.call(this.content.current.children);

      if (children.length <= 0) {
        reject(new Error(`${this.props.className} 0 ELEMENTS`));
        return;
      }

      this.childWidth = children.map(
        (child: HTMLElement) =>
          child.offsetLeft - parseInt(window.getComputedStyle(child).marginLeft || '0', 10),
      );
      resolve();
    });
  };

  updateButtonStatus = async () => {
    await sleep(500);
    if (!this.bar.current || !this.content.current || !this.props.children) {
      return;
    }
    const minScrollOffset = 0;
    const { scrollLeft, scrollWidth, offsetWidth } = this.bar.current;
    const { offsetWidth: contentOffsetWidth } = this.content.current;

    const maxScrollOffset = scrollLeft + offsetWidth;

    const { showLeftButton, showRightButton } = this.state;
    if (offsetWidth > contentOffsetWidth) {
      this.setState({
        showLeftButton: false,
        showRightButton: false,
      });
      return;
    }

    if (scrollLeft === minScrollOffset) {
      if (showLeftButton || !showRightButton) {
        this.setState({
          showLeftButton: false,
          showRightButton: true,
        });
      }

      return;
    }
    if (maxScrollOffset === scrollWidth) {
      if (!showLeftButton || showRightButton) {
        this.setState({
          showLeftButton: true,
          showRightButton: false,
        });
      }
      return;
    }

    if (!showLeftButton || !showRightButton) {
      this.setState({
        showLeftButton: true,
        showRightButton: true,
      });
    }
  };

  handleChangesOnBar = async () => {
    this.updateButtonStatus();
    if (!this.bar.current || !this.content.current) {
      return;
    }
    if (this.content.current.scrollWidth <= 0) {
      await sleep(300);
      this.handleChangesOnBar();
      return;
    }
    const { scrollLeft, scrollWidth, offsetWidth } = this.bar.current;
    this.setState({
      progressBarCurrentStep: scrollLeft + offsetWidth,
      progressBarStepCount: scrollWidth,
    });
  };

  onScrolling = () => {
    this.isScrollingTimer2 && clearTimeout(this.isScrollingTimer2);
    this.isScrollingTimer2 = setTimeout(() => {
      this.handleChangesOnBar();
    }, 100);
  };

  render() {
    const { children, header, className, showLeftButton, showRightButton } = this.state;
    const { showProgressBar, t } = this.props;
    const barClasses = classNames({
      bar: true,
      [className]: true,
    });

    const leftButtonClasses = classNames({
      'bar__content-navigation': true,
      'bar__content-navigation--disabled': !showLeftButton,
      'bar__content-navigation--withheader': header,
    });

    const rightButtonClasses = classNames({
      'bar__content-navigation': true,
      'bar__content-navigation--right': true,
      'bar__content-navigation--disabled': !showRightButton,
      'bar__content-navigation--withheader': header,
    });

    const { progressBarStepCount, progressBarCurrentStep } = this.state;

    return (
      <AnimatePresence>
        {Boolean(this.props.children) && (
          <motion.div
            className={barClasses}
            animate={this.props.transition.animate}
            initial={this.props.transition.initial}
            exit={this.props.transition.exit}
            transition={this.props.transition.transition}>
            <ScrollToContext.Provider value={{ scrollTo: this.scrollTo }}>
              <div className="bar__wrapper">
                {header && <nav className="bar__header">{header}</nav>}
                <div className={leftButtonClasses}>
                  <button
                    aria-label={t('backward')}
                    type="button"
                    className="bar__content-navigation-button"
                    onClick={this.gotoPrevSlide}>
                    <IconC icon={`arrow-left--${import.meta.env.VITE_THEME}`} />
                  </button>
                </div>
                <div
                  className="bar__content"
                  ref={this.bar}
                  onWheel={this.onScroll}
                  onScroll={this.onScrolling}>
                  <div className="bar__content-list" ref={this.content}>
                    {children}
                  </div>
                </div>
                <div className={rightButtonClasses}>
                  <button
                    aria-label={t('forward')}
                    type="button"
                    className="bar__content-navigation-button"
                    onClick={this.gotoNextSlide}>
                    <IconC icon={`arrow-right--${import.meta.env.VITE_THEME}`} />
                  </button>
                </div>
              </div>
              {showProgressBar && (
                <div className="bar__progress">
                  <Progressbar
                    stepCount={progressBarStepCount}
                    currentStep={progressBarCurrentStep}
                  />
                </div>
              )}
            </ScrollToContext.Provider>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
}

export default withLanguage(Bar);
